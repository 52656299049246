import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { AddNewUserForm } from '../AddNewUserForm';
import { UserPostBody } from '../../../../interfaces/User';
import { useUsersContext } from '../../../../context/UsersContext';
import { UserFormSchema } from '../../utils/UsersFormValidation';

interface AddNewUserModalProps extends ModalProps {}

export function AddNewUserModal({ isOpen, onClose }: AddNewUserModalProps) {
  const { getUsers, createUser, isCreatingUser, setPage, pageSize } = useUsersContext();

  const { t } = useTranslation();

  const addNewUserForm = useForm<UserPostBody>({
    defaultValues: {
      ativo: true,
    },
    resolver: yupResolver(UserFormSchema),
  });

  const { handleSubmit, reset } = addNewUserForm;

  function handleCloseModal() {
    reset();
    onClose();
  }

  async function handleCreateNewUser(data: UserPostBody) {
    const createdUser = await createUser(data);
    setPage(1);
    await getUsers({ page: 1, pageSize, itemInList: createdUser });
    handleCloseModal();
  }

  return (
    <BaseModal
      showSaveButton={!isCreatingUser}
      onSaveCallback={handleSubmit(handleCreateNewUser)}
      title={t('users_page.components.add_new_user_modal.modal_title')}
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
    >
      <FormProvider {...addNewUserForm}>
        <AddNewUserForm />
      </FormProvider>
    </BaseModal>
  );
}
