import { useEffect, useState } from 'react';
import { MdMenu, MdOutlineDarkMode, MdOutlineHome, MdOutlineLightMode } from 'react-icons/md';
import { FaRegUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Divider, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import styles from './styles.module.scss';
import PcaLogo from '../../assets/images/pca_logo.png';
import { SidebarNav } from './components/SidebarNav';
import { UserPreferencesModal } from './components/UserPreferencesModal';
import { useModal } from '../../hooks/useModal';
import { useThemeContext } from '../../context/ThemeContext';
import { useAuthContext } from '../../context/AuthContext';
import { PasswordModal } from './components/PasswordModal';
import { UserTypeEnum } from '../../enums/User';
import { PasswordInsertFixedModal } from './components/PasswordInsertFixedModal';
import { UserHistoryModal } from './components/UserHistoryModal';
import { UserHistoryContextProvider } from '../../context/UserHistoryContext';

export function Header() {
  const [isSidebarOpen, handleOpenSidebar, handleCloseSidebar] = useModal();
  const [isUserPreferencesModalOpen, handleOpenUserPreferencesModal, handleCloseUserPreferencesModal] = useModal();
  const [isPasswordModalOpen, handleOpenPasswordModal, handleClosePasswordModal] = useModal();
  const [isPasswordInsertFixedModalOpen, handleOpenPasswordInsertFixedModal, handleClosePasswordInsertFixedModal] =
    useModal();

  const [isUserHistoryModalOpen, handleOpenUserHistoryModal, handleCloseUserHistoryModal] = useModal();

  const { t } = useTranslation();

  const { selectedTheme, setSelectedTheme } = useThemeContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUser, isFetchingCurrentUser, getCurrentUser } = useAuthContext();

  const { doLogout } = useAuthContext();

  useEffect(() => {
    handleCloseSidebar();
    getCurrentUser();
  }, [pathname]);

  useEffect(() => {
    if (currentUser !== undefined && currentUser.senhaInicial !== null) {
      handleOpenPasswordInsertFixedModal();
    }
  }, [currentUser]);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <div className={styles.menuButtonContainer}>
          {currentUser?.tipo === UserTypeEnum.BackOffice && (
            <button type="button" className={styles.menuButton} onClick={handleOpenSidebar}>
              <MdMenu />
            </button>
          )}
        </div>

        <div className={styles.imageLogoContainer}>
          <Link to="/">
            <img src={PcaLogo} alt="PCA Logo" className={styles.imageLogo} />
          </Link>
        </div>

        <div className={styles.userData}>
          <IconButton
            className={styles.themeButton}
            title={t('components.header.go_to_home')}
            onClick={() => navigate('/')}
          >
            <MdOutlineHome />
          </IconButton>

          <IconButton
            onClick={() => setSelectedTheme(selectedTheme === 'light' ? 'dark' : 'light')}
            className={styles.themeButton}
            style={{ marginRight: '1rem' }}
            title={
              selectedTheme === 'light'
                ? t('components.header.change_to_dark_theme')
                : t('components.header.change_to_light_theme')
            }
          >
            {selectedTheme === 'light' ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
          </IconButton>
          <button className={styles.loggedUser} onClick={handleMenuOpen} type="button">
            <span className="userName">
              {isFetchingCurrentUser || currentUser === undefined ? (
                <Skeleton variant="text" width={200} animation="wave" />
              ) : (
                `${currentUser.primeiroNome} ${currentUser.ultimoNome}`
              )}
            </span>
            <FaRegUserCircle />
          </button>
          <Menu
            PaperProps={{ sx: { backgroundColor: 'var(--navs)', color: 'var(--navs-text)' } }}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <span className={styles.menuUserName}>
              <MenuItem>{`${currentUser?.primeiroNome} ${currentUser?.ultimoNome}`}</MenuItem>
              <Divider />
            </span>
            <MenuItem onClick={handleOpenUserPreferencesModal}>{t('components.header.my_preferences')}</MenuItem>
            <MenuItem onClick={handleOpenUserHistoryModal}>{t('components.header.my_history')}</MenuItem>
            <MenuItem onClick={handleOpenPasswordModal}>{t('components.header.change_password')}</MenuItem>
            <MenuItem onClick={doLogout}>{t('components.header.signout')}</MenuItem>
          </Menu>
        </div>
      </div>

      <PasswordInsertFixedModal isOpen={isPasswordInsertFixedModalOpen} onClose={handleClosePasswordInsertFixedModal} />
      <SidebarNav isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
      <UserPreferencesModal isOpen={isUserPreferencesModalOpen} onClose={handleCloseUserPreferencesModal} />
      <PasswordModal isOpen={isPasswordModalOpen} onClose={handleClosePasswordModal} />
      {isUserHistoryModalOpen && (
        <UserHistoryContextProvider>
          <UserHistoryModal isOpen={isUserHistoryModalOpen} onClose={handleCloseUserHistoryModal} />
        </UserHistoryContextProvider>
      )}
    </header>
  );
}
