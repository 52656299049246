import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Metadata } from '../interfaces/ApiResponse';
import {
  FetchCategoriesParams,
  CategoryData,
  CategoryPostBody,
  CategoryResponse,
  CategoryPutBody,
} from '../interfaces/Category';
import { api } from '../services/api';
import { useToastContext } from '../context/ToastContext';

export async function fetchCategories({
  page,
  pageSize,
  nome,
  order,
  orderBy,
  ativo,
}: FetchCategoriesParams): Promise<CategoryResponse> {
  const { data } = await api.get<CategoryResponse>('/categoria', {
    params: {
      page,
      pageSize,
      nome,
      order,
      orderBy,
      ativo,
    },
  });
  return data;
}

export function useGetCategories() {
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingCategories, setIsFetchingCategories] = useState<boolean>(false);

  async function getCategories({ page, pageSize, nome, itemInList, order, orderBy, ativo }: FetchCategoriesParams) {
    setIsFetchingCategories(true);

    try {
      const data = await fetchCategories({ page, pageSize, nome, order, orderBy, ativo });

      if (itemInList) {
        data.results = data.results.filter((cat) => cat.idCategoria !== itemInList.idCategoria);
        data.results = [itemInList, ...data.results];
        if (data.results.length > pageSize) {
          data.results.pop();
        }
      }
      setCategories(data.results);
      setMetadata({ ...data });
    } finally {
      setIsFetchingCategories(false);
    }
  }

  return { getCategories, metadata, categories, isFetchingCategories };
}

export function useGetCategory() {
  const [category, setCategory] = useState<CategoryData | null>(null);
  const [isFetchingCategory, setIsFetchingCategory] = useState<boolean>(false);

  async function getCategory(categoryId: number) {
    setIsFetchingCategory(true);

    try {
      const { data } = await api.get<CategoryData>(`/categoria/${categoryId}`);
      setCategory(data);
    } finally {
      setIsFetchingCategory(false);
    }
  }

  return { category, isFetchingCategory, getCategory };
}

export function useCreateCategory() {
  const [isCreatingCategory, setIsCreatingCategory] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function createCategory(category: CategoryPostBody) {
    setIsCreatingCategory(true);

    try {
      const response = await createPromiseToast(api.post<CategoryData>('/categoria', category), {
        error: t('hooks.use_categories.create_category_error', { categoryName: category.nome }),
        pending: t('hooks.use_categories.create_category_pending', { categoryName: category.nome }),
        success: t('hooks.use_categories.create_category_success', { categoryName: category.nome }),
      });
      return response.data;
    } finally {
      setIsCreatingCategory(false);
    }
  }

  return { createCategory, isCreatingCategory };
}

export function useEditCategory() {
  const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function editCategory(category: CategoryPutBody) {
    setIsEditingCategory(true);

    try {
      const response = await createPromiseToast(api.put<CategoryData>(`/categoria`, category), {
        error: t('hooks.use_categories.edit_category_error', { categoryName: category.nome }),
        pending: t('hooks.use_categories.edit_category_pending', { categoryName: category.nome }),
        success: t('hooks.use_categories.edit_category_success', { categoryName: category.nome }),
      });
      return response.data;
    } finally {
      setIsEditingCategory(false);
    }
  }

  return { editCategory, isEditingCategory };
}

export function useDeleteCategory() {
  const [isDeletingCategory, setIsDeletingCategory] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function deleteCategory(category: CategoryData) {
    setIsDeletingCategory(true);

    try {
      await createPromiseToast(
        api.delete(`/categoria/${category.idCategoria}`),
        {
          error: t('hooks.use_categories.delete_category_error', { categoryName: category.nome }),
          pending: t('hooks.use_categories.delete_category_pending', { categoryName: category.nome }),
          success: t('hooks.use_categories.delete_category_success', { categoryName: category.nome }),
        },
        true
      );
    } catch (error: unknown) {
      setIsDeletingCategory(false);
    } finally {
      setIsDeletingCategory(false);
    }
  }

  return { deleteCategory, isDeletingCategory };
}
