import { RiLockPasswordLine } from 'react-icons/ri';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../BaseModal';
import { UserPassword } from '../../../../interfaces/User';
import styles from './styles.module.scss';
import { changePassword } from '../../../../hooks/useUsers';
import { useToastContext } from '../../../../context/ToastContext';
import { useAuthContext } from '../../../../context/AuthContext';
import { UserPasswordFormSchema } from './utils/UserPasswordFormSchema';

interface PasswordModalProps extends ModalProps {}

export function PasswordModal({ isOpen, onClose }: PasswordModalProps) {
  const { createPromiseToast } = useToastContext();
  const { doLogout } = useAuthContext();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserPassword>({
    resolver: yupResolver(UserPasswordFormSchema),
  });

  function updatePassword(data: UserPassword) {
    try {
      createPromiseToast(changePassword(data), {
        error: t('components.password_modal.change_password_error'),
        pending: t('components.password_modal.change_password_pending'),
        success: t('components.password_modal.change_password_success'),
      });
      doLogout();
    } finally {
      onClose();
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('components.password_modal.modal_title')}
      showSaveButton
      onSaveCallback={handleSubmit(updatePassword)}
    >
      <div className={styles.selectContainerMain}>
        <div className={styles.selectContainer}>
          <FormControl error={!!errors.senhaAtual}>
            <TextField
              id="current-password"
              label={t('components.password_insert_fixed_modal.current_password_input_label')}
              type="password"
              {...register('senhaAtual')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordLine size={24} />
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.senhaAtual && <FormHelperText>{t(`${errors.senhaAtual.message}`)}</FormHelperText>}
          </FormControl>

          <FormControl error={!!errors.novaSenha}>
            <TextField
              id="new-password"
              label={t('components.password_insert_fixed_modal.new_password_input_label')}
              type="password"
              {...register('novaSenha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordLine size={24} />
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.novaSenha && <FormHelperText>{t(`${errors.novaSenha.message}`)}</FormHelperText>}
          </FormControl>

          <FormControl error={!!errors.confirmacaoNovaSenha}>
            <TextField
              id="new-password-confirmation"
              label={t('components.password_insert_fixed_modal.confirm_new_password_input_label')}
              type="password"
              {...register('confirmacaoNovaSenha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordLine size={24} />
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.confirmacaoNovaSenha && (
              <FormHelperText>{t(`${errors.confirmacaoNovaSenha.message}`)}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </BaseModal>
  );
}
