import { MenuItem, Pagination, Select } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCategoriesContext } from '../../../../context/CategoriesContext';
import styles from '../../styles.module.scss';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useContentsContext } from '../../../../context/ContentsContext';

interface SelectCategoryProps {
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}

export function SelectCategory({ category, setCategory }: SelectCategoryProps) {
  const { categories, getCategories, isFetchingCategories, metadata, page, pageSize, setPage } = useCategoriesContext();
  const { isFetchingContents } = useContentsContext();

  const { t } = useTranslation();

  function handlePageChange(value: number) {
    setPage(value);
    setCategory('');
    getCategories({ page: value, pageSize, ativo: true });
  }

  useEffect(() => {
    setPage(1);
    getCategories({ page: 1, pageSize, ativo: true });
  }, []);

  function returnSelectCategory() {
    if (isFetchingCategories) {
      return (
        <div className={styles.selectMenuLoadIndicator}>
          <LoadIndicator />
        </div>
      );
    }
    return (
      categories &&
      categories.map((cat) => (
        <MenuItem key={cat.idCategoria} value={cat.idCategoria}>
          {cat.nome}
        </MenuItem>
      ))
    );
  }

  return (
    <Select
      label={t('report_page.index.select_category_input_label')}
      value={category}
      onChange={({ target }) => (!isFetchingContents ? setCategory(target.value) : '')}
    >
      <MenuItem value="">{t('report_page.components.select_category.menu_item_no_category')}</MenuItem>
      {returnSelectCategory()}
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          onChange={(e, value) => handlePageChange(value)}
          count={metadata.pages}
          color="primary"
          variant="outlined"
          disabled={isFetchingCategories}
        />
      </div>
    </Select>
  );
}
