import { Routes, Route, Navigate } from 'react-router-dom';
import { DefaultLayout } from './layout/DefaultLayout';
import { Content } from './pages/Content';
import { Home } from './pages/Home';
import { Medias } from './pages/Medias';
import { Categories } from './pages/Categories';
import { Login } from './pages/Login';
import { UserManagement } from './pages/UserManagement';
import { ContentsContextProvider } from './context/ContentsContext';
import { MediasContextProvider } from './context/MediasContext';
import { CategoriesContextProvider } from './context/CategoriesContext';
import { UsersContextProvider } from './context/UsersContext';
import { Contents } from './pages/Contents';
import { useAuthContext } from './context/AuthContext';
import { UserTypeEnum } from './enums/User';
import { Report } from './pages/Report';
import { ActivityUserContextProvider } from './context/ActivityUserContext';

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const { authenticated } = useAuthContext();

  if (authenticated === false) {
    return <Navigate to="/login" />;
  }
  return children;
}

export function Router() {
  const { currentUser } = useAuthContext();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route
          path="/"
          element={
            <ContentsContextProvider>
              <Home />
            </ContentsContextProvider>
          }
        />
        {currentUser !== undefined && currentUser.senhaInicial === null && (
          <>
            <Route
              path="/content/:id"
              element={
                <ContentsContextProvider>
                  <Content />
                </ContentsContextProvider>
              }
            />

            {currentUser?.tipo === UserTypeEnum.BackOffice && (
              <>
                <Route
                  path="/usermanagement"
                  element={
                    <UsersContextProvider>
                      <UserManagement />
                    </UsersContextProvider>
                  }
                />
                <Route
                  path="/medias"
                  element={
                    <MediasContextProvider>
                      <Medias />
                    </MediasContextProvider>
                  }
                />
                <Route
                  path="/categories"
                  element={
                    <ContentsContextProvider>
                      <CategoriesContextProvider>
                        <Categories />
                      </CategoriesContextProvider>
                    </ContentsContextProvider>
                  }
                />
                <Route
                  path="/contents"
                  element={
                    <ContentsContextProvider>
                      <CategoriesContextProvider>
                        <Contents />
                      </CategoriesContextProvider>
                    </ContentsContextProvider>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <CategoriesContextProvider>
                      <ContentsContextProvider>
                        <UsersContextProvider>
                          <ActivityUserContextProvider>
                            <Report />
                          </ActivityUserContextProvider>
                        </UsersContextProvider>
                      </ContentsContextProvider>
                    </CategoriesContextProvider>
                  }
                />
              </>
            )}
          </>
        )}

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
