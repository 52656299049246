import { Button, Pagination, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCategoriesContext } from '../../context/CategoriesContext';
import { useModal } from '../../hooks/useModal';
import { AddNewCategoryModal } from './components/AddNewCategoryModal';
import { CategoriesTable } from './components/CategoriesTable';
import styles from './styles.module.scss';
import { OnSortProps } from '../../hooks/useTableSorting';
import { CategoryParamsToOrderBy } from '../../interfaces/Category';

export function Categories() {
  const [searchCategoryName, setSearchCategoryName] = useState<string>('');
  const [isAddNewCategoryModalOpen, handleOpenNewCategoryModal, handleCloseNewCategoryModal] = useModal();
  const {
    page,
    setPage,
    pageSize,
    getCategories,
    metadata,
    isFetchingCategories,
    order,
    orderBy,
    setOrderBy,
    setOrder,
  } = useCategoriesContext();

  const { t } = useTranslation();

  function handleSearchCategoriesByName(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPage(1);
    getCategories({ page: 1, pageSize, nome: searchCategoryName, order, orderBy });
  }

  function handlePageChange(value: number) {
    setPage(value);
    getCategories({ page: value, pageSize, nome: searchCategoryName, order, orderBy });
  }

  async function onTableSort({ orderOnSort, orderByOnSort }: OnSortProps<CategoryParamsToOrderBy>) {
    setOrderBy(orderByOnSort);
    setOrder(orderOnSort);
    await getCategories({ page, pageSize, nome: searchCategoryName, order: orderOnSort, orderBy: orderByOnSort });
  }

  useEffect(() => {
    getCategories({ page, pageSize, order, orderBy });
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('categories_page.index.title')}</h1>
      <div className={styles.heading}>
        <form className={styles.searchBar} onSubmit={handleSearchCategoriesByName}>
          <TextField
            type="text"
            color="primary"
            variant="outlined"
            size="small"
            placeholder={t('categories_page.index.category_name_input_pch')}
            value={searchCategoryName}
            onChange={(e) => setSearchCategoryName(e.target.value)}
            fullWidth
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isFetchingCategories}
            className={styles.searchButton}
          >
            {t('categories_page.index.search_category_button')}
          </Button>
        </form>
        <div className={styles.addSectionContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenNewCategoryModal}
            className={styles.addNewCategoryButton}
          >
            {t('categories_page.index.add_new_category_button')}
          </Button>
        </div>
      </div>
      <CategoriesTable onTableSort={(params) => onTableSort(params)} />
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          onChange={(_, value) => handlePageChange(value)}
          count={metadata.pages}
          color="primary"
          variant="outlined"
        />
      </div>
      <AddNewCategoryModal isOpen={isAddNewCategoryModalOpen} onClose={handleCloseNewCategoryModal} />
    </div>
  );
}
