import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import styles from './styles.module.scss';
import { useAuthContext } from '../../../../context/AuthContext';
import { ActivityUserParamsToOrderBy } from '../../../../interfaces/ActivityUser';
import { MediaEnum } from '../../../../enums/Media';
import { OnSortProps, useTableSorting } from '../../../../hooks/useTableSorting';
import { useActivityUserContext } from '../../../../context/ActivityUserContext';
import { getDateWithTime } from '../../utils/date';
import { MediaData } from '../../../../interfaces/Media';
import { WatchVideoButton } from '../../../Medias/components/WatchVideoButton';
import { ListenAudioButton } from '../../../Medias/components/ListenAudioButton';
import { DownloadButton } from '../../../Medias/components/DownloadButton';
import { ShowMediaEnum } from '../../../../enums/ShowMedia';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function ReportTable() {
  const { currentUser } = useAuthContext();

  const { t } = useTranslation();

  const {
    setOrder,
    setOrderBy,
    activityUser,
    getActivityUser,
    pageSize,
    dateFrom,
    dateTo,
    content,
    category,
    users,
    isFetchingActivityUser,
  } = useActivityUserContext();

  async function onTableSort({ orderOnSort: order, orderByOnSort: orderBy }: OnSortProps<ActivityUserParamsToOrderBy>) {
    setOrderBy(orderBy);
    setOrder(order);
    if (activityUser.length > 0) {
      getActivityUser({
        page: 1,
        pageSize,
        order,
        orderBy,
        idUsuarios: users.map((user) => user.toString()).join(','),
        idCategoria: category,
        idConteudo: content,
        dataAte: dateTo,
        dataDe: dateFrom,
      });
    }
  }

  const orderButton = useTableSorting<ActivityUserParamsToOrderBy>(onTableSort);

  function returnMediaButton(media: MediaData | undefined) {
    if (media !== undefined) {
      if (media.tipo === MediaEnum.Vídeo || media.tipo === MediaEnum.YouTube) {
        return <WatchVideoButton media={media} />;
      }
      if (media.tipo === MediaEnum.Áudio) {
        return <ListenAudioButton media={media} />;
      }
      return <DownloadButton media={media} />;
    }
    return null;
  }

  if (isFetchingActivityUser) return <LoadIndicator />;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.tableHeaderCell} align="center">
              {t('report_page.components.report_table.table.action_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('primeiroNome')}
              {t('report_page.components.report_table.table.first_name_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('ultimoNome')}
              {t('report_page.components.report_table.table.last_name_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('nomeMedia')}
              {t('report_page.components.report_table.table.media_name_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('tipoMedia')}
              {t('report_page.components.report_table.table.media_type_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataInicio')}
              {t('report_page.components.report_table.table.start_date_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataFim')}
              {t('report_page.components.report_table.table.end_date_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataDownload')}
              Download
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityUser?.map((au) => (
            <StyledTableRow key={au.idAtividadeUsuario} className={styles.tableRow}>
              <TableCell className={styles.tableBodyCell} align="center">
                {returnMediaButton(au.media)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.usuario?.primeiroNome}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.usuario?.ultimoNome}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.media?.nome}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.media?.tipo ? t(ShowMediaEnum[au.media.tipo || 0]) : ''}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.dataInicio == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(au.dataInicio, currentUser?.idioma)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.dataFim == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(au.dataFim, currentUser?.idioma)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {au.dataDownload == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(au.dataDownload, currentUser?.idioma)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
