import { Button, FormControl, InputLabel, Pagination, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../BaseModal';
import styles from '../../../../pages/Report/styles.module.scss';
import { SelectContent } from '../../../../pages/Report/components/SelectContent';
import { SelectCategory } from '../../../../pages/Report/components/SelectCategory';
import { InputDateMask } from '../../../../pages/Report';
import { CategoriesContextProvider } from '../../../../context/CategoriesContext';
import { ContentsContextProvider } from '../../../../context/ContentsContext';
import { UserHistoryTable } from './components/UserHistoryTable';
import { useUserHistoryContext } from '../../../../context/UserHistoryContext';

interface UserHistoryModalProps extends ModalProps {}

export function UserHistoryModal({ isOpen, onClose }: UserHistoryModalProps) {
  const { t } = useTranslation();

  const {
    getUserHistory,
    isFetchingUserHistory,
    metadata,
    page,
    pageSize,
    setPage,
    order,
    orderBy,
    category,
    content,
    dateFrom,
    dateTo,
    setCategory,
    setContent,
    setDateFrom,
    setDateTo,
  } = useUserHistoryContext();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setPage(1);
    getUserHistory({
      page: 1,
      pageSize,
      order,
      orderBy,
      idCategoria: category,
      idConteudo: content,
      dataAte: dateTo,
      dataDe: dateFrom,
    });
  }

  function handlePageChange(value: number) {
    setPage(value);
    getUserHistory({
      page: value,
      pageSize,
      order,
      orderBy,
      idCategoria: category,
      idConteudo: content,
      dataAte: dateTo,
      dataDe: dateFrom,
    });
  }

  return (
    <CategoriesContextProvider>
      <ContentsContextProvider>
        <BaseModal isOpen={isOpen} onClose={onClose} title={t('components.user_history_modal.modal_title')}>
          <div className={styles.container}>
            {/* <h1 className={styles.title}>Meu Histórico</h1> */}
            <div className={styles.heading}>
              <form className={styles.searchBar} onSubmit={(e) => handleSubmit(e)}>
                <fieldset className={styles.inputContainer}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="label-selecionar-categoria">
                      {t('report_page.index.select_category_input_label')}
                    </InputLabel>
                    <SelectCategory category={category} setCategory={setCategory} />
                  </FormControl>
                </fieldset>

                <fieldset className={styles.inputContainer}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="courses-select-label">
                      {t('report_page.index.select_content_input_label')}
                    </InputLabel>
                    <SelectContent content={content} setContent={setContent} category={category} />
                  </FormControl>
                </fieldset>

                <fieldset className={styles.containerDate}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label={t('report_page.index.date_from_input_label')}
                      value={dateFrom}
                      size="small"
                      className={styles.inputDate}
                      onChange={({ target }) => setDateFrom(target.value)}
                      InputProps={{
                        inputComponent: InputDateMask as any,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label={t('report_page.index.date_to_input_label')}
                      size="small"
                      value={dateTo}
                      className={styles.inputDate}
                      onChange={({ target }) => setDateTo(target.value)}
                      InputProps={{
                        inputComponent: InputDateMask as any,
                      }}
                    />
                  </FormControl>
                </fieldset>

                <div className={styles.searchContainer}>
                  <Button variant="contained" type="submit" className={styles.searchButton}>
                    {t('report_page.index.search_report_button')}
                  </Button>
                </div>
              </form>
            </div>
            <UserHistoryTable />
            <div className={styles.paginationContainer}>
              <Pagination
                page={page}
                onChange={(_, value) => handlePageChange(value)}
                count={metadata.pages}
                color="primary"
                variant="outlined"
                disabled={isFetchingUserHistory}
              />
            </div>
          </div>
        </BaseModal>
      </ContentsContextProvider>
    </CategoriesContextProvider>
  );
}
