import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContentsContext } from '../../../../context/ContentsContext';
import { ContentCard } from '../ContentCard';
import { ContentCardSkeleton } from '../ContentCardSkeleton';
import styles from './styles.module.scss';
import { CategoryData } from '../../../../interfaces/Category';

interface ContentsListProps {
  selectedMenuCategory: CategoryData | null;
  searchContentName: string;
}

export function ContentsList({ selectedMenuCategory, searchContentName }: ContentsListProps) {
  const { getContents, contents, page, setPage, metadata, isFetchingContents, pageSize } = useContentsContext();

  const { t } = useTranslation();

  const hasContents = contents.length > 0;

  function handlePageChange(value: number) {
    setPage(value);
    getContents({ page: value, pageSize, categoryId: selectedMenuCategory?.idCategoria, ativo: true, catAtiva: true });
  }

  if (isFetchingContents) {
    return (
      <section className={styles.listContainer}>
        <ContentCardSkeleton />
        <ContentCardSkeleton />
        <ContentCardSkeleton />
        <ContentCardSkeleton />
        <ContentCardSkeleton />
      </section>
    );
  }

  return (
    <>
      <section className={hasContents ? styles.listContainer : styles.noItemsListContainer}>
        {hasContents ? (
          contents.map((content) => (
            <ContentCard
              content={content}
              key={content.idConteudo}
              selectedMenuCategory={selectedMenuCategory}
              searchContentName={searchContentName}
            />
          ))
        ) : (
          <p> {t('home_page.components.contents_list.no_content_found')}</p>
        )}
      </section>
      <div className={styles.paginationContainer}>
        <Pagination
          count={metadata.pages}
          page={page}
          onChange={(_, value) => handlePageChange(value)}
          color="primary"
          variant="outlined"
        />
      </div>
    </>
  );
}
