import { IconButton, Slider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPause, FaPlay, FaVolumeDown, FaVolumeUp } from 'react-icons/fa';
import { AiOutlineAudioMuted } from 'react-icons/ai';
import styles from './styles.module.scss';
import { formatTime } from '../utils/time';
import ReactMarkdownShow from '../../ReactMarkdownShow/ReactMarkdownShow';
import { LoadIndicator } from '../../LoadIndicator';
import { MediaData, MediaFileData } from '../../../interfaces/Media';
import { api } from '../../../services/api';

interface AudioPlayerProps {
  media: MediaData;
  onAudioStarted?: () => void;
  onAudioEnded?: () => void;
}

export function AudioPlayer({ media, onAudioEnded, onAudioStarted }: AudioPlayerProps) {
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [currentVolume, setCurrentVolume] = useState<number>(10);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(true);
  const [audioSrc, setAudioSrc] = useState<string>('');
  const [audioMimeType, setAudioMimeType] = useState<string>('');

  const [sentStarted, setSentStarted] = useState<boolean>(false);
  const [sentEnded, setSentEnded] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement>(new Audio(audioSrc));
  const requestRef = useRef<NodeJS.Timer>();

  const { duration } = audioRef.current;

  const { t } = useTranslation();

  function startTimer() {
    requestRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
      } else {
        setCurrentProgress(audioRef.current.currentTime);
      }
    }, 100);
  }

  function handleChangeVolume(e: Event, newValue: number | number[]) {
    setCurrentVolume(newValue as number);
    audioRef.current.volume = (newValue as number) / 100;
  }

  function onChangeTrack(value: number | number[]) {
    audioRef.current.pause();
    audioRef.current.currentTime = value as number;
    setIsPlaying(false);
    setCurrentProgress(audioRef.current.currentTime);
  }

  function onChangeTrackEnd() {
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }

  useEffect(() => {
    if (isPlaying) {
      if (onAudioStarted && sentStarted === false) {
        setSentStarted(true);
        onAudioStarted();
      }
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(requestRef.current);
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    let urlBlob: string | undefined;

    async function getFile() {
      const responseFile = await api.get<MediaFileData>(`/media/${media.idMedia}/arquivo`);

      if (responseFile.data) {
        const fileContent = window.atob(responseFile.data.conteudo);
        const buffer = new ArrayBuffer(fileContent.length);
        const intArray = new Uint8Array(buffer);

        for (let n = 0; n < fileContent.length; n += 1) {
          intArray[n] = fileContent.charCodeAt(n);
        }

        const fileName = `${media.nome}${responseFile.data.extensao}`;
        const mimeType = { type: responseFile.data.mimeType };

        const file = new File([buffer], fileName, mimeType);
        const url = URL.createObjectURL(file);

        setAudioSrc(url);
        setAudioMimeType(responseFile.data.mimeType);

        const audio = new Audio(url);

        audio.volume = 0.1;
        audioRef.current = audio;

        urlBlob = url;
      }

      setIsLoadingFile(false);
    }

    getFile();

    return () => {
      URL.revokeObjectURL(urlBlob || '');
      audioRef.current.pause();
      clearInterval(requestRef.current);
    };
  }, []);

  useEffect(() => {
    if (onAudioEnded && Math.floor((audioRef.current.currentTime / audioRef.current.duration) * 100) >= 90) {
      if (sentEnded === false) {
        setSentEnded(true);
        onAudioEnded();
      }
    }
  }, [currentProgress]);

  function returnPlayer() {
    if (!isLoadingFile && audioSrc && audioRef.current.canPlayType(audioMimeType)) {
      return (
        <div className={styles.audioContainer}>
          <div className={styles.audioPlayerContainer}>
            <div className={styles.audioName}>{media.nome}</div>
            <div className={styles.audioSubtitle}>
              <ReactMarkdownShow>{media.descricao || ''}</ReactMarkdownShow>
            </div>
            <div className={styles.audioControls}>
              <span className={styles.volume}>
                <FaVolumeDown size={32} />
                <Slider
                  size="small"
                  aria-label="Volume"
                  value={currentVolume}
                  max={100}
                  onChange={(e, newValue) => handleChangeVolume(e, newValue)}
                />
                <FaVolumeUp size={32} />
              </span>
              <span className={styles.controls}>
                <IconButton onClick={() => setIsPlaying((old) => !old)}>
                  {isPlaying ? <FaPause /> : <FaPlay />}
                </IconButton>
              </span>
            </div>
            <div className={styles.audioTrack}>
              <span>{formatTime(audioRef.current.currentTime)}</span>
              <Slider
                size="small"
                aria-label={t('components.audio_player.progress_audio')}
                value={currentProgress || 0}
                min={0}
                max={duration || 0}
                onChange={(e, value) => onChangeTrack(value)}
                onMouseUp={() => onChangeTrackEnd()}
                onKeyUp={() => onChangeTrackEnd()}
              />
              <span>{formatTime(audioRef.current.duration)}</span>
            </div>
          </div>
        </div>
      );
    }
    if (audioRef.current.canPlayType(audioMimeType) && audioMimeType !== '') {
      return (
        <span className={styles.noAudioContainer}>
          <AiOutlineAudioMuted size={128} />
          <p>{t('components.audio_player.audio_not_available')}</p>
        </span>
      );
    }
    return (
      <div className={styles.audioContainer}>
        <LoadIndicator />
      </div>
    );
  }

  return returnPlayer();
}
