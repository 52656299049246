import { createContext, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { ToastContent, TypeOptions } from 'react-toastify/dist/types';
import { isAxiosError } from 'axios';
import { useThemeContext } from './ThemeContext';

type ToastContextType = {
  createToast: (msg: ToastContent, type: TypeOptions) => void;
  createPromiseToast: <T>(
    msg: Promise<T>,
    messages: { error: string; pending: string; success: string },
    useErrorResponseMessage?: boolean
  ) => Promise<T>;
};

const ToastContext = createContext({} as ToastContextType);

interface ToastContextProviderProps {
  children: ReactNode;
}

export function ToastContextProvider({ children }: ToastContextProviderProps) {
  const { t } = useTranslation();

  function createToast(msg: ToastContent, type: TypeOptions) {
    toast(msg, { type });
  }

  async function createPromiseToast<T>(
    msg: Promise<T>,
    messages: { error: string; pending: string; success: string },
    useErrorResponseMessage?: boolean
  ) {
    if (useErrorResponseMessage) {
      return toast.promise<T>(msg, {
        success: messages.success,
        pending: messages.pending,
        error: {
          render({ data }) {
            if (isAxiosError(data)) {
              if (data.response?.status === 400) {
                const { errors } = data.response.data;
                const errorsList: string[] = [];
                Object.keys(errors).forEach((key) => errorsList.push(t(`hooks_errors_api.${errors[key].toString()}`)));
                return errorsList;
              }
            }
            return messages.error;
          },
        },
      });
    }
    return toast.promise<T>(msg, messages);
  }

  const { selectedTheme } = useThemeContext();

  return (
    <ToastContext.Provider
      value={{
        createToast,
        createPromiseToast,
      }}
    >
      {children}
      <ToastContainer
        position="top-right"
        autoClose={6000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={selectedTheme}
      />
    </ToastContext.Provider>
  );
}

export function useToastContext() {
  const toastContext = useContext(ToastContext);
  return toastContext;
}
