import { Pagination, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediasContext } from '../../context/MediasContext';
import { useModal } from '../../hooks/useModal';
import { AddNewMediaModal } from './components/AddNewMediaModal';
import { MediasTable } from './components/MediasTable';
import styles from './styles.module.scss';
import { OnSortProps } from '../../hooks/useTableSorting';
import { MediaParamsToOrderBy } from '../../interfaces/Media';

export function Medias() {
  const [searchMediaName, setSearchMediaName] = useState('');
  const [isAddNewMediaModalOpen, handleOpenAddNewMediaModal, handleCloseAddNewMediaModal] = useModal();
  const { page, setPage, getMedias, metadata, isFetchingMedias, pageSize, order, orderBy, setOrder, setOrderBy } =
    useMediasContext();

  const { t } = useTranslation();

  async function handleSearchMediasByName(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPage(1);
    await getMedias({ page: 1, pageSize, nome: searchMediaName, order, orderBy });
  }

  async function handlePageChange(value: number) {
    setPage(value);
    await getMedias({ page: value, pageSize, nome: searchMediaName, order, orderBy });
  }

  async function onTableSort({ orderOnSort, orderByOnSort }: OnSortProps<MediaParamsToOrderBy>) {
    setOrderBy(orderByOnSort);
    setOrder(orderOnSort);
    await getMedias({ page, pageSize, nome: searchMediaName, order: orderOnSort, orderBy: orderByOnSort });
  }

  useEffect(() => {
    getMedias({ page, pageSize, order, orderBy });
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('media_page.index.title')}</h1>
      <div className={styles.heading}>
        <form className={styles.searchBar} onSubmit={handleSearchMediasByName}>
          <TextField
            type="text"
            placeholder={t('media_page.index.media_name_input_pch')}
            color="primary"
            variant="outlined"
            size="small"
            value={searchMediaName}
            onChange={({ target }) => setSearchMediaName(target.value)}
            fullWidth
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isFetchingMedias}
            className={styles.searchButton}
          >
            {t('media_page.index.search_media_button')}
          </Button>
        </form>
        <div className={styles.addMediaContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenAddNewMediaModal}
            className={styles.addNewMediaButton}
          >
            {t('media_page.index.add_new_media_button')}
          </Button>
        </div>
      </div>
      <MediasTable onTableSort={(params) => onTableSort(params)} />
      <div className={styles.paginationContainer}>
        <Pagination
          count={metadata.pages}
          page={page}
          onChange={(event, value) => handlePageChange(value)}
          color="primary"
          variant="outlined"
          disabled={isFetchingMedias}
        />
      </div>
      <AddNewMediaModal isOpen={isAddNewMediaModalOpen} onClose={handleCloseAddNewMediaModal} />
    </div>
  );
}
