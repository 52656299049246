import { useTranslation } from 'react-i18next';
import { useContentsContext } from '../../../../context/ContentsContext';
import { CategoryData } from '../../../../interfaces/Category';
import { ContentCard } from '../ContentCard';
import { ContentCardSkeleton } from '../ContentCardSkeleton';
import styles from './styles.module.scss';

interface MyContentsListProps {
  selectedMenuCategory: CategoryData | null;
  searchContentName: string;
}

export function MyContentsList({ searchContentName, selectedMenuCategory }: MyContentsListProps) {
  const { userContents, isFetchingUserContents } = useContentsContext();

  const { t } = useTranslation();

  const hasContents = userContents.length > 0;

  if (isFetchingUserContents) {
    return (
      <section className={styles.myListContainer}>
        <div className={styles.listContainer}>
          <ContentCardSkeleton />
          <ContentCardSkeleton />
          <ContentCardSkeleton />
          <ContentCardSkeleton />
          <ContentCardSkeleton />
        </div>
      </section>
    );
  }

  return (
    <section className={styles.myListContainer}>
      <h1 className={styles.title}>{t('home_page.components.my_contents_list.title')}</h1>
      <section className={hasContents ? styles.listContainer : styles.noItemsListContainer}>
        {hasContents ? (
          userContents.map((userContent) => (
            <ContentCard
              content={userContent}
              key={userContent.idConteudo}
              isAddedToMyList
              searchContentName={searchContentName}
              selectedMenuCategory={selectedMenuCategory}
            />
          ))
        ) : (
          <p>{t('home_page.components.my_contents_list.no_content_on_list')}</p>
        )}
      </section>
      <div className={styles.paginationContainer}>
        {/* <Pagination count={pageCount} color="primary" variant="outlined" /> */}
      </div>
    </section>
  );
}
