import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchContent, useFetchContentMedias } from '../../hooks/useContent';
import { ContentIntroduction } from './components/ContentIntroduction';
import { ContentSkeleton } from './components/ContentSkeleton';
import { SectionsList } from './components/SectionsList';
import styles from './styles.module.scss';

export function Content() {
  const { id } = useParams();
  const { content, isFetchingContent, fetchContent } = useFetchContent();
  const { contentMedias, getContentMedias, isFetchingContentMedias } = useFetchContentMedias();

  const { t } = useTranslation();

  useEffect(() => {
    fetchContent(Number(id));
    getContentMedias(Number(id), true);
  }, []);

  if (isFetchingContent) {
    return <ContentSkeleton />;
  }

  return (
    <div className={styles.container}>
      {content && contentMedias && (
        <>
          <ContentIntroduction content={content} fetchContent={fetchContent} />
          <div className={styles.sectionsContainer}>
            <h1>{t('content_page.index.sections_title')}</h1>
            <SectionsList
              sections={contentMedias}
              content={content}
              getContentMedias={getContentMedias}
              isFetchingContentMedias={isFetchingContentMedias}
            />
          </div>
        </>
      )}
    </div>
  );
}
