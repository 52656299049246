/* eslint-disable react/function-component-definition */
import { TextField, FormLabel, FormHelperText, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OutlinedTextFieldProps } from '@mui/material/TextField';
import { forwardRef, ForwardRefRenderFunction } from 'react';

type TextFieldProps = Omit<OutlinedTextFieldProps, 'variant'>;

interface InputProps extends TextFieldProps {
  name: string;
  label?: string;
  error?: any;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ label, error, name, ...rest }, ref) => {
  const { t } = useTranslation();

  return (
    <FormControl error={!!error} fullWidth component="fieldset">
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <TextField
        error={!!error}
        name={name}
        id={name}
        color="primary"
        size="small"
        fullWidth
        variant="outlined"
        {...rest}
        ref={ref}
      />
      {!!error && <FormHelperText>{t(`${error.message}`)}</FormHelperText>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
