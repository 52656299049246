import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

export function LoadIndicator() {
  return (
    <div className={styles.container}>
      <CircularProgress />
    </div>
  );
}
