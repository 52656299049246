import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UserPostBody,
  FetchUserParams,
  UserData,
  UserResponse,
  UserPutBody,
  UserPassword,
  PasswordRecoveryData,
  UserPreferences,
  FetchUserHistoryParamsType,
  UserHistoryResponseType,
} from '../interfaces/User';
import { api } from '../services/api';
import { Metadata } from '../interfaces/ApiResponse';
import { useToastContext } from '../context/ToastContext';
import { useAuthContext } from '../context/AuthContext';
import { ActivityUserData } from '../interfaces/ActivityUser';
import { CompanyData, CompanyResponse, FetchCompaniesParams } from '../interfaces/Company';

async function fetchUsers({
  page,
  pageSize,
  primeiroNome,
  ultimoNome,
  email,
  ativo,
  order,
  orderBy,
}: FetchUserParams): Promise<UserResponse> {
  const { data } = await api.get<UserResponse>(`usuario`, {
    params: {
      pageSize,
      page,
      ativo,
      primeiroNome,
      ultimoNome,
      email,
      order,
      orderBy,
    },
  });
  return data;
}

async function fetchAuthenticatedUserHistory({
  page,
  pageSize,
  order,
  orderBy,
  idCategoria,
  idConteudo,
  idUsuario,
  dataAte,
  dataDe,
}: FetchUserHistoryParamsType): Promise<UserHistoryResponseType> {
  const { data } = await api.get<UserHistoryResponseType>(`/usuario/atividadeusuario`, {
    params: {
      page,
      pageSize,
      order,
      orderBy,
      idCategoria,
      idConteudo,
      idUsuario,
      dataDe,
      dataAte,
    },
  });

  return data;
}

export async function fetchUserCompanies({ page, pageSize }: FetchCompaniesParams): Promise<CompanyResponse> {
  const { data } = await api.get<CompanyResponse>('/usuario/empresa', {
    params: {
      page,
      pageSize,
    },
  });
  return data;
}

export function useFetchUsers() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);

  async function getUsers({
    page,
    pageSize,
    primeiroNome,
    ultimoNome,
    email,
    ativo,
    itemInList,
    order,
    orderBy,
  }: FetchUserParams) {
    setIsFetchingUsers(true);

    try {
      const data = await fetchUsers({ pageSize, page, primeiroNome, ultimoNome, email, ativo, order, orderBy });

      if (itemInList && Object.keys(itemInList).length !== 0) {
        data.results = data.results.filter((user) => user.idUsuario !== itemInList.idUsuario);
        data.results = [itemInList, ...data.results];
        if (data.results.length > pageSize) {
          data.results.pop();
        }
      }

      setUsers(data.results);
      setMetadata({ ...data });
    } finally {
      setIsFetchingUsers(false);
    }
  }

  return { users, getUsers, isFetchingUsers, metadata };
}

export function useCreateUser() {
  const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function createUser(user: UserPostBody) {
    setIsCreatingUser(true);

    try {
      const response = await createPromiseToast(
        api.post<UserData>('/usuario', user),
        {
          error: t('hooks.use_user.create_user_error', { userName: user.primeiroNome }),
          pending: t('hooks.use_user.create_user_pending', { userName: user.primeiroNome }),
          success: t('hooks.use_user.create_user_success', { userName: user.primeiroNome }),
        },
        true
      );
      return response.data;
    } catch (error: unknown) {
      return {};
    } finally {
      setIsCreatingUser(false);
    }
  }

  return { createUser, isCreatingUser };
}

export function useEditUser() {
  const [isEditingUser, setIsEditingUser] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { getCurrentUser } = useAuthContext();
  const { t } = useTranslation();

  async function editUser(user: UserPutBody) {
    setIsEditingUser(true);

    try {
      const response = await createPromiseToast(api.put<UserData>(`/usuario`, user), {
        error: t('hooks.use_user.edit_user_error', { userName: user.primeiroNome }),
        pending: t('hooks.use_user.edit_user_pending', { userName: user.primeiroNome }),
        success: t('hooks.use_user.edit_user_success', { userName: user.primeiroNome }),
      });
      await getCurrentUser();
      return response.data;
    } finally {
      setIsEditingUser(false);
    }
  }

  return { editUser, isEditingUser };
}

export function useDeleteUser() {
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { getCurrentUser } = useAuthContext();
  const { t } = useTranslation();

  async function deleteUser(user: UserData) {
    setIsDeletingUser(true);

    try {
      await createPromiseToast(api.delete(`/usuario/${user.idUsuario}`), {
        error: t('hooks.use_user.delete_user_error', { userName: user.primeiroNome }),
        pending: t('hooks.use_user.delete_user_pending', { userName: user.primeiroNome }),
        success: t('hooks.use_user.delete_user_success', { userName: user.primeiroNome }),
      });
      await getCurrentUser();
    } finally {
      setIsDeletingUser(false);
    }
  }

  return { deleteUser, isDeletingUser };
}

export const changePassword = async (data: UserPassword) => {
  await api.patch('/usuario/senha', data);
};

export const PasswordRecovery = async (data: PasswordRecoveryData) => {
  await api.post('/usuario/senha', data);
};

export async function changePreferences(data: UserPreferences) {
  await api.put('/usuario/preferencia', data);
}

export function useFetchUserHistory() {
  const [userHistory, setUserHistory] = useState<ActivityUserData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingUserHistory, setIsFetchingUserHistory] = useState<boolean>(false);

  async function getUserHistory({
    page,
    pageSize,
    order,
    orderBy,
    idCategoria,
    idConteudo,
    idUsuario,
    dataAte,
    dataDe,
  }: FetchUserHistoryParamsType) {
    setIsFetchingUserHistory(true);
    try {
      const data = await fetchAuthenticatedUserHistory({
        page,
        pageSize,
        order,
        orderBy,
        idCategoria,
        idConteudo,
        idUsuario,
        dataAte,
        dataDe,
      });
      setUserHistory(data.results);
      const { results, ...rest } = data;
      setMetadata({ ...rest });
    } finally {
      setIsFetchingUserHistory(false);
    }
  }

  return { getUserHistory, metadata, userHistory, isFetchingUserHistory };
}

export function useGetUserCompanies() {
  const [userCompanies, setUserCompanies] = useState<CompanyData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingUserCompanies, setIsFetchingUserCompanies] = useState<boolean>(false);

  async function getUserCompanies({ page, pageSize }: FetchCompaniesParams) {
    setIsFetchingUserCompanies(true);

    try {
      const data = await fetchUserCompanies({ page, pageSize });
      setUserCompanies(data.results);
      setMetadata({ ...data });
    } finally {
      setIsFetchingUserCompanies(false);
    }
  }

  return { getUserCompanies, metadata, userCompanies, isFetchingUserCompanies };
}
