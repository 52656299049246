import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import styles from './styles.module.scss';
import { PasswordRecovery } from '../../../../hooks/useUsers';
import { PasswordRecoveryData } from '../../../../interfaces/User';
import { useToastContext } from '../../../../context/ToastContext';

interface PasswordRecoveryModalProps extends ModalProps {}

export function PasswordRecoveryModal({ isOpen, onClose }: PasswordRecoveryModalProps) {
  const [emailRecuperacao, setEmailRecuperacao] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { createToast } = useToastContext();
  const { t } = useTranslation();

  const handlePasswordRecovery = async () => {
    const data: PasswordRecoveryData = {
      email: emailRecuperacao,
    };

    if (data.email.trim() === '') {
      createToast(t('login_page.components.password_recovery_modal.fill_email_error'), 'error');
      return;
    }

    setIsButtonDisabled(true);
    try {
      await PasswordRecovery(data);
      createToast(t('login_page.components.password_recovery_modal.recovery_email_success'), 'success');
      setEmailRecuperacao('');
    } catch (error) {
      createToast(t('login_page.components.password_recovery_modal.recovery_email_error'), 'error');
    }
    setIsButtonDisabled(false);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('login_page.components.password_recovery_modal.modal_title')}
      showSaveButton={false}
    >
      <div className={styles.selectContainerMain}>
        <p className={styles.emailTitle}>{t('login_page.components.password_recovery_modal.recovery_title')}</p>
        <div className={styles.selectContainer}>
          <TextField
            id="current-email"
            label={t('login_page.components.password_recovery_modal.current_email_label')}
            type="e-mail"
            value={emailRecuperacao}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdEmail size={24} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => setEmailRecuperacao(event.target.value)}
          />
        </div>
        <div className={styles.ContainerButton}>
          <button onClick={() => handlePasswordRecovery()} disabled={isButtonDisabled} type="submit">
            {t('login_page.components.password_recovery_modal.submit_recovery_button')}
          </button>
        </div>
      </div>
    </BaseModal>
  );
}
