import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import SimpleMdeReact from 'react-simplemde-editor';
import ReactDOMServer from 'react-dom/server';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import EasyMDE from 'easymde';
import { Input } from '../../../../../../components/Input';
import styles from './styles.module.scss';
import { useGetCategories } from '../../../../../../hooks/useCategories';
import 'easymde/dist/easymde.min.css';
import { ContentData, ContentPutBody } from '../../../../../../interfaces/Content';
import ReactMarkdownShow from '../../../../../../components/ReactMarkdownShow/ReactMarkdownShow';

interface DetailsModalProps {
  contentToEdit: ContentData;
}

export function DetailsModal({ contentToEdit }: DetailsModalProps) {
  const {
    register,
    setValue,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<ContentPutBody>();

  const watchCapa = watch('capa');
  const watchAtivo = watch('ativo');

  const pageSize = 99999999;
  const [selectedCoverImage, setSelectedCoverImage] = useState<File | null>(null);
  const { categories, getCategories } = useGetCategories();

  const inputElementFile = useRef<HTMLInputElement>(null);

  const descriptionMarkdownEditorOptions = useMemo(
    () =>
      ({
        maxHeight: '200px',
        spellChecker: false,
        nativeSpellcheck: true,
        initialValue: '',
        toolbar: [
          {
            name: 'bold',
            action: EasyMDE.toggleBold,
            className: 'fa fa-bold',
            title: 'Negrito',
          },
          {
            name: 'italic',
            action: EasyMDE.toggleItalic,
            className: 'fa fa-italic',
            title: 'Itálico',
          },
          {
            name: 'ordered-list',
            action: EasyMDE.toggleOrderedList,
            className: 'fa fa-list-ol',
            title: 'Inserir Lista Ordenada',
          },
          {
            name: 'unordered-list',
            action: EasyMDE.toggleUnorderedList,
            className: 'fa fa-list-ul',
            title: 'Inserir Lista Não Ordenada',
          },
          {
            name: 'heading',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Cabeçalho',
          },
          {
            name: 'heading-smaller',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Diminuir Cabeçalho',
          },
          {
            name: 'heading-bigger',
            action: EasyMDE.toggleHeadingBigger,
            className: 'fa fa-header',
            title: 'Aumentar Cabeçalho',
          },
          {
            name: 'quote',
            action: EasyMDE.toggleBlockquote,
            className: 'fa fa-quote-left',
            title: 'Inserir Citação',
          },
          {
            name: 'horizontal-rule',
            action: EasyMDE.drawHorizontalRule,
            className: 'fa fa-minus',
            title: 'Inserir Linha Horizontal',
          },
          {
            name: 'upload-image',
            action: EasyMDE.drawImage,
            className: 'fa fa-picture-o',
            title: 'Inserir Link de Imagem',
          },
          {
            name: 'link',
            action: EasyMDE.drawLink,
            className: 'fa fa-link',
            title: 'Inserir Link',
          },
          {
            name: 'table',
            action: EasyMDE.drawTable,
            className: 'fa fa-table',
            title: 'Inserir Tabela',
          },
          {
            name: 'code',
            action: EasyMDE.toggleCodeBlock,
            className: 'fa fa-code',
            title: 'Inserir Código',
          },
          {
            name: 'preview',
            action: EasyMDE.togglePreview,
            className: 'fa fa-eye no-disable',
            title: 'Visualizar',
          },
          {
            name: 'side-by-side',
            action: EasyMDE.toggleSideBySide,
            className: 'fa fa-columns no-disable no-mobile',
            title: 'Lado a Lado',
          },
          {
            name: 'fullscreen',
            action: EasyMDE.toggleFullScreen,
            className: 'fa fa-arrows-alt no-disable no-mobile',
            title: 'Tela Cheia',
          },
          {
            name: 'guide',
            action: () => {
              window.open('https://www.markdownguide.org/basic-syntax/');
            },
            className: 'fa fa-question-circle',
            title: 'Guia Markdown',
          },
        ],
        previewRender: (plainText) => ReactDOMServer.renderToString(<ReactMarkdownShow>{plainText}</ReactMarkdownShow>),
      } as EasyMDE.Options),
    []
  );

  const { t } = useTranslation();

  function validateImage(image: File | null): boolean {
    if (image) {
      if (!image.type.startsWith('image/')) {
        setError('capa', { message: t('contents_page.components.add_new_content_form.corrupted_image_error') });
        return false;
      }
      if (image.size > 1000000) {
        setError('capa', { message: t('contents_page.components.add_new_content_form.max_image_size_error') });
        return false;
      }
      clearErrors('capa');
      return true;
    }
    return false;
  }

  function onChangeImageUpload(files: FileList | null) {
    if (files) {
      const selectedImage = files[0];

      if (validateImage(selectedImage)) {
        setSelectedCoverImage(selectedImage);

        const reader = new FileReader();

        reader.addEventListener('load', () => {
          const imageUrl = reader.result?.toString();
          setValue('capa', imageUrl || '');
          setValue('mimeType', selectedImage.type);
        });

        reader.readAsDataURL(selectedImage);
      }
    }
  }

  function removeImageUpload() {
    setSelectedCoverImage(null);
    setValue('capa', '');
    setValue('mimeType', '');
    if (inputElementFile.current) {
      inputElementFile.current.value = '';
    }
  }

  useEffect(() => {
    getCategories({ page: 1, pageSize, ativo: true });
  }, []);

  return (
    <div className={styles.courseModalContainer}>
      <div className={styles.heading}>
        <Input
          type="text"
          label={t('contents_page.components.add_new_content_form.content_name_input_label')}
          {...register('nome')}
          error={errors.nome}
          fullWidth
        />
        <Controller
          name="idCategoria"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth size="small" error={!!fieldState.error} component="fieldset">
              <FormLabel htmlFor="categoria" error={!!fieldState.error}>
                {t('contents_page.components.add_new_content_form.category_select_label')}
              </FormLabel>
              <Select
                id="categoria"
                color="primary"
                size="small"
                value={categories.length ? field.value : ''}
                onChange={field.onChange}
              >
                {categories.map((category) => (
                  <MenuItem value={category.idCategoria} key={category.idCategoria}>
                    {category.nome}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <div className={styles.description}>
        <Controller
          control={control}
          name="descricao"
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error} component="fieldset">
              <FormLabel htmlFor="descricao" error={!!fieldState.error}>
                {t('contents_page.components.add_new_content_form.description_input_label')}
              </FormLabel>
              <SimpleMdeReact
                value={field.value}
                onChange={field.onChange}
                options={descriptionMarkdownEditorOptions}
              />
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <div className={styles.coverImage}>
        <FormControl fullWidth error={!!errors.capa} component="fieldset" className={styles.imageUploadContainer}>
          <FormLabel htmlFor="contentCover">
            {' '}
            {t('contents_page.components.add_new_content_form.cover_image_input_label')}
          </FormLabel>
          <div className={styles.imageFileInputContainer}>
            <Input
              type="text"
              value={selectedCoverImage?.name || t('contents_page.components.add_new_content_form.no_image_selected')}
              name="contentCover"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button variant="contained" className={styles.imageFileButton} component="label">
              {t('contents_page.components.add_new_content_form.add_new_cover_image_button')}
              <input type="file" hidden onChange={(e) => onChangeImageUpload(e.target.files)} ref={inputElementFile} />
            </Button>
          </div>
          <FormHelperText error={!!errors.capa}>{errors.capa?.message}</FormHelperText>
        </FormControl>
        {watchCapa || contentToEdit.capa ? (
          <div className={styles.imageContentContainer}>
            <img
              alt={t('contents_page.components.add_new_content_form.cover_image_alt')}
              className={styles.imageContent}
              src={watchCapa || contentToEdit.capa}
            />
            {watchCapa && (
              <IconButton className={styles.removeImageIcon} onClick={() => removeImageUpload()}>
                <FiTrash2 />
              </IconButton>
            )}
          </div>
        ) : (
          <span className={styles.noImageSelectedContainer}>
            <MdOutlineImageNotSupported size={150} />
          </span>
        )}
      </div>
      <div className={styles.footer}>
        <FormControlLabel
          control={<Checkbox {...register('ativo')} checked={watchAtivo} />}
          label={t('contents_page.components.add_new_content_form.active_content_checkbox_label')}
        />
      </div>
    </div>
  );
}
