import { HiOutlineCheckCircle, HiOutlineDocumentDownload } from 'react-icons/hi';
import { Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaData } from '../../../../interfaces/Media';
import styles from './styles.module.scss';
import { MediaEnum } from '../../../../enums/Media';
import { WatchVideoButton } from '../WatchVideoButton';
import ReactMarkdownShow from '../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { ContentData } from '../../../../interfaces/Content';
import { downloadMedia } from '../../../Medias/components/DownloadButton';
import { ListenAudioButton } from '../ListenAudioButton';
import { PDFViewerModal, ReturnFileCallBackProps } from '../../../../components/PDFViewerModal';
import { useModal } from '../../../../hooks/useModal';

interface SectionCardProps {
  section: MediaData;
  content: ContentData;
  secondaryBackground: boolean;
  getContentMedias: (contentId: number, active?: boolean) => {};
}

export function SectionCard({ section, secondaryBackground, content, getContentMedias }: SectionCardProps) {
  const [isDownloadingMedia, setIsDownloadingMedia] = useState<boolean>(false);
  const [isPdfModalOpen, handleOpenPdfModal, handleClosePdfModal] = useModal();
  const [pdfFileContent, setPdfFileContent] = useState<ReturnFileCallBackProps>({} as ReturnFileCallBackProps);

  const { t } = useTranslation();

  function openPdfModal(params: ReturnFileCallBackProps) {
    const { file, mediaName, mediaId } = params;
    setPdfFileContent({ file, mediaName, mediaId });
    handleOpenPdfModal();
  }

  async function onClickDownloadButton() {
    setIsDownloadingMedia(true);
    try {
      await downloadMedia(section, openPdfModal);
    } finally {
      setIsDownloadingMedia(false);
      getContentMedias(Number(content.idConteudo), true);
    }
  }

  function checkIfCompleted() {
    if (section?.status?.dataDownload) {
      return true;
    }
    if (section?.status?.dataInicio && section?.status?.dataFim) {
      return true;
    }
    return false;
  }

  function onUpdateMediaMessage() {
    getContentMedias(Number(content.idConteudo), true);
  }

  function returnMediaButton() {
    if (section.tipo === MediaEnum.Vídeo || section.tipo === MediaEnum.YouTube) {
      return (
        <WatchVideoButton
          media={section}
          title={content.nome || ''}
          onUpdateVideoMessage={() => onUpdateMediaMessage()}
        />
      );
    }
    if (section.tipo === MediaEnum.Áudio) {
      return (
        <ListenAudioButton
          media={section}
          title={content.nome || ''}
          onUpdateAudioMessage={() => onUpdateMediaMessage()}
        />
      );
    }
    return (
      <Button variant="contained" startIcon={<HiOutlineDocumentDownload />} onClick={() => onClickDownloadButton()}>
        {isDownloadingMedia ? (
          <CircularProgress size="24.5px" style={{ color: 'white' }} />
        ) : (
          <>{t('content_page.components.section_card.download_media_button')}</>
        )}
      </Button>
    );
  }

  return (
    <div className={`${secondaryBackground ? styles.cardContainerSecondaryBackground : styles.cardContainer}`}>
      <div className={styles.cardContent}>
        <h5 className={styles.title}>{section.nome}</h5>
        <div className={styles.description}>
          <ReactMarkdownShow>{section.descricao || ''}</ReactMarkdownShow>
        </div>
        {returnMediaButton()}
      </div>
      <div className={styles.sectionCompleted}>
        {checkIfCompleted() && (
          <>
            <HiOutlineCheckCircle />
            <span>{t('content_page.components.section_card.media_completed')}</span>
          </>
        )}
      </div>
      {isPdfModalOpen && (
        <PDFViewerModal
          isOpen={isPdfModalOpen}
          onClose={handleClosePdfModal}
          pdfFileContent={pdfFileContent}
          onUpdateDocumentRead={() => onUpdateMediaMessage()}
        />
      )}
    </div>
  );
}
