import { useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import EasyMDE from 'easymde';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import SimpleMdeReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import {
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import ReactMarkdownShow from '../../../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { Input } from '../../../../../../components/Input';
import { useGetCategories } from '../../../../../../hooks/useCategories';
import { ContentPostBody } from '../../../../../../interfaces/Content';
import styles from './styles.module.scss';
import { useContentsContext } from '../../../../../../context/ContentsContext';
import { LoadIndicator } from '../../../../../../components/LoadIndicator';

export function NewContentForm() {
  const pageSize = 99999999;
  const [selectedCoverImage, setSelectedCoverImage] = useState<File | null>(null);
  const [selectedCoverImageUrl, setSelectedCoverImageUrl] = useState<string>('');

  const { t } = useTranslation();

  const { categories, getCategories } = useGetCategories();
  const { isCreatingContent } = useContentsContext();

  useEffect(() => {
    getCategories({ page: 1, pageSize, ativo: true });
  }, []);

  const {
    register,
    setValue,
    control,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext<ContentPostBody>();

  const descriptionMarkdownEditorOptions = useMemo(
    () =>
      ({
        maxHeight: '200px',
        spellChecker: false,
        nativeSpellcheck: true,
        toolbar: [
          {
            name: 'bold',
            action: EasyMDE.toggleBold,
            className: 'fa fa-bold',
            title: 'Negrito',
          },
          {
            name: 'italic',
            action: EasyMDE.toggleItalic,
            className: 'fa fa-italic',
            title: 'Itálico',
          },
          {
            name: 'ordered-list',
            action: EasyMDE.toggleOrderedList,
            className: 'fa fa-list-ol',
            title: 'Inserir Lista Ordenada',
          },
          {
            name: 'unordered-list',
            action: EasyMDE.toggleUnorderedList,
            className: 'fa fa-list-ul',
            title: 'Inserir Lista Não Ordenada',
          },
          {
            name: 'heading',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Cabeçalho',
          },
          {
            name: 'heading-smaller',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Diminuir Cabeçalho',
          },
          {
            name: 'heading-bigger',
            action: EasyMDE.toggleHeadingBigger,
            className: 'fa fa-header',
            title: 'Aumentar Cabeçalho',
          },
          {
            name: 'quote',
            action: EasyMDE.toggleBlockquote,
            className: 'fa fa-quote-left',
            title: 'Inserir Citação',
          },
          {
            name: 'horizontal-rule',
            action: EasyMDE.drawHorizontalRule,
            className: 'fa fa-minus',
            title: 'Inserir Linha Horizontal',
          },
          {
            name: 'upload-image',
            action: EasyMDE.drawImage,
            className: 'fa fa-picture-o',
            title: 'Inserir Link de Imagem',
          },
          {
            name: 'link',
            action: EasyMDE.drawLink,
            className: 'fa fa-link',
            title: 'Inserir Link',
          },
          {
            name: 'table',
            action: EasyMDE.drawTable,
            className: 'fa fa-table',
            title: 'Inserir Tabela',
          },
          {
            name: 'code',
            action: EasyMDE.toggleCodeBlock,
            className: 'fa fa-code',
            title: 'Inserir Código',
          },
          {
            name: 'preview',
            action: EasyMDE.togglePreview,
            className: 'fa fa-eye no-disable',
            title: 'Visualizar',
          },
          {
            name: 'side-by-side',
            action: EasyMDE.toggleSideBySide,
            className: 'fa fa-columns no-disable no-mobile',
            title: 'Lado a Lado',
          },
          {
            name: 'fullscreen',
            action: EasyMDE.toggleFullScreen,
            className: 'fa fa-arrows-alt no-disable no-mobile',
            title: 'Tela Cheia',
          },
          {
            name: 'guide',
            action: () => {
              window.open('https://www.markdownguide.org/basic-syntax/');
            },
            className: 'fa fa-question-circle',
            title: 'Guia Markdown',
          },
        ],
        previewRender: (plainText) => ReactDOMServer.renderToString(<ReactMarkdownShow>{plainText}</ReactMarkdownShow>),
      } as EasyMDE.Options),
    []
  );

  function validateImage(image: File | null): boolean {
    if (image) {
      if (!image.type.startsWith('image/')) {
        setError('capa', { message: t('contents_page.components.add_new_content_form.corrupted_image_error') });
        return false;
      }
      if (image.size > 1000000) {
        setError('capa', { message: t('contents_page.components.add_new_content_form.max_image_size_error') });
        return false;
      }
      clearErrors('capa');
      return true;
    }
    return false;
  }

  function onChangeImageUpload(files: FileList | null) {
    if (files) {
      const selectedImage = files[0];

      if (validateImage(selectedImage)) {
        setSelectedCoverImage(selectedImage);

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setSelectedCoverImageUrl(reader.result?.toString() || '');
          setValue('capa', String(reader.result));
          setValue('mimeType', selectedImage.type);
        });
        reader.readAsDataURL(selectedImage);
      }
    }
  }

  function removeImageUpload() {
    setSelectedCoverImage(null);
    setSelectedCoverImageUrl('');
    setValue('capa', '');
    setValue('mimeType', '');
    clearErrors('capa');
  }

  return (
    <div className={styles.contentModalContainer}>
      {isCreatingContent ? (
        <div className={styles.loadContainer}>
          <LoadIndicator />
        </div>
      ) : (
        <>
          <div className={styles.heading}>
            <Input
              label={t('contents_page.components.add_new_content_form.content_name_input_label')}
              {...register('nome')}
              error={errors.nome}
              fullWidth
            />
            <Controller
              name="idCategoria"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth size="small" error={!!fieldState.error} component="fieldset">
                  <FormLabel htmlFor="categoria" error={!!fieldState.error}>
                    {t('contents_page.components.add_new_content_form.category_select_label')}
                  </FormLabel>
                  <Select
                    id="categoria"
                    color="primary"
                    size="small"
                    value={field.value || ''}
                    onChange={field.onChange}
                  >
                    {categories.map((category) => (
                      <MenuItem value={category.idCategoria} key={category.idCategoria}>
                        {category.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className={styles.description}>
            <Controller
              control={control}
              name="descricao"
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={!!fieldState.error} component="fieldset">
                  <FormLabel htmlFor="descricao">
                    {t('contents_page.components.add_new_content_form.description_input_label')}
                  </FormLabel>
                  <SimpleMdeReact
                    value={field.value}
                    onChange={field.onChange}
                    options={descriptionMarkdownEditorOptions}
                  />
                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className={styles.coverImage}>
            <FormControl fullWidth error={!!errors.capa} component="fieldset" className={styles.imageUploadContainer}>
              <FormLabel htmlFor="contentCover">
                {t('contents_page.components.add_new_content_form.cover_image_input_label')}
              </FormLabel>
              <div className={styles.imageFileInputContainer}>
                <Input
                  type="text"
                  value={selectedCoverImage?.name || t('contents_page.components.add_new_content_form.no_image_selected')}
                  name="contentCover"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Button variant="contained" className={styles.imageFileButton} component="label">
                  {t('contents_page.components.add_new_content_form.add_new_cover_image_button')}
                  <input type="file" hidden onChange={(e) => onChangeImageUpload(e.target.files)} />
                </Button>
              </div>
              <FormHelperText error={!!errors.capa}>{errors.capa?.message}</FormHelperText>
            </FormControl>
            {selectedCoverImageUrl ? (
              <div className={styles.imageContentContainer}>
                <img
                  alt={t('contents_page.components.add_new_content_form.cover_image_alt')}
                  className={styles.imageContent}
                  src={selectedCoverImageUrl}
                />
                <IconButton className={styles.removeImageIcon} onClick={() => removeImageUpload()}>
                  <FiTrash2 />
                </IconButton>
              </div>
            ) : (
              <span className={styles.noImageSelectedContainer}>
                <MdOutlineImageNotSupported size={150} />
              </span>
            )}
          </div>
          <div className={styles.footer}>
            <FormControlLabel
              control={<Checkbox {...register('ativo')} defaultChecked={getValues('ativo')} />}
              label={t('contents_page.components.add_new_content_form.active_content_checkbox_label')}
            />
          </div>
        </>
      )}
    </div>
  );
}
