import {
  Checkbox,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserData } from '../../../../interfaces/User';
import styles from './styles.module.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function getStatusCellStyles(status: boolean) {
  if (status === true) {
    return styles.activeBox;
  }
  return styles.inactiveBox;
}

interface UsersTableProps {
  userList: UserData[];
  handleSelection: (selectionValue: number, select: boolean) => void;
  handleSelectAll: (select: boolean) => void;
  selected: number[];
}

export function SelectUserTable({ userList, handleSelection, handleSelectAll, selected = [] }: UsersTableProps) {
  const { t } = useTranslation();

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    handleSelection(Number(value), checked);
  };

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    handleSelectAll(checked);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.checkboxCell}>
              <Checkbox
                sx={{
                  color: 'black',
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
                onChange={onSelectAll}
                checked={userList.length > 0 ? userList.every((user) => selected.includes(user.idUsuario || 0)) : false}
              />
            </StyledTableCell>
            <StyledTableCell>{t('users_page.components.users_table.table.first_name_header')}</StyledTableCell>
            <StyledTableCell>{t('users_page.components.users_table.table.last_name_header')}</StyledTableCell>
            <StyledTableCell>E-mail</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((user) => (
            <StyledTableRow key={user.idUsuario}>
              <TableCell className={styles.checkboxCell}>
                <Checkbox
                  className={styles.checkbox}
                  value={user.idUsuario}
                  onChange={onSelect}
                  checked={selected.includes(user.idUsuario || 0)}
                />
              </TableCell>
              <TableCell>{user.primeiroNome}</TableCell>
              <TableCell>{user.ultimoNome}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell width={20}>
                <div className={getStatusCellStyles(user.ativo || false)}>{user.ativo ? 'Active' : 'Inactive'}</div>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
