import { MenuItem, Select } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../BaseModal';
import styles from './styles.module.scss';
import { changePreferences } from '../../../../hooks/useUsers';
import { useAuthContext } from '../../../../context/AuthContext';
import { UserPreferences } from '../../../../interfaces/User';
import { useToastContext } from '../../../../context/ToastContext';
import { ColorPalette, useThemeContext } from '../../../../context/ThemeContext';
import { ShowLanguage } from '../../../../enums/ShowLanguage';

interface UserPreferencesModalProps extends ModalProps {}

export function UserPreferencesModal({ isOpen, onClose }: UserPreferencesModalProps) {
  const [languageSelected, setLanguageSelected] = useState<string>('');

  const { updateColors, resetColors, initialColors } = useThemeContext();
  const { currentUser, getCurrentUser } = useAuthContext();
  const { createPromiseToast, createToast } = useToastContext();

  const [color, setColor] = useState<ColorPalette>(initialColors);

  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameColor: string) => {
    if (nameColor === 'header') {
      setColor({
        ...color,
        header: event.target.value,
      });
    }
    if (nameColor === 'primary') {
      setColor({
        ...color,
        primary: event.target.value,
      });
    }
    if (nameColor === 'secondary') {
      setColor({
        ...color,
        secondary: event.target.value,
      });
    }
  };

  function updateThemeColors() {
    updateColors(color);
  }

  async function updateLanguage() {
    const preferences: UserPreferences = {
      idioma: languageSelected,
    };

    localStorage.setItem('language', languageSelected);

    try {
      await createPromiseToast(changePreferences(preferences), {
        error: t('components.user_preferences_modal.change_preferences_error'),
        pending: t('components.user_preferences_modal.change_preferences_pending'),
        success: t('components.user_preferences_modal.change_preferences_success'),
      });
      await getCurrentUser();
    } finally {
      onClose();
    }
  }

  function saveData() {
    updateThemeColors();
    updateLanguage();
  }

  function resetColorsApp() {
    createToast(t('components.user_preferences_modal.change_preferences_success'), 'success');
    resetColors();
    onClose();
  }

  useEffect(() => {
    setLanguageSelected(currentUser?.idioma || '');
  }, [currentUser]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('components.user_preferences_modal.modal_title')}
      showSaveButton
      showSecondaryButton
      secondaryButtonText={t('components.user_preferences_modal.reset_colors')}
      onSaveCallback={() => saveData()}
      onSecondaryButtonCallback={() => resetColorsApp()}
    >
      <div className={styles.selectContainer}>
        <span>{t('components.user_preferences_modal.language_input_label')}</span>
        <Select
          id="language-select"
          value={languageSelected}
          size="small"
          color="primary"
          onChange={(e) => setLanguageSelected(e.target.value)}
        >
          {Object.keys(ShowLanguage).map((lang) => (
            <MenuItem key={lang} value={lang}>
              {t(ShowLanguage[lang])}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.changeColorContainer}>
        <span>{t('components.user_preferences_modal.color_pallete')}</span>
        <div className={styles.colorsColumns}>
          <fieldset>
            <div>
              <input type="color" defaultValue={initialColors.header} onChange={(e) => handleChange(e, 'header')} />
              <span>{t('components.user_preferences_modal.header_color')}</span>
            </div>
            <div>
              <input type="color" defaultValue={initialColors.primary} onChange={(e) => handleChange(e, 'primary')} />
              <span>{t('components.user_preferences_modal.primary_color')}</span>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <div className={styles.primaryColorTransparent} />
              <span>{t('components.user_preferences_modal.primary_transparent_color')}</span>
            </div>
            <div>
              <input
                type="color"
                defaultValue={initialColors.secondary}
                onChange={(e) => handleChange(e, 'secondary')}
              />
              <span>{t('components.user_preferences_modal.secondary_color')}</span>
            </div>
          </fieldset>
        </div>
      </div>
    </BaseModal>
  );
}
