import * as yup from 'yup';

export const contentFormPost = yup.object({
  nome: yup.string().required('form_schemas.contents.name_required'),
  idCategoria: yup.number().required('form_schemas.contents.category_id_required'),
  descricao: yup.string().required('form_schemas.contents.description_required'),
  capa: yup.string().required('form_schemas.contents.cover_image_required'),
});

export const contentFormPut = yup.object({
  nome: yup.string().required('form_schemas.contents.name_required'),
  idCategoria: yup.number().required('form_schemas.contents.category_id_required'),
  descricao: yup.string().required('form_schemas.contents.description_required'),
});
