import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { useAuthContext } from '../../../../context/AuthContext';
import { CompanyData } from '../../../../interfaces/Company';
import styles from './styles.module.scss';

interface SelectCompanyModalProps extends ModalProps {
  companies: CompanyData[];
}

export function SelectCompanyModal({ isOpen, onClose, companies }: SelectCompanyModalProps) {
  const { doLoginCompany } = useAuthContext();

  const { t } = useTranslation();

  function handleCompanyOnClick(company: CompanyData) {
    doLoginCompany({ idEmpresa: company.idEmpresa || 0 });
  }

  return (
    <BaseModal
      showCloseButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('login_page.components.select_company_modal.modal_title')}
      showSaveButton={false}
    >
      <div className={styles.selectCompanyContainerMain}>
        <p className={styles.title}>{t('login_page.components.select_company_modal.enter_platform')}</p>
        <div className={styles.selectCompanyContainer}>
          {companies.map((company) => (
            <button
              type="button"
              className={styles.companyCard}
              key={company.idEmpresa}
              onClick={() => handleCompanyOnClick(company)}
            >
              <span>{company.nome}</span>
              <span>{company.cnpj?.replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, '$1.$2.$3/$4-$5')}</span>
            </button>
          ))}
        </div>
      </div>
    </BaseModal>
  );
}
