import { useState } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableBody,
  IconButton,
  styled,
  tableCellClasses,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import styles from './styles.module.scss';
import { useModal } from '../../../../hooks/useModal';
import { EditContentModal } from '../EditContentModal';
import { ContentData, ContentParamsToOrderBy } from '../../../../interfaces/Content';
import { useContentsContext } from '../../../../context/ContentsContext';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { DeleteAlertModal } from '../../../../components/DeleteAlertModal';
import { useAuthContext } from '../../../../context/AuthContext';
import { CategoryData } from '../../../../interfaces/Category';
import { EditCategoryModal } from '../../../Categories/components/EditCategoryModal';
import { TableSortProps, useTableSorting } from '../../../../hooks/useTableSorting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface ContentsTableProps extends TableSortProps<ContentParamsToOrderBy> {}

export function ContentsTable({ onTableSort }: ContentsTableProps) {
  const [isEditContentModalOpen, handleOpenEditContentModal, handleCloseEditContentModal] = useModal();
  const [isDeleteContentModalOpen, handleOpenDeleteContentModal, handleCloseDeleteContentModal] = useModal();

  const [categoryToEdit, setCategoryToEdit] = useState<CategoryData | null>(null);
  const [isEditCategoryModalOpen, handleOpenEditCategoryModal, handleCloseEditCategoryModal] = useModal();

  const { t } = useTranslation();

  const onOpenEditCategoryModal = (category: CategoryData | null) => {
    if (category === null) {
      return;
    }
    setCategoryToEdit(category);
    handleOpenEditCategoryModal();
  };

  const onCloseEditCategoryModal = () => {
    setCategoryToEdit(null);
    handleCloseEditCategoryModal();
  };

  const [contentToEdit, setContentToEdit] = useState<ContentData | null>(null);
  const [contentToDelete, setContentToDelete] = useState<ContentData | null>(null);

  const { contents, getContents, isFetchingContents, deleteContent, isDeletingContent, setPage, pageSize } =
    useContentsContext();

  const { currentUser } = useAuthContext();

  function onOpenEditContentModal(content: ContentData) {
    setContentToEdit(content);
    handleOpenEditContentModal();
  }

  function onCloseEditContentModal() {
    handleCloseEditContentModal();
    setContentToEdit(null);
  }

  function onOpenDeleteContentModal(content: ContentData) {
    setContentToDelete(content);
    handleOpenDeleteContentModal();
  }

  function onCloseDeleteContentModal() {
    setContentToDelete(null);
    handleCloseDeleteContentModal();
  }

  async function handleDeleteContent(content: ContentData) {
    await deleteContent(content);
    setPage(1);
    await getContents({ page: 1, pageSize });
    onCloseDeleteContentModal();
  }

  const orderButton = useTableSorting<ContentParamsToOrderBy>(onTableSort);

  if (isFetchingContents) return <LoadIndicator />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell className={styles.tableHeaderCell} align="center" width={160}>
                {t('contents_page.components.contents_table.table.action_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('nome')}
                {t('contents_page.components.contents_table.table.content_name_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('nomeCategoria')}
                {t('contents_page.components.contents_table.table.category_name_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('dataRegistro')}
                {t('contents_page.components.contents_table.table.register_date_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left" width={20}>
                {orderButton('ativo')}
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents &&
              contents.map((content) => (
                <StyledTableRow key={content.idConteudo} className={styles.tableRow}>
                  <TableCell width={160} align="center" className={styles.tableBodyCell}>
                    <IconButton
                      color="primary"
                      aria-label={t(
                        'contents_page.components.contents_table.table_body.edit_content_button_aria_label'
                      )}
                      title={t('contents_page.components.contents_table.table_body.edit_content_button_title')}
                      onClick={() => onOpenEditContentModal(content)}
                    >
                      <HiOutlinePencilAlt />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      aria-label={t(
                        'contents_page.components.contents_table.table_body.delete_content_button_aria_label'
                      )}
                      title={t('contents_page.components.contents_table.table_body.delete_content_button_title')}
                      onClick={() => onOpenDeleteContentModal(content)}
                    >
                      <HiOutlineTrash />
                    </IconButton>
                  </TableCell>
                  <TableCell className={styles.tableBodyCell} align="left">
                    {content.nome}
                  </TableCell>
                  <TableCell className={styles.tableBodyCell} align="left">
                    <button
                      className={styles.buttonLink}
                      type="button"
                      onClick={() => onOpenEditCategoryModal(content.categoria || null)}
                    >
                      {content.categoria?.nome}
                    </button>
                  </TableCell>
                  <TableCell className={styles.tableBodyCell} align="left">
                    {new Date(content.dataRegistro || '').toLocaleDateString(currentUser?.idioma)}
                  </TableCell>
                  <TableCell className={styles.tableBodyCell} width={20} align="left">
                    <span className={content.ativo ? styles.activeBox : styles.inactiveBox}>
                      {content.ativo ? 'Active' : 'Inactive'}
                    </span>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {contentToEdit && (
        <EditContentModal
          isOpen={isEditContentModalOpen}
          onClose={() => onCloseEditContentModal()}
          contentToEdit={contentToEdit}
        />
      )}
      {contentToDelete && (
        <DeleteAlertModal
          onDeleteCallback={() => handleDeleteContent(contentToDelete)}
          isDeleting={isDeletingContent}
          isOpen={isDeleteContentModalOpen}
          onClose={() => onCloseDeleteContentModal()}
          title={t('contents_page.components.contents_table.delete_modal.title')}
          message={t('contents_page.components.contents_table.delete_modal.message', {
            contentName: contentToDelete.nome,
          })}
        />
      )}
      {categoryToEdit && (
        <EditCategoryModal
          isOpen={isEditCategoryModalOpen}
          onClose={onCloseEditCategoryModal}
          categoryToEdit={categoryToEdit}
          onCategoryEdit={() => getContents({ page: 1, pageSize })}
        />
      )}
    </>
  );
}
