import { useState } from 'react';
import { FaArrowDown, FaArrowUp, FaArrowsAltV } from 'react-icons/fa';

type UseTableSortingResult<T> = (id: keyof T) => JSX.Element | null;

export type OnSortProps<T> = {
  orderByOnSort: keyof T | '';
  orderOnSort: 'Asc' | 'Desc' | '';
};

export interface TableSortProps<T> {
  onTableSort: ({ orderOnSort, orderByOnSort }: OnSortProps<T>) => void;
}

export function useTableSorting<T>(
  onTableSort: ({ orderOnSort, orderByOnSort }: OnSortProps<T>) => void
): UseTableSortingResult<T> {
  const [orderBy, setOrderBy] = useState<keyof T | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');

  function sort(sortBy: keyof T) {
    if (orderBy === sortBy) {
      const currentOrder = order === 'Asc' ? 'Desc' : 'Asc';
      setOrder(currentOrder);
      if (currentOrder === 'Asc') {
        setOrderBy('');
        setOrder('');
        onTableSort({ orderOnSort: '', orderByOnSort: '' });
        return;
      }
      onTableSort({ orderOnSort: currentOrder, orderByOnSort: orderBy });
    } else {
      setOrderBy(sortBy);
      setOrder('Asc');
      onTableSort({ orderOnSort: 'Asc', orderByOnSort: sortBy });
    }
  }

  function returnOrderButton(id: keyof T) {
    const arrows = {
      Asc: <FaArrowUp onClick={() => sort(id)} />,
      Desc: <FaArrowDown onClick={() => sort(id)} />,
      '': null,
    };
    return orderBy === id ? arrows[order] : <FaArrowsAltV onClick={() => sort(id)} />;
  }

  return returnOrderButton;
}
