import { useEffect, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import { MediaData } from '../../../../../../interfaces/Media';
import { ContentPutBody } from '../../../../../../interfaces/Content';
import { useContentsContext } from '../../../../../../context/ContentsContext';
import { LoadIndicator } from '../../../../../../components/LoadIndicator';

interface OrderModalProps {
  isFetchingContentMedias: boolean;
}

export function OrderModal({ isFetchingContentMedias }: OrderModalProps) {
  const { getValues, setValue, watch } = useFormContext<ContentPutBody>();
  const { medias, isFetchingMedias } = useContentsContext();
  const [mediasOrder, setMediasOrder] = useState<MediaData[]>([]);

  const { t } = useTranslation();

  const watchOrdemMedias = watch('ordemMedias');

  function changeDirection(index: number, direction: 'up' | 'down') {
    const copy = [...mediasOrder];
    const temp = copy[index];
    if (direction === 'up' && index > 0) {
      copy[index] = copy[index - 1];
      copy[index - 1] = temp;
    } else if (direction === 'down' && index < copy.length - 1) {
      copy[index] = copy[index + 1];
      copy[index + 1] = temp;
    }
    setMediasOrder(copy);
    setValue(
      'ordemMedias',
      copy.map((med) => med.idMedia || 0)
    );
  }

  useEffect(() => {
    setMediasOrder(
      getValues('ordemMedias')
        .map((ord) => medias.filter((med) => med.idMedia === ord))
        .flat(1)
    );
  }, [watchOrdemMedias]);

  if (isFetchingContentMedias || isFetchingMedias) {
    return <LoadIndicator />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>{t('contents_page.components.edit_content_modal.order_tab_title')}</span>
      <div className={styles.tableContainer}>
        <Table size="small" className={styles.table}>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell className={styles.tableHeadCell} width={90} />
              <TableCell className={styles.tableHeadCell}>{t('contents_page.components.edit_content_modal.order_tab_table_header_media')}</TableCell>
              <TableCell className={styles.tableHeadCell}>{t('contents_page.components.edit_content_modal.order_tab_table_header_description')}</TableCell>
              <TableCell className={styles.tableHeadCell} width={120}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mediasOrder &&
              mediasOrder.map((order, index) => (
                <TableRow key={index} className={styles.tableRow}>
                  <TableCell className={styles.arrowColumn} width={90}>
                    <IconButton disabled={index === 0} onClick={() => changeDirection(index, 'up')}>
                      <AiOutlineArrowUp />
                    </IconButton>
                    <IconButton
                      disabled={index === mediasOrder.length - 1}
                      onClick={() => changeDirection(index, 'down')}
                    >
                      <AiOutlineArrowDown />
                    </IconButton>
                  </TableCell>
                  <TableCell>{order.nome}</TableCell>
                  <TableCell>{order.descricao}</TableCell>
                  <TableCell width={120}>
                    <span className={order.ativo ? styles.activeBox : styles.inactiveBox}>
                      {order.ativo ? 'Active' : 'Inactive'}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
