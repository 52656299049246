import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme, Theme } from '@mui/material';
import { hexToRGBA } from '../components/Header/components/UserPreferencesModal/utils/colors';

interface ThemeContextProps {
  selectedTheme: 'dark' | 'light';
  initialColors: ColorPalette;
  setSelectedTheme: (theme: 'dark' | 'light') => void;
  updateColors: (color: ColorPalette) => void;
  resetColors: () => void;
}

export interface ColorPalette {
  header: string;
  primary: string;
  secondary: string;
}

const ThemeContext = createContext({} as ThemeContextProps);

interface ThemeContextProviderProps {
  children: ReactNode;
}

function getAppliedTheme() {
  const theme = localStorage.getItem('theme');
  if (theme != null) {
    if (theme === 'dark' || theme === 'light') {
      return theme;
    }
  }
  return 'light';
}

function returnTheme() {
  const theme = createTheme({
    palette: {
      primary: {
        main: window.getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#003789',
      },
    },
    typography: {
      fontFamily: 'Montserrat',
    },
  });
  return theme;
}

export function ThemeContextProvider({ children }: ThemeContextProviderProps) {
  const [selectedTheme, setSelectedTheme] = useState<'dark' | 'light'>(getAppliedTheme());
  const [muiTheme, setMuiTheme] = useState<Theme>(returnTheme());
  const initialColors = {
    header: window.getComputedStyle(document.documentElement).getPropertyValue('--header-color'),
    primary: window.getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
    secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
  };

  function createPrimaryColorTransparent(opacity: number) {
    const primary = window.getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
    const op = opacity;
    const primaryTransparent = hexToRGBA(primary, op);
    return primaryTransparent;
  }

  function updateColors(color: ColorPalette) {
    document.documentElement.style.setProperty('--header-color', color.header);
    document.documentElement.style.setProperty('--primary-color', color.primary);
    document.documentElement.style.setProperty('--primary-color-transparent', createPrimaryColorTransparent(0.6));
    document.documentElement.style.setProperty('--primary-color-secondary-transparent', createPrimaryColorTransparent(0.1));
    document.documentElement.style.setProperty('--secondary-color', color.secondary);

    localStorage.setItem('colors', JSON.stringify(color));
    setMuiTheme(returnTheme());
  }

  function getColorsPalette() {
    const colorsJSON = localStorage.getItem('colors');
    if (colorsJSON != null) {
      const newColors = JSON.parse(colorsJSON);
      updateColors(newColors);
    }
  }

  function resetColors(){
    const colorsReset = {
      header: "#ff6d2a",
      primary: "#e13e00",
      secondary: "#ffdcc8"
    }
    updateColors(colorsReset);
  }

  useEffect(() => {
    document.documentElement.setAttribute('theme', selectedTheme);
    localStorage.setItem('theme', selectedTheme);
    getColorsPalette();
  }, [selectedTheme]);

  return (
    <ThemeContext.Provider
      value={{
        selectedTheme,
        setSelectedTheme,
        initialColors,
        updateColors,
        resetColors
      }}
    >
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const themeContext = useContext(ThemeContext);
  return themeContext;
}
