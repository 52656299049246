import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Metadata } from '../interfaces/ApiResponse';
import {
  CategoryContentResponseType,
  ContentData,
  ContentPutBody,
  ContentPostBody,
  ContentsResponseType,
  FetchContentParamsType,
  ContentMediasResponseType,
  ContentUsersResponseType,
  ContentUserPut,
} from '../interfaces/Content';
import { api } from '../services/api';
import { MediaData } from '../interfaces/Media';
import { UserData } from '../interfaces/User';
import { useToastContext } from '../context/ToastContext';

async function fetchContents({
  page,
  pageSize,
  nome,
  ativo,
  order,
  orderBy,
  catAtiva,
}: FetchContentParamsType): Promise<ContentsResponseType> {
  const { data } = await api.get<ContentsResponseType>(`/conteudo`, {
    params: {
      page,
      pageSize,
      ativo,
      nome,
      order,
      orderBy,
      catAtiva,
    },
  });

  return data;
}

async function fetchCategoryContents({
  page,
  pageSize,
  categoryId,
  nome,
  ativo,
  order,
  orderBy,
  catAtiva,
}: FetchContentParamsType): Promise<CategoryContentResponseType> {
  const { data } = await api.get<CategoryContentResponseType>(`/categoria/${categoryId}/conteudo`, {
    params: {
      page,
      pageSize,
      ativo,
      nome,
      order,
      orderBy,
      catAtiva,
    },
  });

  return data;
}

export function useFetchContents() {
  const [contents, setContents] = useState<ContentData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingContents, setIsFetchingContents] = useState<boolean>(false);

  async function getContents({
    page,
    pageSize,
    categoryId,
    nome,
    itemInList,
    ativo,
    order,
    orderBy,
    catAtiva,
  }: FetchContentParamsType) {
    setIsFetchingContents(true);
    try {
      let data;

      if (categoryId) {
        data = await fetchCategoryContents({ page, pageSize, categoryId, nome, ativo, order, orderBy, catAtiva });

        if (itemInList) {
          data.results[0].conteudo = data.results[0].conteudo.filter(
            (content) => content.idConteudo !== itemInList.idConteudo
          );
          data.results[0].conteudo = [itemInList, ...data.results[0].conteudo];
          if (data.results[0].conteudo.length > pageSize) {
            data.results[0].conteudo.pop();
          }
        }

        if (data.results.length > 0) {
          setContents(data.results[0].conteudo);
        }
      } else {
        data = await fetchContents({ page, pageSize, nome, ativo, order, orderBy, catAtiva });

        if (itemInList) {
          data.results = data.results.filter((content) => content.idConteudo !== itemInList.idConteudo);
          data.results = [itemInList, ...data.results];
          if (data.results.length > pageSize) {
            data.results.pop();
          }
        }

        setContents(data.results);
      }

      const { results, ...rest } = data;
      setMetadata({ ...rest });
    } finally {
      setIsFetchingContents(false);
    }
  }

  return { getContents, metadata, contents, isFetchingContents };
}

export function useFetchContent() {
  const [content, setContent] = useState<ContentData | null>(null);
  const [isFetchingContent, setIsFetchingContent] = useState<boolean>(false);

  async function fetchContent(contentId: number) {
    setIsFetchingContent(true);

    try {
      const { data } = await api.get(`/conteudo/${contentId}`);
      setContent(data);
    } finally {
      setIsFetchingContent(false);
    }
  }

  return { content, fetchContent, isFetchingContent };
}

export function useCreateContent() {
  const [isCreatingContent, setIsCreatingContent] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function createContent(content: ContentPostBody) {
    setIsCreatingContent(true);

    try {
      const response = await createPromiseToast(api.post<ContentData>('/conteudo', content), {
        error: t('hooks.use_content.create_content_error', { contentName: content.nome }),
        pending: t('hooks.use_content.create_content_pending', { contentName: content.nome }),
        success: t('hooks.use_content.create_content_success', { contentName: content.nome }),
      });
      return response.data;
    } finally {
      setIsCreatingContent(false);
    }
  }

  return {
    createContent,
    isCreatingContent,
  };
}

export function useEditContent() {
  const [isEditingContent, setIsEditingContent] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function editContent(content: ContentPutBody) {
    setIsEditingContent(true);

    try {
      const response = await createPromiseToast(api.put<ContentData>('/conteudo', content), {
        error: t('hooks.use_content.edit_content_error', { contentName: content.nome }),
        pending: t('hooks.use_content.edit_content_pending', { contentName: content.nome }),
        success: t('hooks.use_content.edit_content_success', { contentName: content.nome }),
      });
      return response.data;
    } finally {
      setIsEditingContent(false);
    }
  }

  return { isEditingContent, editContent };
}

export function useDeleteContent() {
  const [isDeletingContent, setIsDeletingContent] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function deleteContent(content: ContentData) {
    setIsDeletingContent(true);

    try {
      await createPromiseToast(api.delete(`/conteudo/${content.idConteudo}`), {
        error: t('hooks.use_content.delete_content_error', { contentName: content.nome }),
        pending: t('hooks.use_content.delete_content_pending', { contentName: content.nome }),
        success: t('hooks.use_content.delete_content_success', { contentName: content.nome }),
      });
    } finally {
      setIsDeletingContent(false);
    }
  }

  return { isDeletingContent, deleteContent };
}

export function useFetchUserContents() {
  interface UserContentsData {
    idUsuario: number;
    conteudos: ContentData[];
  }

  const [userContents, setUserContents] = useState<ContentData[]>([]);
  const [isFetchingUserContents, setIsFetchingUserContents] = useState<boolean>(false);

  async function getUserContents() {
    setIsFetchingUserContents(true);

    try {
      const { data } = await api.get<UserContentsData>('/usuario/conteudo');
      setUserContents(data.conteudos);
    } finally {
      setIsFetchingUserContents(false);
    }
  }

  return { getUserContents, userContents, isFetchingUserContents };
}

export function useFetchContentMedias() {
  const [contentMedias, setContentMedias] = useState<MediaData[]>([]);
  const [isFetchingContentMedias, setIsFetchingContentMedias] = useState<boolean>(false);

  async function getContentMedias(contentId: number, active?: boolean) {
    setIsFetchingContentMedias(true);

    try {
      const { data } = await api.get<ContentMediasResponseType>(`/conteudo/${contentId}/medias`, {
        params: { ativo: active },
      });
      setContentMedias(data.results[0].medias);
    } finally {
      setIsFetchingContentMedias(false);
    }
  }

  return { getContentMedias, contentMedias, isFetchingContentMedias };
}

export function useFetchContentUsers() {
  const [contentUsers, setContentUsers] = useState<UserData[]>([]);
  const [isFetchingContentUsers, setIsFetchingContentUsers] = useState<boolean>(false);

  async function getContentUsers(contentId: number) {
    setIsFetchingContentUsers(true);

    try {
      const { data } = await api.get<ContentUsersResponseType>(`/conteudo/${contentId}/usuarios`);
      setContentUsers(data.results[0].usuarios);
    } finally {
      setIsFetchingContentUsers(false);
    }
  }

  return { getContentUsers, contentUsers, isFetchingContentUsers };
}

export function useAddContentUser() {
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function addContentUser(content: ContentUserPut) {
    await createPromiseToast(api.post(`/conteudo/${content.idConteudo}/usuario`), {
      error: t('hooks.use_content.add_content_user_error', { contentName: content.nome }),
      pending: t('hooks.use_content.add_content_user_pending', { contentName: content.nome }),
      success: t('hooks.use_content.add_content_user_success', { contentName: content.nome }),
    });
  }

  return { addContentUser };
}

export function useDeleteContentUser() {
  const { createPromiseToast } = useToastContext();

  const { t } = useTranslation();

  async function deleteContentUser(content: ContentUserPut) {
    await createPromiseToast(api.delete(`/conteudo/${content.idConteudo}/usuario`), {
      error: t('hooks.use_content.delete_content_user_error', { contentName: content.nome }),
      pending: t('hooks.use_content.delete_content_user_pending', { contentName: content.nome }),
      success: t('hooks.use_content.delete_content_user_success', { contentName: content.nome }),
    });
  }

  return { deleteContentUser };
}
