import { IconButton, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Draggable from 'react-draggable';
import styles from './styles.module.scss';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

interface BaseModalProps extends ModalProps {
  title: string;
  children: ReactNode;
  showSaveButton?: boolean;
  showSecondaryButton?: boolean;
  showCloseButton?: boolean;
  onSaveCallback?: () => void;
  onSecondaryButtonCallback?: () => void;
  saveButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  saveButtonText?: string;
  secondaryButtonText?: string;
  fullscreen?: boolean;
  contentStyle?: React.CSSProperties;
}

export function BaseModal({
  isOpen,
  onClose,
  title,
  children,
  showSaveButton = false,
  showSecondaryButton = false,
  onSaveCallback = () => {},
  onSecondaryButtonCallback = () => {},
  saveButtonDisabled = false,
  secondaryButtonDisabled = false,
  showCloseButton = true,
  saveButtonText,
  secondaryButtonText = '',
  fullscreen = false,
  contentStyle,
}: BaseModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={`${styles.modalBackground} ${fullscreen ? styles.fullscreen : ''}`}>
        <Draggable handle="#modalHeaderDraggable" disabled={fullscreen}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeading} id="modalHeaderDraggable">
              <h1>{title}</h1>
              {showCloseButton && (
                <IconButton onClick={onClose} onTouchEnd={onClose}>
                  <AiOutlineClose />
                </IconButton>
              )}
            </div>
            <div className={styles.modalContent} style={contentStyle}>
              {children}
            </div>
            <div className={styles.modalButtonRow}>
              {showSecondaryButton && (
                <div className={styles.modalActions}>
                  <button type="button" onClick={() => onSecondaryButtonCallback()} disabled={secondaryButtonDisabled}>
                    {secondaryButtonText}
                  </button>
                </div>
              )}
              {showSaveButton && (
                <div className={styles.modalActions}>
                  <button type="button" onClick={() => onSaveCallback()} disabled={saveButtonDisabled}>
                    {saveButtonText === undefined ? t('components.base_modal.save_button_text') : saveButtonText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Draggable>
      </div>
    </Modal>
  );
}
