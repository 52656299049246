import { FiVideoOff } from 'react-icons/fi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../BaseModal';
import { MediaData } from '../../interfaces/Media';
import styles from './styles.module.scss';
import config from '../../config/config';
import { MediaEnum } from '../../enums/Media';
import { api } from '../../services/api';
import { UserActivity } from '../../interfaces/User';
import { UserActivityEnum } from '../../enums/User';

interface VideoModalProps extends ModalProps {
  media: MediaData;
  modalTitle: string;
  videoTitle?: string;
  onUpdateVideoMessage?: () => void;
}

export function VideoModal({ isOpen, onClose, media, modalTitle, videoTitle, onUpdateVideoMessage }: VideoModalProps) {
  const { t } = useTranslation();

  interface IFrameVideoMessage {
    videoSrc: string;
    msg: 'VIDEO_ENDED' | 'VIDEO_STARTED';
  }

  async function handleMessageIframe(e: MessageEvent<IFrameVideoMessage>) {
    if (typeof e.data === 'object' && (e.data.msg === 'VIDEO_ENDED' || e.data.msg === 'VIDEO_STARTED')) {
      const userActivity: UserActivity = {
        idMedia: media.idMedia || 0,
        tipo: e.data.msg === 'VIDEO_ENDED' ? UserActivityEnum.MediaCompletada : UserActivityEnum.MediaIniciada,
      };
      await api.post('/usuario/media', userActivity);

      if (onUpdateVideoMessage) {
        onUpdateVideoMessage();
      }
    }
  }

  function returnPlayer() {
    if (media.stream) {
      if (media.tipo === MediaEnum.YouTube) {
        return (
          <iframe
            width={640}
            height={430}
            className={styles.iframe}
            src={`${config.publicUrl}/youtubevideoplayer.html?videoSrc=${media.stream.url}`}
            title={`Player de vídeo da media ${media.nome}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        );
      }
      return (
        <iframe
          width={640}
          height={430}
          className={styles.iframe}
          src={`${config.publicUrl}/azurevideoplayer.html?videoSrc=${media.stream.url}&baseUrl=${config.publicUrl}`}
          title={`Player de vídeo da media ${media.nome}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      );
    }
    return (
      <span className={styles.noVideoContainer}>
        <FiVideoOff size={128} />
        <p>{t('media_page.components.edit_media_form.video_not_available')}</p>
      </span>
    );
  }

  useEffect(() => {
    window.addEventListener('message', handleMessageIframe);

    return () => {
      window.removeEventListener('message', handleMessageIframe);
    };
  }, []);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <div className={styles.videoContainer}>
        {videoTitle && <p className={styles.videoTitle}>{videoTitle}</p>}
        {returnPlayer()}
      </div>
    </BaseModal>
  );
}
