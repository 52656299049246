import styles from './style.module.scss';
import config from '../../config/config';

export function Footer() {
  return (
    <footer className={styles.footer}>
      @ 2023 PCA Engenharia de Software Ltda. - All Rights Reserved - Version {`${config.majorBuild}.${config.minorBuild}.${config.buildNumber}`}
    </footer>
  );
}
