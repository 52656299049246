import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useModal } from '../../../../hooks/useModal';
import { MediaData } from '../../../../interfaces/Media';
import { VideoModal } from '../../../../components/VideoModal';

interface WatchVideoButtonProps {
  media: MediaData;
  title: string;
  onUpdateVideoMessage?: () => void;
}

export function WatchVideoButton({ media, title, onUpdateVideoMessage }: WatchVideoButtonProps) {
  const [isMediaVideoModalOpen, handleOpenMediaVideoModal, handleCloseMediaVideoModal] = useModal();
  const { t } = useTranslation();

  return (
    <>
      <Button variant="contained" startIcon={<HiOutlineVideoCamera />} onClick={handleOpenMediaVideoModal}>
        {t('content_page.components.watch_video_button.watch_button')}
      </Button>
      {isMediaVideoModalOpen && (
        <VideoModal
          isOpen={isMediaVideoModalOpen}
          onClose={handleCloseMediaVideoModal}
          media={media}
          modalTitle={title}
          videoTitle={media.nome}
          onUpdateVideoMessage={onUpdateVideoMessage}
        />
      )}
    </>
  );
}
