import { useEffect } from 'react';
import { MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContentsContext } from '../../../../context/ContentsContext';
import styles from '../../styles.module.scss';
import { LoadIndicator } from '../../../../components/LoadIndicator';

interface SelectContentProps {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  category: string;
}

export function SelectContent({ content, setContent, category }: SelectContentProps) {
  const { contents, getContents, isFetchingContents, setPage, pageSize, page, metadata } = useContentsContext();

  const { t } = useTranslation();

  function handlePageChange(value: number) {
    setPage(value);
    getContents({ page: value, pageSize });
  }

  function returnSelectContent() {
    if (isFetchingContents && category) {
      return (
        <div className={styles.selectMenuLoadIndicator}>
          <LoadIndicator />
        </div>
      );
    }
    return (
      category &&
      contents.map((cont) => (
        <MenuItem key={cont.idConteudo} value={cont.idConteudo}>
          {cont.nome}
        </MenuItem>
      ))
    );
  }

  function onChangeConteudo(event: SelectChangeEvent<string>) {
    if (event.target) {
      setContent(event.target.value);
    }
  }

  useEffect(() => {
    setContent('');
    setPage(1);
    if (category && !isFetchingContents) {
      getContents({ page: 1, pageSize, categoryId: Number(category) });
    }
  }, [category]);

  return (
    <Select
      label={t('report_page.index.select_content_input_label')}
      value={category ? content : ''}
      onChange={(e) => onChangeConteudo(e)}
    >
      <MenuItem value="" className={styles.noContentMenuItem}>
        {category
          ? t('report_page.components.select_content.menu_item_no_content')
          : t('report_page.components.select_content.menu_item_select_category')}
      </MenuItem>
      {returnSelectContent()}
      {category && (
        <div className={styles.paginationContainer}>
          <Pagination
            page={page}
            onChange={(e, value) => handlePageChange(value)}
            count={metadata.pages}
            color="primary"
            variant="outlined"
            disabled={isFetchingContents}
          />
        </div>
      )}
    </Select>
  );
}
