import { Button, Checkbox, CircularProgress, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { MdAccountCircle, MdLock } from 'react-icons/md';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/Footer';
import { useAuthContext } from '../../context/AuthContext';
import pcaLogo from '../../assets/images/pca_logo.png';
import styles from './styles.module.scss';
import { useModal } from '../../hooks/useModal';
import { PasswordRecoveryModal } from './components/PasswordRecoveryModal';
import { useToastContext } from '../../context/ToastContext';
import { RequestAccessModal } from './components/RequestAccessModal';
import { fetchUserCompanies } from '../../hooks/useUsers';
import { SelectCompanyModal } from './components/SelectCompanyModal';
import { CompanyData } from '../../interfaces/Company';

export function Login() {
  const [email, setEmail] = useState<string>(localStorage.getItem('rememberMeEmail') || '');
  const [senha, setSenha] = useState<string>('');

  const [isFPasswordModalOpen, handleOpenFPasswordModal, handleCloseFPasswordModal] = useModal();
  const [isReqAccessModalOpen, handleOpenReqAccessModal, handleCloseReqAccessModal] = useModal();
  const [isSelectCompanyModalOpen, handleOpenSelectCompanyModal, handleCloseSelectCompanyModal] = useModal();

  const [rememberMe, setRememberMe] = useState<boolean>(!!localStorage.getItem('rememberMeEmail'));

  const { doLogin, isLoggingIn, doLoginCompany } = useAuthContext();
  const { createToast } = useToastContext();
  const { t } = useTranslation();

  const [userCompanies, setUserCompanies] = useState<CompanyData[]>([]);

  async function onAuthenticated() {
    const userCompaniesResult = await fetchUserCompanies({ page: 1, pageSize: 99999999 });

    if (userCompaniesResult.results.length > 1) {
      setUserCompanies(userCompaniesResult.results);
      handleOpenSelectCompanyModal();
    } else if (userCompaniesResult.results.length === 1) {
      doLoginCompany({ idEmpresa: userCompaniesResult.results[0].idEmpresa || 0 });
    } else {
      createToast('Houve um erro ao autenticar-se. Caso o erro persista, entre em contato com o suporte.', 'error');
    }
  }

  function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (email === '' || senha === '') {
      createToast(t('login_page.index.fill_both_fields_error'), 'error');
      return;
    }

    if (rememberMe) {
      localStorage.setItem('rememberMeEmail', email);
    } else {
      localStorage.removeItem('rememberMeEmail');
    }

    doLogin({ usuario: email, senha }, onAuthenticated);
  }

  function handleChangeRememberMe(event: React.ChangeEvent<HTMLInputElement>) {
    setRememberMe(event.target.checked);
  }

  return (
    <>
      <main className={styles.backgroundContainer}>
        <div className={styles.authCard}>
          <form onSubmit={handleLogin}>
            <img src={pcaLogo} alt="PCA Logo" className={styles.imageLogo} />
            <TextField
              placeholder="E-mail"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdAccountCircle size={21} className={styles.textFieldIcon} />
                  </InputAdornment>
                ),
                className: styles.textField,
              }}
              variant="outlined"
              margin="dense"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              placeholder={t('login_page.index.password_input_pch')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdLock size={21} className={styles.textFieldIcon} />
                  </InputAdornment>
                ),
                className: styles.textField,
              }}
              variant="outlined"
              margin="dense"
              type="password"
              fullWidth
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => handleChangeRememberMe(e)}
                  sx={{ color: 'var(--primary-content)' }}
                />
              }
              label={t('login_page.index.remember_me_checkbox')}
              className={styles.rememberMe}
            />
            <Button className={styles.loginButton} variant="contained" fullWidth disabled={isLoggingIn} type="submit">
              {t('login_page.index.signin_button')}
              {isLoggingIn && <CircularProgress className={styles.loadIndicator} size="1.2rem" />}
            </Button>
            <Button className={styles.actionButton} onClick={handleOpenFPasswordModal}>
              {t('login_page.index.forgot_password_button')}
            </Button>
            <Button className={styles.actionButton} onClick={handleOpenReqAccessModal}>
              {t('login_page.index.request_access_button')}
            </Button>
          </form>
        </div>
        <PasswordRecoveryModal isOpen={isFPasswordModalOpen} onClose={handleCloseFPasswordModal} />
        <RequestAccessModal isOpen={isReqAccessModalOpen} onClose={handleCloseReqAccessModal} />
        <SelectCompanyModal
          isOpen={isSelectCompanyModalOpen}
          onClose={handleCloseSelectCompanyModal}
          companies={userCompanies}
        />
      </main>
      <Footer />
    </>
  );
}
