import {
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { DeleteAlertModal } from '../../../../components/DeleteAlertModal';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useCategoriesContext } from '../../../../context/CategoriesContext';
import { useModal } from '../../../../hooks/useModal';
import { CategoryData, CategoryParamsToOrderBy } from '../../../../interfaces/Category';
import { EditCategoryModal } from '../EditCategoryModal';
import styles from './styles.module.scss';
import { useAuthContext } from '../../../../context/AuthContext';
import { TableSortProps, useTableSorting } from '../../../../hooks/useTableSorting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function getStatusCellStyles(isActive: boolean) {
  return isActive ? styles.activeBox : styles.inactiveBox;
}

interface CategoriesTableProps extends TableSortProps<CategoryParamsToOrderBy> {}

export function CategoriesTable({ onTableSort }: CategoriesTableProps) {
  const [categoryToEdit, setCategoryToEdit] = useState<CategoryData | null>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<CategoryData | null>(null);

  const { categories, getCategories, isFetchingCategories, page, pageSize, deleteCategory, isDeletingCategory } =
    useCategoriesContext();
  const { currentUser } = useAuthContext();

  const { t } = useTranslation();

  const [isEditCategoryModalOpen, handleOpenEditCategoryModal, handleCloseEditCategoryModal] = useModal();
  const [isDeleteCategoryModalOpen, handleOpenDeleteCategoryModal, handleCloseDeleteCategoryModal] = useModal();

  const onOpenEditCategoryModal = (category: CategoryData) => {
    setCategoryToEdit(category);
    handleOpenEditCategoryModal();
  };

  const onCloseEditCategoryModal = () => {
    setCategoryToEdit(null);
    handleCloseEditCategoryModal();
  };

  function onOpenDeleteCategoryModal(category: CategoryData) {
    setCategoryToDelete(category);
    handleOpenDeleteCategoryModal();
  }

  const onCloseDeleteCategoryModal = () => {
    setCategoryToDelete(null);
    handleCloseDeleteCategoryModal();
  };

  async function handleDeleteCategory(category: CategoryData) {
    await deleteCategory(category);
    getCategories({ page, pageSize });
    onCloseDeleteCategoryModal();
  }

  const orderButton = useTableSorting<CategoryParamsToOrderBy>(onTableSort);

  if (isFetchingCategories) return <LoadIndicator />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell width={160} className={styles.tableHeaderCell} align="center">
                {t('categories_page.components.categories_table.table.action_header')}
              </StyledTableCell>
              <StyledTableCell align="left" className={styles.tableHeaderCell}>
                {orderButton('nome')}
                {t('categories_page.components.categories_table.table.category_name_header')}
              </StyledTableCell>
              <StyledTableCell align="left" className={styles.tableHeaderCell}>
                {orderButton('dataRegistro')}
                {t('categories_page.components.categories_table.table.register_date_header')}
              </StyledTableCell>
              <StyledTableCell width={20} className={styles.tableHeaderCell} align="left">
                {orderButton('ativo')}
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <StyledTableRow key={category.idCategoria} className={styles.tableRow}>
                <TableCell width={160} align="center" className={styles.tableBodyCell}>
                  <IconButton
                    color="primary"
                    aria-label={t(
                      'categories_page.components.categories_table.table_body.edit_category_button_aria_label'
                    )}
                    title={t('categories_page.components.categories_table.table_body.edit_category_button_title')}
                    onClick={() => onOpenEditCategoryModal(category)}
                  >
                    <HiOutlinePencilAlt />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label={t(
                      'categories_page.components.categories_table.table_body.delete_category_button_aria_label'
                    )}
                    title={t('categories_page.components.categories_table.table_body.delete_category_button_title')}
                    onClick={() => onOpenDeleteCategoryModal(category)}
                  >
                    <HiOutlineTrash />
                  </IconButton>
                </TableCell>
                <TableCell align="left" className={styles.tableBodyCell}>
                  {category.nome}
                </TableCell>
                <TableCell align="left" className={styles.tableBodyCell}>
                  {new Date(category.dataRegistro || '').toLocaleDateString(currentUser?.idioma)}
                </TableCell>
                <TableCell width={20} align="left" className={styles.tableBodyCell}>
                  <div className={getStatusCellStyles(category.ativo || false)}>
                    {category.ativo === true ? 'Active' : 'Inactive'}
                  </div>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {categoryToEdit && (
        <EditCategoryModal
          isOpen={isEditCategoryModalOpen}
          onClose={onCloseEditCategoryModal}
          categoryToEdit={categoryToEdit}
        />
      )}
      {categoryToDelete && (
        <DeleteAlertModal
          onDeleteCallback={() => handleDeleteCategory(categoryToDelete)}
          isDeleting={isDeletingCategory}
          isOpen={isDeleteCategoryModalOpen}
          onClose={onCloseDeleteCategoryModal}
          title={t('categories_page.components.categories_table.delete_modal.title')}
          message={t('categories_page.components.categories_table.delete_modal.message', {
            categoryName: categoryToDelete.nome,
          })}
        />
      )}
    </>
  );
}
