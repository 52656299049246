import { createContext, ReactNode, useContext, useState } from 'react';
import { useCreateCategory, useDeleteCategory, useEditCategory, useGetCategories } from '../hooks/useCategories';
import { Metadata } from '../interfaces/ApiResponse';
import {
  FetchCategoriesParams,
  CategoryData,
  CategoryPostBody,
  CategoryPutBody,
  CategoryParamsToOrderBy,
} from '../interfaces/Category';

type CategoriesContextType = {
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  orderBy: keyof CategoryParamsToOrderBy | '';
  setOrderBy: (value: keyof CategoryParamsToOrderBy | '') => void;
  order: 'Asc' | 'Desc' | '';
  setOrder: (value: 'Asc' | 'Desc' | '') => void;
  categories: CategoryData[];
  getCategories: ({ page, pageSize, nome, itemInList, order, orderBy, ativo }: FetchCategoriesParams) => Promise<void>;
  isFetchingCategories: boolean;
  metadata: Metadata;
  createCategory: (category: CategoryPostBody) => Promise<CategoryData>;
  isCreatingCategory: boolean;
  editCategory: (category: CategoryPutBody) => Promise<CategoryData>;
  isEditingCategory: boolean;
  deleteCategory: (category: CategoryData) => Promise<void>;
  isDeletingCategory: boolean;
};

const CategoriesContext = createContext({} as CategoriesContextType);

interface CategoriesContextProviderProps {
  children: ReactNode;
}

export function CategoriesContextProvider({ children }: CategoriesContextProviderProps) {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof CategoryParamsToOrderBy | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');
  const { categories, getCategories, isFetchingCategories, metadata } = useGetCategories();
  const { createCategory, isCreatingCategory } = useCreateCategory();
  const { editCategory, isEditingCategory } = useEditCategory();
  const { deleteCategory, isDeletingCategory } = useDeleteCategory();

  return (
    <CategoriesContext.Provider
      value={{
        page,
        setPage,
        pageSize,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        categories,
        getCategories,
        isFetchingCategories,
        metadata,
        createCategory,
        isCreatingCategory,
        editCategory,
        isEditingCategory,
        deleteCategory,
        isDeletingCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
}

export function useCategoriesContext() {
  const categoriesContext = useContext(CategoriesContext);
  return categoriesContext;
}
