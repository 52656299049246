import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import { AuthRequestAccessType, AuthRequestCompanyType, AuthRequestHeaderType } from '../interfaces/Auth';
import { UserData } from '../interfaces/User';
import { useToastContext } from '../context/ToastContext';
import { LanguageConverterLanguages } from '../enums/LanguageConverterI18n';

export async function Login({ usuario, senha }: AuthRequestHeaderType) {
  const response = await api.post('/authentication', undefined, {
    auth: {
      username: usuario,
      password: senha,
    },
  });
  return response;
}

export async function LoginCompany({ idEmpresa }: AuthRequestCompanyType) {
  const response = await api.post('/authentication/empresa', undefined, { headers: { 'X-Company-Id': idEmpresa } });
  return response;
}

export function useAuth() {
  const [isFetchingCurrentUser, setIsFetchingCurrentUser] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserData | undefined>(undefined);

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { createToast } = useToastContext();
  const { t } = useTranslation();

  async function doLogin({ usuario, senha }: AuthRequestHeaderType, onAuthenticated: () => void) {
    setIsLoggingIn(true);

    try {
      const loginResponse = await Login({ usuario, senha });

      if (loginResponse?.status === 200) {
        onAuthenticated();
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        createToast(t('hooks.use_auth.invalid_email_or_password_error'), 'error');
      } else {
        createToast(t('hooks.use_auth.login_error'), 'error');
      }
    }

    setIsLoggingIn(false);
  }

  async function doLoginCompany({ idEmpresa }: AuthRequestCompanyType) {
    setIsLoggingIn(true);

    try {
      const loginResponse = await LoginCompany({ idEmpresa });

      if (loginResponse?.status === 200) {
        setAuthenticated(true);
        navigate('/');
      }
    } catch (error: any) {
      createToast(t('hooks.use_auth.login_error'), 'error');
    }

    setIsLoggingIn(false);
  }

  async function doLogout() {
    setAuthenticated(false);
    setCurrentUser(undefined);
    localStorage.removeItem('authToken');
    api.defaults.headers.Authorization = '';
    navigate('/login');
  }

  function checkLogin() {
    const token = localStorage.getItem('authToken');

    if (token != null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
      if (location.pathname === '/login') {
        navigate('/');
      }
    } else {
      doLogout();
    }
  }

  async function getCurrentUser() {
    setIsFetchingCurrentUser(true);

    try {
      const currentUserResponse = await api.get<UserData>('/usuario/autenticado');
      setCurrentUser(currentUserResponse.data);
    } finally {
      setIsFetchingCurrentUser(false);
    }
  }

  useEffect(() => {
    checkLogin();
    const language = localStorage.getItem('language');

    if (language) {
      i18next.changeLanguage(LanguageConverterLanguages[language]);
    }
  }, []);

  return {
    doLogin,
    doLoginCompany,
    doLogout,
    isLoggingIn,
    authenticated,
    getCurrentUser,
    currentUser,
    isFetchingCurrentUser,
  };
}

export function useRequestAccess() {
  const [isRequestingAccess, setIsRequestingAccess] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function doRequestAccess(request: AuthRequestAccessType) {
    setIsRequestingAccess(true);

    request.cnpj = request.cnpj.replace(/[^0-9]/g, '');

    try {
      await createPromiseToast(
        api.post('/usuario/solicitacao', request),
        {
          error: t('hooks.use_auth.request_send_error'),
          pending: t('hooks.use_auth.request_send_pending'),
          success: t('hooks.use_auth.request_send_success'),
        },
        true
      );
    } catch (error: any) {
      setIsRequestingAccess(false);
    } finally {
      setIsRequestingAccess(false);
    }
  }

  return { isRequestingAccess, doRequestAccess };
}
