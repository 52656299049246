import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { MediaFormSchema } from '../../utils/MediaFormValidation';
import { MediaData, MediaPutBody, MediaActivityUserFetch } from '../../../../interfaces/Media';
import { useMediasContext } from '../../../../context/MediasContext';
import { EditMediaForm } from '../EditMediaForm';
import { ConfirmAlertModal } from '../../../../components/ConfirmAlertModal';
import { useModal } from '../../../../hooks/useModal';
import { api } from '../../../../services/api';

interface AddNewMediaModalProps extends ModalProps {
  mediaToEdit: MediaData;
}

export function EditMediaModal({ isOpen, onClose, mediaToEdit }: AddNewMediaModalProps) {
  const { setPage, getMedias, editMedia, isEditingMedia, pageSize } = useMediasContext();
  const [isWatchingPreviewVideo, setIsWatchingPreviewVideo] = useState<boolean>(false);
  const [isListeningAudio, setIsListeningAudio] = useState<boolean>(false);

  const [isConfirmModalOpen, handleOpenConfirmModal, handleCloseConfirmModal] = useModal();

  const { t } = useTranslation();

  const editMediaForm = useForm<MediaPutBody>({
    resolver: yupResolver(MediaFormSchema),
    defaultValues: {
      idMedia: mediaToEdit.idMedia,
      nome: mediaToEdit.nome,
      descricao: mediaToEdit.descricao,
      ativo: mediaToEdit.ativo,
      tipo: mediaToEdit.tipo,
    },
  });

  const { handleSubmit } = editMediaForm;

  async function handleEditMedia(data: MediaPutBody) {
    onClose();
    const editedMedia = await editMedia(data);
    setPage(1);
    await getMedias({ page: 1, pageSize, itemInList: editedMedia });
  }

  async function verifyEditMedia() {
    if (editMediaForm.getValues('youtubeLink') || editMediaForm.getValues('media')) {
      const resp = await api.get<MediaActivityUserFetch>(`/media/${mediaToEdit.idMedia}/atividadeusuario`);
      if (resp.data.atividadesUsuarios.length > 0) {
        handleOpenConfirmModal();
      } else {
        handleSubmit(handleEditMedia)();
      }
    } else {
      handleSubmit(handleEditMedia)();
    }
  }

  async function editMediaWithUserActivity() {
    await api.delete(`/media/${mediaToEdit.idMedia}/atividadeUsuario`);
    handleSubmit(handleEditMedia)();
  }

  return (
    <>
      <BaseModal
        onSaveCallback={() => verifyEditMedia()}
        showSaveButton={!isEditingMedia && !isWatchingPreviewVideo && !isListeningAudio}
        title={mediaToEdit.nome || ''}
        isOpen={isOpen}
        onClose={onClose}
      >
        <FormProvider {...editMediaForm}>
          <EditMediaForm
            mediaToEdit={mediaToEdit}
            isWatchingPreviewVideo={isWatchingPreviewVideo}
            setIsWatchingPreviewVideo={setIsWatchingPreviewVideo}
            isListeningAudio={isListeningAudio}
            setIsListeningAudio={setIsListeningAudio}
          />
        </FormProvider>
      </BaseModal>
      <ConfirmAlertModal
        isOpen={isConfirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirmCallback={() => editMediaWithUserActivity()}
        title={t('media_page.components.edit_media_modal.confirm_alert_title')}
        message={t('media_page.components.edit_media_modal.confirm_alert_message')}
      />
    </>
  );
}
