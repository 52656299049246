import {
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import { useModal } from '../../../../hooks/useModal';
import { UserData, UserParamsToOrderBy } from '../../../../interfaces/User';
import { EditUserModal } from '../EditUserModal';
import styles from './styles.module.scss';
import { useUsersContext } from '../../../../context/UsersContext';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { DeleteAlertModal } from '../../../../components/DeleteAlertModal';
import { useAuthContext } from '../../../../context/AuthContext';
import { TableSortProps, useTableSorting } from '../../../../hooks/useTableSorting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function getStatusCellStyles(ativo: boolean) {
  return ativo ? styles.activeBox : styles.inactiveBox;
}

interface UsersTableProps extends TableSortProps<UserParamsToOrderBy> {}

export function UsersTable({ onTableSort }: UsersTableProps) {
  const [userToEdit, setUserToEdit] = useState<UserData | null>(null);
  const [userToDelete, setUserToDelete] = useState<UserData | null>(null);
  const [isEditModalOpen, handleOpenEditModal, handleCloseEditModal] = useModal();
  const [isDeleteModalOpen, handleOpenDeleteModal, handleCloseDeleteModal] = useModal();
  const { getUsers, users, isFetchingUsers, deleteUser, isDeletingUser, setPage, pageSize } = useUsersContext();

  const { currentUser } = useAuthContext();

  const { t } = useTranslation();

  function onOpenEditUserModal(user: UserData) {
    setUserToEdit(user);
    handleOpenEditModal();
  }

  function onCloseEditUserModal() {
    setUserToEdit(null);
    handleCloseEditModal();
  }

  function onOpenDeleteUserModal(user: UserData) {
    setUserToDelete(user);
    handleOpenDeleteModal();
  }

  function onCloseDeleteUserModal() {
    setUserToDelete(null);
    handleCloseDeleteModal();
  }

  async function handleDeleteUser(user: UserData) {
    await deleteUser(user);
    setPage(1);
    await getUsers({ page: 1, pageSize });
    onCloseDeleteUserModal();
  }

  const orderButton = useTableSorting<UserParamsToOrderBy>(onTableSort);

  if (isFetchingUsers) return <LoadIndicator />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell className={styles.tableHeaderCell} align="center" width={160}>
                {t('users_page.components.users_table.table.action_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('primeiroNome')}
                {t('users_page.components.users_table.table.first_name_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('ultimoNome')}
                {t('users_page.components.users_table.table.last_name_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('email')}
                {t('users_page.components.users_table.table.email_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {orderButton('dataRegistro')}
                {t('users_page.components.users_table.table.register_date_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left">
                {t('users_page.components.users_table.table.initial_password_header')}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeaderCell} align="left" width={20}>
                {orderButton('ativo')}
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.idUsuario} className={styles.tableRow}>
                <TableCell className={styles.tableBodyCell} align="center" width={160}>
                  <IconButton
                    color="primary"
                    aria-label={t('users_page.components.users_table.table_body.edit_user_button_aria_label')}
                    title={t('users_page.components.users_table.table_body.edit_user_button_title')}
                    onClick={() => onOpenEditUserModal(user)}
                  >
                    <HiOutlinePencilAlt />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label={t('users_page.components.users_table.table_body.delete_user_button_aria_label')}
                    title={t('users_page.components.users_table.table_body.delete_user_button_title')}
                    onClick={() => onOpenDeleteUserModal(user)}
                  >
                    <HiOutlineTrash />
                  </IconButton>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>{user.primeiroNome}</TableCell>
                <TableCell className={styles.tableBodyCell}>{user.ultimoNome}</TableCell>
                <TableCell className={styles.tableBodyCell}>{user.email}</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {new Date(user.dataRegistro || '').toLocaleDateString(currentUser?.idioma)}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>{user.senhaInicial}</TableCell>
                <TableCell className={styles.tableBodyCell} width={20}>
                  <div className={getStatusCellStyles(user.ativo || false)}>{user.ativo ? 'Active' : 'Inactive'}</div>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {userToEdit && (
        <EditUserModal userToEdit={userToEdit} isOpen={isEditModalOpen} onClose={() => onCloseEditUserModal()} />
      )}
      {userToDelete && (
        <DeleteAlertModal
          onDeleteCallback={() => handleDeleteUser(userToDelete)}
          isDeleting={isDeletingUser}
          isOpen={isDeleteModalOpen}
          onClose={() => onCloseDeleteUserModal()}
          title={t('users_page.components.users_table.delete_modal.title')}
          message={t('users_page.components.users_table.delete_modal.message', {
            firstName: userToDelete.primeiroNome,
            lastName: userToDelete.ultimoNome,
          })}
        />
      )}
    </>
  );
}
