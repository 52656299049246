import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ModalProps, BaseModal } from '../../../../components/BaseModal';
import { useContentsContext } from '../../../../context/ContentsContext';
import { NewContentForm } from './components/AddNewContentForm';
import { ContentPostBody } from '../../../../interfaces/Content';
import { contentFormPost } from '../../utils/ContentsFormValidation';

interface AddNewContentModalProps extends ModalProps {}

export function AddNewContentModal({ isOpen, onClose }: AddNewContentModalProps) {
  const { isCreatingContent, createContent, getContents, setPage, pageSize } = useContentsContext();
  const { t } = useTranslation();

  const addNewContentForm = useForm<ContentPostBody>({
    resolver: yupResolver(contentFormPost),
    defaultValues: {
      ativo: true,
    },
  });

  const { reset, handleSubmit } = addNewContentForm;

  const onCloseModal = () => {
    reset();
    onClose();
  };

  async function handleCreateNewContent(content: ContentPostBody) {
    const createdContent = await createContent(content);
    setPage(1);
    onCloseModal();
    await getContents({ page: 1, pageSize, itemInList: createdContent });
  }

  return (
    <BaseModal
      onSaveCallback={handleSubmit(handleCreateNewContent)}
      title={t("contents_page.components.add_new_content_modal.modal_title")}
      isOpen={isOpen}
      onClose={onCloseModal}
      showSaveButton={!isCreatingContent}
    >
      <FormProvider {...addNewContentForm}>
        <NewContentForm />
      </FormProvider>
    </BaseModal>
  );
}
