const { REACT_APP_API_URL_BASE, PUBLIC_URL, REACT_APP_MAJOR_BUILD_VERSION, REACT_APP_MINOR_BUILD_VERSION , REACT_APP_BUILD_NUMBER} = process.env;

const config = {
  apiUrlBase: REACT_APP_API_URL_BASE,
  publicUrl: PUBLIC_URL,
  majorBuild: REACT_APP_MAJOR_BUILD_VERSION,
  minorBuild: REACT_APP_MINOR_BUILD_VERSION,
  buildNumber: REACT_APP_BUILD_NUMBER
};

export default config;
