import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Metadata } from '../interfaces/ApiResponse';
import {
  FetchMediaParamsType,
  MediaData,
  MediaFileData,
  MediaPostBody,
  MediaPutBody,
  MediaResponseType,
} from '../interfaces/Media';
import { api } from '../services/api';
import { useToastContext } from '../context/ToastContext';

async function fetchMedias({ page, pageSize, nome, ativo, order, orderBy }: FetchMediaParamsType) {
  const { data } = await api.get<MediaResponseType>('/media', {
    params: {
      page,
      pageSize,
      ativo,
      nome: nome || undefined,
      order,
      orderBy,
    },
  });

  return data;
}

export function useFetchMedias() {
  const [medias, setMedias] = useState<MediaData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingMedias, setIsFetchingMedias] = useState<boolean>(false);

  async function getMedias({ page, pageSize, nome, ativo, itemInList, order, orderBy }: FetchMediaParamsType) {
    setIsFetchingMedias(true);

    try {
      const data = await fetchMedias({ page, pageSize, nome, ativo, order, orderBy });
      if (itemInList) {
        data.results = data.results.filter((media) => media.idMedia !== itemInList.idMedia);
        data.results = [itemInList, ...data.results];
        if (data.results.length > pageSize) {
          data.results.pop();
        }
      }
      setMedias(data.results);
      const { results, ...rest } = data;
      setMetadata({ ...rest });
    } finally {
      setIsFetchingMedias(false);
    }
  }

  return { medias, getMedias, metadata, isFetchingMedias };
}

export function useCreateMedia() {
  const [isCreatingMedia, setIsCreatingMedia] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function createMedia(newMedia: MediaPostBody) {
    setIsCreatingMedia(true);

    try {
      const { media, youtubeLink, ...rest } = newMedia;

      const { data } = await createPromiseToast(api.post<MediaData>('/media', { ...rest }), {
        error: t('hooks.use_media.create_media_error', { mediaName: newMedia.nome }),
        pending: t('hooks.use_media.create_media_pending', { mediaName: newMedia.nome }),
        success: t('hooks.use_media.create_media_success', { mediaName: newMedia.nome }),
      });

      if (media) {
        const formDataBody = new FormData();
        formDataBody.append('media', media);

        const newData = await createPromiseToast(
          api.post<MediaData>(`media/${data.idMedia}/upload`, formDataBody, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }),
          {
            error: t('hooks.use_media.linking_file_error', { mediaName: newMedia.nome }),
            pending: t('hooks.use_media.linking_file_pending', { mediaName: newMedia.nome }),
            success: t('hooks.use_media.linking_file_success', { mediaName: newMedia.nome }),
          }
        );
        return newData.data;
      }

      const youtubeResponse = await createPromiseToast(
        axios.get(`https://www.youtube.com/oembed?url=${youtubeLink}&format=json`),
        {
          error: t('hooks.use_media.create_youtube_url_error', { mediaName: newMedia.nome }),
          pending: t('hooks.use_media.create_youtube_url_pending', { mediaName: newMedia.nome }),
          success: t('hooks.use_media.create_youtube_url_success', { mediaName: newMedia.nome }),
        }
      );

      const div = document.createElement('div');
      div.innerHTML = youtubeResponse.data.html;

      const iframe = <HTMLIFrameElement>div.firstChild;
      const embeddedYoutubeLink = iframe.src;

      const newData = await createPromiseToast(
        api.post<MediaData>(`media/${data.idMedia}/youtube`, { youtubeLink: embeddedYoutubeLink }),
        {
          error: t('hooks.use_media.linking_youtube_url_error', { mediaName: newMedia.nome }),
          pending: t('hooks.use_media.linking_youtube_url_pending', { mediaName: newMedia.nome }),
          success: t('hooks.use_media.linking_youtube_url_success', { mediaName: newMedia.nome }),
        }
      );
      return newData.data;
    } finally {
      setIsCreatingMedia(false);
    }
  }

  return { createMedia, isCreatingMedia };
}

export function useEditMedia() {
  const [isEditingMedia, setIsEditingMedia] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function editMedia(mediaToEdit: MediaPutBody) {
    setIsEditingMedia(true);

    try {
      const { media, youtubeLink, ...rest } = mediaToEdit;

      const { data } = await createPromiseToast(api.put<MediaData>('/media', { ...rest }), {
        error: t('hooks.use_media.edit_media_error', { mediaName: mediaToEdit.nome }),
        pending: t('hooks.use_media.edit_media_pending', { mediaName: mediaToEdit.nome }),
        success: t('hooks.use_media.edit_media_success', { mediaName: mediaToEdit.nome }),
      });

      if (media) {
        const formDataBody = new FormData();
        formDataBody.append('media', media);

        const newData = await createPromiseToast(
          api.post<MediaData>(`media/${data.idMedia}/upload`, formDataBody, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }),
          {
            error: t('hooks.use_media.linking_file_error', { mediaName: mediaToEdit.nome }),
            pending: t('hooks.use_media.linking_file_pending', { mediaName: mediaToEdit.nome }),
            success: t('hooks.use_media.linking_file_success', { mediaName: mediaToEdit.nome }),
          }
        );
        return newData.data;
      }
      if (mediaToEdit.youtubeLink) {
        const youtubeResponse = await createPromiseToast(
          axios.get(`https://www.youtube.com/oembed?url=${youtubeLink}&format=json`),
          {
            error: t('hooks.use_media.create_youtube_url_error', { mediaName: mediaToEdit.nome }),
            pending: t('hooks.use_media.create_youtube_url_pending', { mediaName: mediaToEdit.nome }),
            success: t('hooks.use_media.create_youtube_url_success', { mediaName: mediaToEdit.nome }),
          }
        );

        const div = document.createElement('div');
        div.innerHTML = youtubeResponse.data.html;

        const iframe = <HTMLIFrameElement>div.firstChild;
        const embeddedYoutubeLink = iframe.src;

        const newData = await createPromiseToast(
          api.post<MediaData>(`media/${data.idMedia}/youtube`, { youtubeLink: embeddedYoutubeLink }),
          {
            error: t('hooks.use_media.linking_youtube_url_error', { mediaName: mediaToEdit.nome }),
            pending: t('hooks.use_media.linking_youtube_url_pending', { mediaName: mediaToEdit.nome }),
            success: t('hooks.use_media.linking_youtube_url_success', { mediaName: mediaToEdit.nome }),
          }
        );
        return newData.data;
      }
      return data;
    } finally {
      setIsEditingMedia(false);
    }
  }

  return { editMedia, isEditingMedia };
}

export function useDeleteMedia() {
  const [isDeletingMedia, setIsDeletingMedia] = useState<boolean>(false);
  const { createPromiseToast } = useToastContext();
  const { t } = useTranslation();

  async function deleteMedia(media: MediaData) {
    setIsDeletingMedia(true);

    try {
      await createPromiseToast(api.delete(`/media/${media.idMedia}`), {
        error: t('hooks.use_media.delete_media_error', { mediaName: media.nome }),
        pending: t('hooks.use_media.delete_media_pending', { mediaName: media.nome }),
        success: t('hooks.use_media.delete_media_success', { mediaName: media.nome }),
      });
    } finally {
      setIsDeletingMedia(false);
    }
  }

  return { deleteMedia, isDeletingMedia };
}

export function useFetchMediaFile() {
  const [mediaFile, setMediaFile] = useState<MediaFileData>({} as MediaFileData);
  const [isFetchingMediaFile, setIsFetchingMediaFile] = useState<boolean>(false);

  async function fetchMediaFile(mediaId: number) {
    setIsFetchingMediaFile(true);

    try {
      const response = await api.get<MediaFileData>(`/media/${mediaId}/arquivo`);
      setMediaFile(response.data);
    } finally {
      setIsFetchingMediaFile(false);
    }
  }

  return { mediaFile, isFetchingMediaFile, fetchMediaFile };
}

export function useFetchMediaFileInfo() {
  const [mediaFileInfo, setMediaFileInfo] = useState<MediaFileData>({} as MediaFileData);
  const [isFetchingMediaFileInfo, setIsFetchingMediaFileInfo] = useState<boolean>(false);

  async function fetchMediaFileInfo(mediaId: number) {
    setIsFetchingMediaFileInfo(true);

    try {
      const response = await api.get<MediaFileData>(`/media/${mediaId}/arquivoInfo`);
      setMediaFileInfo(response.data);
    } finally {
      setIsFetchingMediaFileInfo(false);
    }
  }

  return { mediaFileInfo, isFetchingMediaFileInfo, fetchMediaFileInfo };
}
