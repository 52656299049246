import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { useUsersContext } from '../../../../context/UsersContext';
import { UserData, UserPutBody } from '../../../../interfaces/User';
import { UserFormSchema } from '../../utils/UsersFormValidation';
import { EditUserForm } from '../EditUserForm';

interface EditUserModalProps extends ModalProps {
  userToEdit: UserData;
}

export function EditUserModal({ userToEdit, isOpen, onClose }: EditUserModalProps) {
  const { getUsers, editUser, isEditingUser, setPage, pageSize } = useUsersContext();

  const editUserForm = useForm<UserPutBody>({
    defaultValues: {
      idUsuario: userToEdit.idUsuario,
      primeiroNome: userToEdit.primeiroNome,
      ultimoNome: userToEdit.ultimoNome,
      email: userToEdit.email,
      idioma: userToEdit.idioma,
      ativo: userToEdit.ativo,
      tipo: userToEdit.tipo,
    },
    resolver: yupResolver(UserFormSchema),
  });

  const { handleSubmit, reset } = editUserForm;

  function handleCloseModal() {
    reset();
    onClose();
  }

  async function handleEditUser(user: UserPutBody) {
    const editedUser = await editUser(user);
    setPage(1);
    await getUsers({ page: 1, pageSize, itemInList: editedUser });
    handleCloseModal();
  }

  return (
    <BaseModal
      onSaveCallback={handleSubmit(handleEditUser)}
      showSaveButton={!isEditingUser}
      title={`${userToEdit.primeiroNome} ${userToEdit.ultimoNome}`}
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
    >
      <FormProvider {...editUserForm}>
        <EditUserForm />
      </FormProvider>
    </BaseModal>
  );
}
