import { Modal, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import styles from './styles.module.scss';
import { ModalProps } from '../BaseModal';

interface ConfirmAlertModalProps extends ModalProps {
  title: string;
  message: string;
  onConfirmCallback: () => void;
}

export function ConfirmAlertModal({ isOpen, onClose, title, message, onConfirmCallback }: ConfirmAlertModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.modalBackground}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeading}>
            <h1>{title}</h1>
            <IconButton onClick={onClose}>
              <AiOutlineClose />
            </IconButton>
          </div>
          <div className={styles.content}>
            <p className={styles.message}>{message}</p>
          </div>
          <div className={styles.modalActions}>
            <Button color="error" variant="outlined" onClick={onClose}>
              {t('components.delete_alert_modal.no_button')}
            </Button>
            <Button color="primary" variant="contained" onClick={() => onConfirmCallback()}>
              {t('components.delete_alert_modal.yes_button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
