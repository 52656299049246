import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { RequestAccessFormSchema } from '../../utils/RequestAccessFormSchema';
import { AuthRequestAccessType } from '../../../../interfaces/Auth';
import { RequestAccessForm } from '../RequestAccessForm';
import { useAuthContext } from '../../../../context/AuthContext';

interface RequestAccessModalProps extends ModalProps {}

export function RequestAccessModal({ isOpen, onClose }: RequestAccessModalProps) {
  const { doRequestAccess, isRequestingAccess } = useAuthContext();
  const { t } = useTranslation();

  const requestAccessForm = useForm<AuthRequestAccessType>({
    resolver: yupResolver(RequestAccessFormSchema),
  });

  const { handleSubmit, reset } = requestAccessForm;

  function handleCloseModal() {
    reset();
    onClose();
  }

  async function handleRequestAccess(data: AuthRequestAccessType) {
    await doRequestAccess(data);
    handleCloseModal();
  }

  return (
    <BaseModal
      showSaveButton={!isRequestingAccess}
      onSaveCallback={handleSubmit(handleRequestAccess)}
      title={t('login_page.components.request_access_modal.modal_title')}
      saveButtonText={t('login_page.components.password_recovery_modal.submit_recovery_button')}
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
    >
      <FormProvider {...requestAccessForm}>
        <RequestAccessForm />
      </FormProvider>
    </BaseModal>
  );
}
