import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { Input } from '../../../../components/Input';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useCategoriesContext } from '../../../../context/CategoriesContext';
import { CategoryFormSchema } from '../../utils/CategoriesFormValidation';
import styles from './styles.module.scss';
import { CategoryPostBody } from '../../../../interfaces/Category';

interface AddNewCategoryModalProps extends ModalProps {}

export function AddNewCategoryModal({ isOpen, onClose }: AddNewCategoryModalProps) {
  const { setPage, pageSize, getCategories, createCategory, isCreatingCategory } = useCategoriesContext();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<CategoryPostBody>({
    resolver: yupResolver(CategoryFormSchema),
    defaultValues: {
      ativo: true,
    },
  });

  async function handleCreateNewCategory(data: CategoryPostBody) {
    const createdCategory = await createCategory(data);
    setPage(1);
    await getCategories({ page: 1, pageSize, itemInList: createdCategory });
    reset();
    onClose();
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('categories_page.components.add_new_category_modal.modal_title')}
      showSaveButton={!isCreatingCategory}
      onSaveCallback={handleSubmit(handleCreateNewCategory)}
    >
      <div className={styles.formContainer}>
        {isCreatingCategory ? (
          <LoadIndicator />
        ) : (
          <>
            <Input
              label={t('categories_page.components.add_new_category_modal.category_name_input_label')}
              {...register('nome')}
              error={errors.nome}
            />
            <FormControlLabel
              control={<Checkbox {...register('ativo')} defaultChecked={getValues('ativo')} />}
              label={t('categories_page.components.add_new_category_modal.active_category_checkbox_label')}
            />
          </>
        )}
      </div>
    </BaseModal>
  );
}
