import { MediaData } from '../../interfaces/Media';
import { BaseModal, ModalProps } from '../BaseModal';
import { AudioPlayer } from './AudioPlayer';

interface AudioModalProps extends ModalProps {
  media: MediaData;
  modalTitle: string;
  onAudioStarted?: () => void;
  onAudioEnded?: () => void;
}

export function AudioModal({ isOpen, onClose, media, modalTitle, onAudioEnded, onAudioStarted }: AudioModalProps) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <AudioPlayer media={media} onAudioEnded={onAudioEnded} onAudioStarted={onAudioStarted} />
    </BaseModal>
  );
}
