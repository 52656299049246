import { CircularProgress, IconButton } from '@mui/material';
import { HiOutlineDownload, HiOutlineBookOpen } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaData, MediaFileData } from '../../../../interfaces/Media';
import { useThemeContext } from '../../../../context/ThemeContext';
import { api } from '../../../../services/api';
import { UserActivityEnum } from '../../../../enums/User';
import { UserActivity } from '../../../../interfaces/User';
import { useModal } from '../../../../hooks/useModal';
import { PDFViewerModal, ReturnFileCallBackProps } from '../../../../components/PDFViewerModal';
import { useFetchMediaFileInfo } from '../../../../hooks/useMedia';

interface DownloadButtonProps {
  media: MediaData;
}

export async function downloadMedia(
  media: MediaData,
  returnFileCallBack?: ({ file, mediaName }: ReturnFileCallBackProps) => void
) {
  const responseFile = await api.get<MediaFileData>(`/media/${media.idMedia}/arquivo`);

  if (responseFile.data && responseFile.status === 200) {
    const fileContent = window.atob(responseFile.data.conteudo);
    const buffer = new ArrayBuffer(fileContent.length);
    const intArray = new Uint8Array(buffer);

    for (let n = 0; n < fileContent.length; n += 1) {
      intArray[n] = fileContent.charCodeAt(n);
    }

    const fileName = `${media.nome}${responseFile.data.extensao}`;
    const mimeType = { type: responseFile.data.mimeType };

    const file = new File([buffer], fileName, mimeType);

    if (file.type !== 'application/pdf') {
      const userActivity: UserActivity = {
        idMedia: media.idMedia || 0,
        tipo: UserActivityEnum.Download,
      };

      await api.post('/usuario/media', userActivity);

      const url = URL.createObjectURL(file);

      window.open(url);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      link.remove();

      URL.revokeObjectURL(url);
      return;
    }
    if (returnFileCallBack) {
      const userActivity: UserActivity = {
        idMedia: media.idMedia || 0,
        tipo: UserActivityEnum.MediaIniciada,
      };

      await api.post('/usuario/media', userActivity);
      returnFileCallBack({ file, mediaName: media.nome || '', mediaId: media.idMedia || 0 });
    }
  }
}

export function DownloadButton({ media }: DownloadButtonProps) {
  const [isDownloadingMedia, setIsDownloadingMedia] = useState<boolean>(false);
  const [isPdfModalOpen, handleOpenPdfModal, handleClosePdfModal] = useModal();
  const [pdfFileContent, setPdfFileContent] = useState<ReturnFileCallBackProps>({} as ReturnFileCallBackProps);

  const { t } = useTranslation();

  const { selectedTheme } = useThemeContext();
  const { fetchMediaFileInfo, mediaFileInfo, isFetchingMediaFileInfo } = useFetchMediaFileInfo();

  function openPdfModal(params: ReturnFileCallBackProps) {
    const { file, mediaName, mediaId } = params;
    setPdfFileContent({ file, mediaName, mediaId });
    handleOpenPdfModal();
  }

  async function onClickDownloadButton() {
    setIsDownloadingMedia(true);
    try {
      await downloadMedia(media, openPdfModal);
    } finally {
      setIsDownloadingMedia(false);
    }
  }

  function returnMediaIcon() {
    if (mediaFileInfo.mimeType === 'application/pdf') {
      return <HiOutlineBookOpen />;
    }
    return <HiOutlineDownload />;
  }

  useEffect(() => {
    async function executeAsync() {
      await fetchMediaFileInfo(media.idMedia || 0);
    }
    executeAsync();
  }, []);

  return (
    <>
      <IconButton
        style={selectedTheme === 'dark' ? { color: '#0066ff' } : {}}
        color="secondary"
        aria-label={
          mediaFileInfo.mimeType === 'application/pdf'
            ? t('media_page.components.download_button.aria_label_read_document')
            : t('media_page.components.download_button.aria_label_download_media')
        }
        onClick={() => onClickDownloadButton()}
        title={
          mediaFileInfo.mimeType === 'application/pdf'
            ? t('media_page.components.download_button.title_read_document')
            : t('media_page.components.download_button.title_download_media')
        }
      >
        {isDownloadingMedia || isFetchingMediaFileInfo ? (
          <CircularProgress size={24} color="secondary" style={selectedTheme === 'dark' ? { color: '#0066ff' } : {}} />
        ) : (
          returnMediaIcon()
        )}
      </IconButton>
      <PDFViewerModal isOpen={isPdfModalOpen} onClose={handleClosePdfModal} pdfFileContent={pdfFileContent} />
    </>
  );
}
