import { createContext, ReactNode, useContext, useState } from 'react';
import { useCreateMedia, useDeleteMedia, useEditMedia, useFetchMedias } from '../hooks/useMedia';
import { Metadata } from '../interfaces/ApiResponse';
import {
  FetchMediaParamsType,
  MediaData,
  MediaParamsToOrderBy,
  MediaPostBody,
  MediaPutBody,
} from '../interfaces/Media';

type MediasContextType = {
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  orderBy: keyof MediaParamsToOrderBy | '';
  setOrderBy: (value: keyof MediaParamsToOrderBy | '') => void;
  order: 'Asc' | 'Desc' | '';
  setOrder: (value: 'Asc' | 'Desc' | '') => void;
  medias: MediaData[];
  getMedias: ({ page, pageSize, nome, ativo, itemInList, order, orderBy }: FetchMediaParamsType) => Promise<void>;
  metadata: Metadata;
  isFetchingMedias: boolean;
  createMedia: (media: MediaPostBody) => Promise<MediaData>;
  isCreatingMedia: boolean;
  editMedia: (media: MediaPutBody) => Promise<MediaData>;
  isEditingMedia: boolean;
  deleteMedia: (media: MediaData) => Promise<void>;
  isDeletingMedia: boolean;
};

const MediasContext = createContext({} as MediasContextType);

interface MediasContextProviderProps {
  children: ReactNode;
}

export function MediasContextProvider({ children }: MediasContextProviderProps) {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof MediaParamsToOrderBy | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');
  const { medias, metadata, getMedias, isFetchingMedias } = useFetchMedias();
  const { createMedia, isCreatingMedia } = useCreateMedia();
  const { editMedia, isEditingMedia } = useEditMedia();
  const { deleteMedia, isDeletingMedia } = useDeleteMedia();

  return (
    <MediasContext.Provider
      value={{
        page,
        setPage,
        pageSize,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        medias,
        metadata,
        getMedias,
        isFetchingMedias,
        createMedia,
        isCreatingMedia,
        editMedia,
        isEditingMedia,
        deleteMedia,
        isDeletingMedia,
      }}
    >
      {children}
    </MediasContext.Provider>
  );
}

export function useMediasContext() {
  const mediasContext = useContext(MediasContext);
  return mediasContext;
}
