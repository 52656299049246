import { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentsList } from './components/ContentsList';
import { CategoryFilterButton } from './components/CategoryFilterButton';
import { MyContentsList } from './components/MyContentList';
import styles from './styles.module.scss';
import { useContentsContext } from '../../context/ContentsContext';
import { CategoryData } from '../../interfaces/Category';

export function Home() {
  const [searchContentName, setSearchContentName] = useState<string>('');
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<CategoryData | null>(null);

  const { getContents, isFetchingContents, setPage, pageSize, page, getUserContents } = useContentsContext();

  const { t } = useTranslation();

  function handleSearchContentByName(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPage(1);
    getContents({
      page: 1,
      pageSize,
      categoryId: selectedMenuCategory?.idCategoria,
      nome: searchContentName,
      ativo: true,
      catAtiva: true,
    });
  }

  useEffect(() => {
    getContents({ page, pageSize, ativo: true, catAtiva: true });
    getUserContents();
  }, []);

  return (
    <>
      <div className={styles.homeHeading}>
        <h1>{t('home_page.index.available_content')}</h1>
        <form className={styles.contentNameFormFilter} onSubmit={handleSearchContentByName}>
          <TextField
            type="text"
            placeholder={t('home_page.index.content_name_input_pch')}
            color="primary"
            variant="outlined"
            size="small"
            value={searchContentName}
            onChange={(e) => setSearchContentName(e.target.value)}
            disabled={isFetchingContents}
            fullWidth
          />
          <Button type="submit" color="primary" variant="contained" disabled={isFetchingContents}>
            {t('home_page.index.search_content_button')}
          </Button>
        </form>
      </div>
      <div className={styles.categoryName}>
        <h1>{selectedMenuCategory ? selectedMenuCategory.nome : t('home_page.index.all_categories')}</h1>
        <CategoryFilterButton
          setSelectedMenuCategory={setSelectedMenuCategory}
          selectedMenuCategory={selectedMenuCategory}
          name={searchContentName}
        />
      </div>
      <ContentsList selectedMenuCategory={selectedMenuCategory} searchContentName={searchContentName} />
      <MyContentsList selectedMenuCategory={selectedMenuCategory} searchContentName={searchContentName} />
    </>
  );
}
