import { IconButton } from '@mui/material';
import { HiPlay } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../../hooks/useModal';
import { MediaData } from '../../../../interfaces/Media';
import { useThemeContext } from '../../../../context/ThemeContext';
import { AudioModal } from '../../../../components/AudioModal';

interface ListenAudioButtonProps {
  media: MediaData;
}

export function ListenAudioButton({ media }: ListenAudioButtonProps) {
  const [isMediaAudioModalOpen, handleOpenMediaAudioModal, handleCloseMediaAudioModal] = useModal();
  const { selectedTheme } = useThemeContext();

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        style={selectedTheme === 'dark' ? { color: '#0066ff' } : {}}
        color="secondary"
        aria-label={t('media_page.components.listen_audio_button.aria_label')}
        title={t('media_page.components.listen_audio_button.title')}
        onClick={() => handleOpenMediaAudioModal()}
      >
        <HiPlay />
      </IconButton>
      {isMediaAudioModalOpen && (
        <AudioModal
          media={media}
          isOpen={isMediaAudioModalOpen}
          onClose={handleCloseMediaAudioModal}
          modalTitle={media.nome || ''}
        />
      )}
    </>
  );
}
