import { createContext, ReactNode, useContext, useEffect } from 'react';
import i18next from 'i18next';
import { AuthRequestAccessType, AuthRequestCompanyType, AuthRequestHeaderType } from '../interfaces/Auth';
import { useAuth, useRequestAccess } from '../hooks/useAuth';
import { UserData } from '../interfaces/User';
import { LanguageConverterLanguages } from '../enums/LanguageConverterI18n';

type AuthContextType = {
  doLogin: ({ usuario, senha }: AuthRequestHeaderType, onAuthenticated: () => void) => Promise<void>;
  doLoginCompany: ({ idEmpresa }: AuthRequestCompanyType) => Promise<void>;
  doLogout: () => Promise<void>;
  isLoggingIn: boolean;
  authenticated: boolean;
  currentUser: UserData | undefined;
  getCurrentUser: () => Promise<void>;
  isFetchingCurrentUser: boolean;
  doRequestAccess: ({ email, idioma, primeiroNome, ultimoNome }: AuthRequestAccessType) => Promise<void>;
  isRequestingAccess: boolean;
};

const AuthContext = createContext({} as AuthContextType);

interface AuthContextProviderProps {
  children: ReactNode;
}

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const {
    doLogin,
    doLoginCompany,
    doLogout,
    isLoggingIn,
    authenticated,
    currentUser,
    isFetchingCurrentUser,
    getCurrentUser,
  } = useAuth();
  const { doRequestAccess, isRequestingAccess } = useRequestAccess();

  useEffect(() => {
    if (currentUser?.idioma) {
      localStorage.setItem('language', currentUser?.idioma);
    }
    i18next.changeLanguage(LanguageConverterLanguages[currentUser?.idioma || '']);
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        doLogin,
        doLoginCompany,
        doLogout,
        isLoggingIn,
        authenticated,
        currentUser,
        isFetchingCurrentUser,
        getCurrentUser,
        doRequestAccess,
        isRequestingAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  return authContext;
}
