import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import styles from './styles.module.scss';
import { ContentData } from '../../../../interfaces/Content';
import ReactMarkdownShow from '../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { useThemeContext } from '../../../../context/ThemeContext';
import { useAddContentUser, useDeleteContentUser } from '../../../../hooks/useContent';

interface ContentIntroductionProps {
  content: ContentData;
  fetchContent: (contentId: number) => void;
}

export function ContentIntroduction({ content, fetchContent }: ContentIntroductionProps) {
  const { nome, descricao, capa, categoria, idConteudo, conteudoNaLista } = content;
  const [isUpdatingListButton, setIsUpdatingListButton] = useState<boolean>(false);

  const { t } = useTranslation();

  const { selectedTheme } = useThemeContext();
  const { addContentUser } = useAddContentUser();
  const { deleteContentUser } = useDeleteContentUser();

  async function addContentToList() {
    setIsUpdatingListButton(true);

    await addContentUser({ idConteudo: idConteudo || 0, nome: nome || '' });
    fetchContent(content.idConteudo || 0);

    setIsUpdatingListButton(false);
  }

  async function deleteContentFromList() {
    setIsUpdatingListButton(true);

    await deleteContentUser({ idConteudo: idConteudo || 0, nome: nome || '' });
    fetchContent(content.idConteudo || 0);

    setIsUpdatingListButton(false);
  }

  function returnListButton() {
    if (conteudoNaLista) {
      return (
        <button type="button" onClick={() => deleteContentFromList()}>
          <AiOutlineMinusCircle />
          {t('home_page.components.content_card.my_list_button')}
        </button>
      );
    }
    return (
      <button type="button" onClick={() => addContentToList()}>
        <AiOutlinePlusCircle />
        {t('home_page.components.content_card.my_list_button')}
      </button>
    );
  }

  return (
    <div className={styles.contentIntroductionContainer}>
      <img
        src={capa}
        alt={t('home_page.components.content_card.content_cover_image', { contentName: nome })}
        className={styles.imgContainer}
      />
      <div className={`${styles.contentIntroductionContent} ${selectedTheme === 'light' ? styles.border : ''}`}>
        <div className={styles.introductionHeading}>
          <h5>{categoria?.nome}</h5>
          <h4>{nome}</h4>
        </div>
        <div className={styles.introductionInfos}>
          <ReactMarkdownShow>{descricao || ''}</ReactMarkdownShow>
        </div>
        <div className={styles.introductionActions}>
          {isUpdatingListButton ? <CircularProgress /> : returnListButton()}
          <Link to="/">
            <BsArrowLeftCircle />
          </Link>
        </div>
      </div>
    </div>
  );
}
