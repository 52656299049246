import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../../../../../../pages/Report/components/ReportTable/styles.module.scss';
import { OnSortProps, useTableSorting } from '../../../../../../hooks/useTableSorting';
import { UserHistoryParamsToOrderBy } from '../../../../../../interfaces/User';
import { useAuthContext } from '../../../../../../context/AuthContext';
import { useUserHistoryContext } from '../../../../../../context/UserHistoryContext';
import { LoadIndicator } from '../../../../../LoadIndicator';
import { MediaEnum } from '../../../../../../enums/Media';
import { getDateWithTime } from '../../../../../../pages/Report/utils/date';
import { WatchVideoButton } from '../../../../../../pages/Medias/components/WatchVideoButton';
import { ListenAudioButton } from '../../../../../../pages/Medias/components/ListenAudioButton';
import { DownloadButton } from '../../../../../../pages/Medias/components/DownloadButton';
import { MediaData } from '../../../../../../interfaces/Media';
import { ShowMediaEnum } from '../../../../../../enums/ShowMedia';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function UserHistoryTable() {
  const { currentUser } = useAuthContext();

  const { t } = useTranslation();

  const {
    setOrder,
    setOrderBy,
    setPage,
    userHistory,
    getUserHistory,
    pageSize,
    dateFrom,
    dateTo,
    content,
    category,
    isFetchingUserHistory,
  } = useUserHistoryContext();

  async function onTableSort({ orderOnSort, orderByOnSort }: OnSortProps<UserHistoryParamsToOrderBy>) {
    setOrderBy(orderByOnSort);
    setOrder(orderOnSort);
    setPage(1);
    if (userHistory.length > 0) {
      getUserHistory({
        page: 1,
        pageSize,
        order: orderOnSort,
        orderBy: orderByOnSort,
        idCategoria: category,
        idConteudo: content,
        dataAte: dateTo,
        dataDe: dateFrom,
      });
    }
  }

  const orderButton = useTableSorting<UserHistoryParamsToOrderBy>(onTableSort);

  function returnMediaButton(media: MediaData | undefined) {
    if (media !== undefined) {
      if (media.tipo === MediaEnum.Vídeo || media.tipo === MediaEnum.YouTube) {
        return <WatchVideoButton media={media} />;
      }
      if (media.tipo === MediaEnum.Áudio) {
        return <ListenAudioButton media={media} />;
      }
      return <DownloadButton media={media} />;
    }
    return null;
  }

  if (isFetchingUserHistory) return <LoadIndicator />;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.tableHeaderCell} align="center">
              {t('report_page.components.report_table.table.action_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('nomeMedia')}
              {t('report_page.components.report_table.table.media_name_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('tipoMedia')}
              {t('report_page.components.report_table.table.media_type_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataInicio')}
              {t('report_page.components.report_table.table.start_date_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataFim')}
              {t('report_page.components.report_table.table.end_date_header')}
            </StyledTableCell>
            <StyledTableCell className={styles.tableHeaderCell} align="left">
              {orderButton('dataDownload')}
              Download
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userHistory?.map((userHis) => (
            <StyledTableRow key={userHis.idAtividadeUsuario} className={styles.tableRow}>
              <TableCell className={styles.tableBodyCell} align="center">
                {returnMediaButton(userHis.media)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {userHis.media?.nome}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {userHis.media?.tipo ? t(ShowMediaEnum[userHis.media.tipo || 0]) : ''}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {userHis.dataInicio == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(userHis.dataInicio, currentUser?.idioma)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {userHis.dataFim == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(userHis.dataFim, currentUser?.idioma)}
              </TableCell>
              <TableCell className={styles.tableBodyCell} align="left">
                {userHis.dataDownload == null
                  ? t('report_page.components.report_table.table.no_date')
                  : getDateWithTime(userHis.dataDownload, currentUser?.idioma)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
