import { useTranslation } from 'react-i18next';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { ContentData } from '../../../../interfaces/Content';
import { MediaData } from '../../../../interfaces/Media';
import { SectionCard } from '../SectionCard';
import styles from './styles.module.scss';

interface SectionsListProps {
  sections: MediaData[];
  content: ContentData;
  isFetchingContentMedias: boolean;
  getContentMedias: (contentId: number, active?: boolean) => {};
}

export function SectionsList({ sections, content, getContentMedias, isFetchingContentMedias }: SectionsListProps) {
  const { t } = useTranslation();

  function returnMessageNoMedias() {
    if (!isFetchingContentMedias) {
      return <p>{t('content_page.components.sections_list.no_media_within')}</p>;
    }
    return <LoadIndicator />;
  }

  return (
    <div className={styles.sectionsListContainer}>
      {sections.length > 0
        ? sections.map((section, index) => (
            <SectionCard
              getContentMedias={getContentMedias}
              section={section}
              secondaryBackground={index % 2 === 1}
              key={section.idMedia}
              content={content}
            />
          ))
        : returnMessageNoMedias()}
    </div>
  );
}
