import { useEffect, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import { LoadIndicator } from '../../../../../../components/LoadIndicator';
import { CategoryPutBody } from '../../../../../../interfaces/Category';
import { ContentData } from '../../../../../../interfaces/Content';

interface OrderModalProps {
  contents: ContentData[];
  isFetchingContents: boolean;
}

export function OrderModal({ contents, isFetchingContents }: OrderModalProps) {
  const { getValues, setValue, watch } = useFormContext<CategoryPutBody>();
  const [conteudosOrder, setConteudosOrder] = useState<ContentData[]>([]);

  const watchOrdemConteudos = watch('ordemConteudos');

  const { t } = useTranslation();

  function changeDirection(index: number, direction: 'up' | 'down') {
    const copy = [...conteudosOrder];
    const temp = copy[index];
    if (direction === 'up' && index > 0) {
      copy[index] = copy[index - 1];
      copy[index - 1] = temp;
    } else if (direction === 'down' && index < copy.length - 1) {
      copy[index] = copy[index + 1];
      copy[index + 1] = temp;
    }
    setConteudosOrder(copy);
    setValue(
      'ordemConteudos',
      copy.map((cont) => cont.idConteudo || 0)
    );
  }

  useEffect(() => {
    setConteudosOrder(
      getValues('ordemConteudos')
        .map((ord) => contents.filter((cont) => cont.idConteudo === ord))
        .flat(1)
    );
  }, [watchOrdemConteudos]);

  if (isFetchingContents) {
    return <LoadIndicator />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>{t('categories_page.components.edit_category_modal.order_tab_title')}</span>
      <div className={styles.tableContainer}>
        <Table size="small" className={styles.table}>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell className={styles.tableHeadCell} width={120} />
              <TableCell className={styles.tableHeadCell} width={200}>
                {t('categories_page.components.edit_category_modal.order_tab_table_header_content')}
              </TableCell>
              <TableCell className={styles.tableHeadCell} width={200}>
                {t('categories_page.components.edit_category_modal.order_tab_table_header_description')}
              </TableCell>
              <TableCell className={styles.tableHeadCell} width={120}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conteudosOrder &&
              conteudosOrder.map((order, index) => (
                <TableRow key={index} className={styles.tableRow}>
                  <TableCell width={120}>
                    <IconButton disabled={index === 0} onClick={() => changeDirection(index, 'up')}>
                      <AiOutlineArrowUp />
                    </IconButton>
                    <IconButton
                      disabled={index === conteudosOrder.length - 1}
                      onClick={() => changeDirection(index, 'down')}
                    >
                      <AiOutlineArrowDown />
                    </IconButton>
                  </TableCell>
                  <TableCell width={200}>{order.nome}</TableCell>
                  <TableCell width={200}>{order.descricao}</TableCell>
                  <TableCell width={120}>
                    <span className={order.ativo ? styles.activeBox : styles.inactiveBox}>
                      {order.ativo ? 'Active' : 'Inactive'}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
