import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { AddNewMediaForm } from '../AddNewMediaForm';
import { MediaFormSchema } from '../../utils/MediaFormValidation';
import { useMediasContext } from '../../../../context/MediasContext';
import { MediaPostBody } from '../../../../interfaces/Media';
import { MediaEnum } from '../../../../enums/Media';

interface AddNewMediaModalProps extends ModalProps {}

export function AddNewMediaModal({ isOpen, onClose }: AddNewMediaModalProps) {
  const { setPage, getMedias, createMedia, isCreatingMedia, pageSize } = useMediasContext();

  const { t } = useTranslation();

  const addNewMediaForm = useForm<MediaPostBody>({
    resolver: yupResolver(MediaFormSchema),
    defaultValues: {
      ativo: true,
    },
  });

  const { handleSubmit, reset, setError } = addNewMediaForm;

  const onCloseModal = () => {
    onClose();
    reset();
  };

  async function handleCreateNewMedia(media: MediaPostBody) {
    if (media.tipo === MediaEnum.YouTube) {
      if (!media.youtubeLink) {
        setError(
          'youtubeLink',
          { message: t('media_page.components.add_new_media_modal.no_youtube_link_error') },
          { shouldFocus: true }
        );
        return;
      }
    } else if (media.tipo !== MediaEnum.YouTube) {
      if (!media.media) {
        setError(
          'media',
          { message: t('media_page.components.add_new_media_modal.no_file_error') },
          { shouldFocus: true }
        );
        return;
      }
    }

    onCloseModal();
    const createdMedia = await createMedia(media);
    setPage(1);
    await getMedias({ page: 1, pageSize, itemInList: createdMedia });
  }

  return (
    <BaseModal
      onSaveCallback={handleSubmit(handleCreateNewMedia)}
      showSaveButton={!isCreatingMedia}
      title={t('media_page.components.add_new_media_modal.modal_title')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <FormProvider {...addNewMediaForm}>
        <AddNewMediaForm />
      </FormProvider>
    </BaseModal>
  );
}
