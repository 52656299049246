import { Button, FormControl, IconButton, InputAdornment, InputLabel, Pagination, TextField } from '@mui/material';
import React, { FormEvent } from 'react';
import { BiSearch } from 'react-icons/bi';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { SelectUserModal } from './components/SelectUserModal';
import { useModal } from '../../hooks/useModal';
import { ReportTable } from './components/ReportTable';
import { useActivityUserContext } from '../../context/ActivityUserContext';
import { SelectCategory } from './components/SelectCategory';
import { SelectContent } from './components/SelectContent';
import { UserData } from '../../interfaces/User';

export const InputDateMask = React.forwardRef<HTMLInputElement>((props, ref) => (
  <IMaskInput {...props} mask="00/00/0000" placeholder="DD/MM/AAAA" inputRef={ref} />
));

export function Report() {
  const [isSelectUserModalOpen, handleOpenSelectUserModal, handleCloseSelectUserModal] = useModal();

  const { t } = useTranslation();

  const {
    getActivityUser,
    isFetchingActivityUser,
    metadata,
    page,
    pageSize,
    setPage,
    order,
    orderBy,
    users,
    category,
    content,
    dateFrom,
    dateTo,
    setCategory,
    setContent,
    setDateFrom,
    setDateTo,
    setUsers,
  } = useActivityUserContext();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setPage(1);
    getActivityUser({
      page: 1,
      pageSize,
      order,
      orderBy,
      idUsuarios: users.map((user) => user.toString()).join(','),
      idCategoria: category,
      idConteudo: content,
      dataAte: dateTo,
      dataDe: dateFrom,
    });
  }

  function handlePageChange(value: number) {
    setPage(value);
    getActivityUser({
      page: value,
      pageSize,
      order,
      orderBy,
      idUsuarios: users.map((user) => user.toString()).join(','),
      idCategoria: category,
      idConteudo: content,
      dataAte: dateTo,
      dataDe: dateFrom,
    });
  }

  const handleSelectUser = (usersSelect: UserData[]) => {
    setUsers(usersSelect.map((user) => user.idUsuario || 0));
    handleCloseSelectUserModal();
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('report_page.index.title')}</h1>
      <div className={styles.heading}>
        <form className={styles.searchBar} onSubmit={(e) => handleSubmit(e)}>
          <fieldset className={styles.inputContainer}>
            <FormControl size="small" fullWidth>
              <InputLabel id="label-selecionar-categoria">
                {t('report_page.index.select_category_input_label')}
              </InputLabel>
              <SelectCategory category={category} setCategory={setCategory} />
            </FormControl>
          </fieldset>

          <fieldset className={styles.inputContainer}>
            <FormControl size="small" fullWidth>
              <InputLabel id="courses-select-label">{t('report_page.index.select_content_input_label')}</InputLabel>
              <SelectContent content={content} setContent={setContent} category={category} />
            </FormControl>
          </fieldset>

          <fieldset className={styles.inputContainer}>
            <TextField
              size="small"
              value={t('report_page.index.select_user_input_value', { selectedUsers: users.length })}
              label={t('report_page.index.select_user_input_label')}
              className={`${styles.input} ${styles.userInput}`}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => handleOpenSelectUserModal()}>
                    <IconButton size="small">
                      <BiSearch className={styles.searchIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-input': {
                  textOverflow: 'ellipsis',
                },
              }}
            />
          </fieldset>

          <fieldset className={styles.containerDate}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label={t('report_page.index.date_from_input_label')}
                value={dateFrom}
                size="small"
                className={styles.inputDate}
                onChange={({ target }) => setDateFrom(target.value)}
                InputProps={{
                  inputComponent: InputDateMask as any,
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                type="text"
                label={t('report_page.index.date_to_input_label')}
                size="small"
                value={dateTo}
                className={styles.inputDate}
                onChange={({ target }) => setDateTo(target.value)}
                InputProps={{
                  inputComponent: InputDateMask as any,
                }}
              />
            </FormControl>
          </fieldset>

          <div className={styles.searchContainer}>
            <Button variant="contained" type="submit" className={styles.searchButton}>
              {t('report_page.index.search_report_button')}
            </Button>
          </div>
        </form>
      </div>
      <ReportTable />
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          onChange={(_, value) => handlePageChange(value)}
          count={metadata.pages}
          color="primary"
          variant="outlined"
          disabled={isFetchingActivityUser}
        />
      </div>
      <SelectUserModal
        isOpen={isSelectUserModalOpen}
        onClose={handleCloseSelectUserModal}
        handleSaveSelection={handleSelectUser}
      />
    </div>
  );
}
