import { AxiosResponse } from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../context/AuthContext';
import { api } from './api';
import { useToastContext } from '../context/ToastContext';

interface AxiosInterceptorProps {
  children: ReactElement<any, any> | null;
}

export function AxiosInterceptor({ children }: AxiosInterceptorProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { doLogout } = useAuthContext();
  const { createToast } = useToastContext();

  function parseJwt(token: string | undefined) {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          // eslint-disable-next-line prefer-template
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      return JSON.parse(jsonPayload);
    }
    return null;
  }
  const { t } = useTranslation();

  useEffect(() => {
    function ResponseInterceptorSuccess(response: AxiosResponse<any, any>) {
      const tokenJWT = response.headers['x-access-token'];
      if (tokenJWT) {
        api.defaults.headers.Authorization = `Bearer ${tokenJWT}`;
        localStorage.setItem('authToken', tokenJWT);
      }
      return Promise.resolve(response);
    }

    function ResponseInterceptorError(error: any) {
      if (error?.response?.status === 401) {
        if (api.defaults.headers.Authorization !== '') {
          doLogout();
          createToast(t('services.axios_interceptor.session_expired'), 'warning');
        }
        return Promise.reject(error);
      }
      if (error?.response?.status !== 401) {
        if (parseJwt(error.response.headers['x-access-token'])?.company <= 0) {
          doLogout();
          localStorage.removeItem('authToken');
        }
        return Promise.reject(error);
      }
    }

    const interceptor = api.interceptors.response.use(ResponseInterceptorSuccess, ResponseInterceptorError);

    setIsLoading(false);

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  if (!isLoading) {
    return children;
  }
  return null;
}
