import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Tab } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import { Input } from '../../../../components/Input';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useCategoriesContext } from '../../../../context/CategoriesContext';
import { CategoryData, CategoryPutBody } from '../../../../interfaces/Category';
import { CategoryFormSchema } from '../../utils/CategoriesFormValidation';
import styles from './styles.module.scss';
import { OrderModal } from './components/OrderModal';
import { useFetchContents } from '../../../../hooks/useContent';

interface EditCategoryModalProps extends ModalProps {
  categoryToEdit: CategoryData;
  onCategoryEdit?: () => void;
}

export function EditCategoryModal({ categoryToEdit, isOpen, onClose, onCategoryEdit }: EditCategoryModalProps) {
  const { t } = useTranslation();

  const TABS_OPTIONS = {
    DETAILS: t('categories_page.components.edit_category_modal.details_tab'),
    ORDER: t('categories_page.components.edit_category_modal.order_tab'),
  };

  const [valueTab, setValueTab] = useState<string>('1');

  const { setPage, pageSize, getCategories, editCategory, isEditingCategory } = useCategoriesContext();
  const { getContents, contents, isFetchingContents } = useFetchContents();

  const editCategoryForm = useForm<CategoryPutBody>({
    resolver: yupResolver(CategoryFormSchema),
    defaultValues: {
      idCategoria: categoryToEdit.idCategoria,
      nome: categoryToEdit.nome,
      ativo: categoryToEdit.ativo,
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = editCategoryForm;

  const isActive = watch('ativo');

  async function handleUpdateCategory(data: CategoryPutBody) {
    const editedCategory = await editCategory(data);
    setPage(1);
    await getCategories({ page: 1, pageSize, itemInList: editedCategory });
    if (onCategoryEdit) {
      onCategoryEdit();
    }
    onClose();
  }

  function handleTabChange(e: React.SyntheticEvent, newValue: string) {
    setValueTab(newValue);
  }

  useEffect(() => {
    getContents({ page: 1, pageSize: 999999999, categoryId: categoryToEdit.idCategoria });
  }, []);

  useEffect(() => {
    setValue(
      'ordemConteudos',
      contents.map((cm) => cm.idConteudo || 0)
    );
  }, [contents]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={categoryToEdit.nome || ''}
      showSaveButton={!isEditingCategory}
      onSaveCallback={handleSubmit(handleUpdateCategory)}
    >
      <div className={styles.editCategoryContainer}>
        <TabContext value={valueTab}>
          <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} className={styles.tabsContainer}>
            <Tab label={TABS_OPTIONS.DETAILS} value="1" className={styles.tabLabel} />
            <Tab label={TABS_OPTIONS.ORDER} value="2" className={styles.tabLabel} />
          </TabList>
          <TabPanel value="1" className={styles.tabPanel}>
            {isEditingCategory ? (
              <LoadIndicator />
            ) : (
              <div className={styles.formContainer}>
                <Input
                  label={t('categories_page.components.add_new_category_modal.category_name_input_label')}
                  {...register('nome')}
                  error={errors.nome}
                />
                <FormControlLabel
                  control={<Checkbox {...register('ativo')} checked={isActive} />}
                  label={t('categories_page.components.add_new_category_modal.active_category_checkbox_label')}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value="2" className={styles.tabPanel}>
            {isEditingCategory ? (
              <LoadIndicator />
            ) : (
              <FormProvider {...editCategoryForm}>
                <OrderModal contents={contents} isFetchingContents={isFetchingContents} />
              </FormProvider>
            )}
          </TabPanel>
        </TabContext>
      </div>
    </BaseModal>
  );
}
