import { ReactNode, createContext, useContext, useState } from 'react';
import { ActivityUserData} from '../interfaces/ActivityUser';
import { Metadata } from '../interfaces/ApiResponse';
import { FetchUserHistoryParamsType, UserHistoryParamsToOrderBy } from '../interfaces/User';
import { useFetchUserHistory } from '../hooks/useUsers';

type UserHistoryContextType = {
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  orderBy: keyof UserHistoryParamsToOrderBy | '';
  setOrderBy: (value: keyof UserHistoryParamsToOrderBy | '') => void;
  order: 'Asc' | 'Desc' | '';
  setOrder: (value: 'Asc' | 'Desc' | '') => void;
  userHistory: ActivityUserData[];
  getUserHistory: ({
    page,
    pageSize,
    order,
    orderBy,
    idCategoria,
    idConteudo,
    idUsuario,
    dataAte,
    dataDe,
  }: FetchUserHistoryParamsType) => Promise<void>;
  isFetchingUserHistory: boolean;
  metadata: Metadata;
  category: string;
  content: string;
  dateFrom: string;
  dateTo: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setDateFrom: React.Dispatch<React.SetStateAction<string>>;
  setDateTo: React.Dispatch<React.SetStateAction<string>>;
};

const UserHistoryContext = createContext({} as UserHistoryContextType);

interface UserHistoryContextProviderProps {
  children: ReactNode;
}

export function UserHistoryContextProvider({ children }: UserHistoryContextProviderProps) {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof UserHistoryParamsToOrderBy | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');

  const [category, setCategory] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const { getUserHistory, isFetchingUserHistory, metadata, userHistory } = useFetchUserHistory();

  return (
    <UserHistoryContext.Provider
      value={{
        page,
        pageSize,
        setPage,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        metadata,
        getUserHistory,
        isFetchingUserHistory,
        userHistory,
        category,
        content,
        dateFrom,
        dateTo,
        setCategory,
        setContent,
        setDateFrom,
        setDateTo,
      }}
    >
      {children}
    </UserHistoryContext.Provider>
  );
}

export function useUserHistoryContext() {
  const userHistoryContext = useContext(UserHistoryContext);
  return userHistoryContext;
}
