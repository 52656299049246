import { AiOutlineCheckCircle, AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { ContentData } from '../../../../interfaces/Content';
import ReactMarkdownShow from '../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { useAddContentUser, useDeleteContentUser } from '../../../../hooks/useContent';
import { useContentsContext } from '../../../../context/ContentsContext';
import { CategoryData } from '../../../../interfaces/Category';

interface ContentCardProps {
  isAddedToMyList?: boolean;
  content: ContentData;
  selectedMenuCategory: CategoryData | null;
  searchContentName: string;
}

export function ContentCard({
  isAddedToMyList = false,
  content,
  selectedMenuCategory,
  searchContentName,
}: ContentCardProps) {
  const { idConteudo, nome, descricao, capa, medias, completado } = content;

  const { addContentUser } = useAddContentUser();
  const { deleteContentUser } = useDeleteContentUser();

  const { getContents, page, pageSize, getUserContents } = useContentsContext();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const numberOfMedias = medias?.length;

  function handleNavigateContent() {
    navigate(`/content/${idConteudo}`);
  }

  async function addContentToList() {
    await addContentUser({ idConteudo: idConteudo || 0, nome: nome || '' });
    getContents({
      page,
      pageSize,
      categoryId: selectedMenuCategory?.idCategoria,
      nome: searchContentName,
      ativo: true,
      catAtiva: true,
    });
    getUserContents();
  }

  async function removeContentFromList() {
    await deleteContentUser({ idConteudo: idConteudo || 0, nome: nome || '' });
    getContents({
      page,
      pageSize,
      categoryId: selectedMenuCategory?.idCategoria,
      nome: searchContentName,
      ativo: true,
      catAtiva: true,
    });
    getUserContents();
  }

  function returnAddContentButton() {
    if (!content.conteudoNaLista) {
      return (
        <button type="button" onClick={() => addContentToList()}>
          <AiOutlinePlusCircle />
          {t('home_page.components.content_card.my_list_button')}
        </button>
      );
    }
    return undefined;
  }

  return (
    <div className={styles.cardContainer}>
      <button
        className={styles.cardContent}
        onClick={() => handleNavigateContent()}
        type="button"
        aria-label={t('home_page.components.content_card.navigate_to_content_aria_label', { content_name: content.nome })}
      >
        <div className={styles.completedContentImgContainer}>
          <img
            src={capa}
            alt={t('home_page.components.content_card.content_cover_image', { content_name: content.nome })}
            className={styles.contentImgContainer}
            width={250}
            height={160}
          />
          {completado && (
            <div>
              <AiOutlineCheckCircle />
              <span>{t('home_page.components.content_card.completed_content')}</span>
            </div>
          )}
        </div>
        <div className={styles.contentName}>
          <h5>{nome}</h5>
        </div>
        <div className={styles.contentDescription}>
          <div className={styles.contentDescriptionText}>
            <ReactMarkdownShow>{descricao || ''}</ReactMarkdownShow>
          </div>
        </div>
      </button>
      <div className={styles.contentActions}>
        {isAddedToMyList ? (
          <button type="button" onClick={() => removeContentFromList()}>
            <AiOutlineMinusCircle />
            {t('home_page.components.content_card.my_list_button')}
          </button>
        ) : (
          returnAddContentButton()
        )}
        <span className={styles.numberMedias}>{numberOfMedias} medias</span>
      </div>
    </div>
  );
}
