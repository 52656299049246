import { useFormContext, Controller } from 'react-hook-form';
import { Button, CircularProgress, FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import SimpleMdeReact from 'react-simplemde-editor';
import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import EasyMDE from 'easymde';
import { HiOutlineDocumentDownload, HiOutlineVideoCamera } from 'react-icons/hi';
import { FiVideoOff } from 'react-icons/fi';
import { MdAudiotrack } from 'react-icons/md';
import { Input } from '../../../../components/Input';
import styles from './styles.module.scss';
import { MediaData, MediaPutBody } from '../../../../interfaces/Media';
import ReactMarkdownShow from '../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { MediaEnum } from '../../../../enums/Media';
import { downloadMedia } from '../DownloadButton';
import config from '../../../../config/config';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useMediasContext } from '../../../../context/MediasContext';
import { AudioPlayer } from '../../../../components/AudioModal/AudioPlayer';
import { PDFViewerModal, ReturnFileCallBackProps } from '../../../../components/PDFViewerModal';
import { useModal } from '../../../../hooks/useModal';

interface EditMediaFormProps {
  mediaToEdit: MediaData;
  isWatchingPreviewVideo: boolean;
  setIsWatchingPreviewVideo: React.Dispatch<React.SetStateAction<boolean>>;
  isListeningAudio: boolean;
  setIsListeningAudio: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EditMediaForm({
  mediaToEdit,
  isWatchingPreviewVideo,
  setIsWatchingPreviewVideo,
  isListeningAudio,
  setIsListeningAudio,
}: EditMediaFormProps) {
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [selectedMediaType, setSelectedMediaType] = useState<string>('local');
  const [isDownloadingMedia, setIsDownloadingMedia] = useState<boolean>(false);
  const [isOptionsShowing, setIsOptionsShowing] = useState<boolean>(false);

  const [isPdfModalOpen, handleOpenPdfModal, handleClosePdfModal] = useModal();
  const [pdfFileContent, setPdfFileContent] = useState<ReturnFileCallBackProps>({} as ReturnFileCallBackProps);

  function openPdfModal(params: ReturnFileCallBackProps) {
    const { file, mediaName, mediaId } = params;
    setPdfFileContent({ file, mediaName, mediaId });
    handleOpenPdfModal();
  }

  const { isEditingMedia } = useMediasContext();

  const { t } = useTranslation();

  const {
    register,
    control,
    setValue,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext<MediaPutBody>();

  const watchAtivo = watch('ativo');

  const descriptionMarkdownEditorOptions = useMemo(
    () =>
      ({
        maxHeight: '200px',
        spellChecker: false,
        nativeSpellcheck: true,
        toolbar: [
          {
            name: 'bold',
            action: EasyMDE.toggleBold,
            className: 'fa fa-bold',
            title: 'Negrito',
          },
          {
            name: 'italic',
            action: EasyMDE.toggleItalic,
            className: 'fa fa-italic',
            title: 'Itálico',
          },
          {
            name: 'ordered-list',
            action: EasyMDE.toggleOrderedList,
            className: 'fa fa-list-ol',
            title: 'Inserir Lista Ordenada',
          },
          {
            name: 'unordered-list',
            action: EasyMDE.toggleUnorderedList,
            className: 'fa fa-list-ul',
            title: 'Inserir Lista Não Ordenada',
          },
          {
            name: 'heading',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Cabeçalho',
          },
          {
            name: 'heading-smaller',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Diminuir Cabeçalho',
          },
          {
            name: 'heading-bigger',
            action: EasyMDE.toggleHeadingBigger,
            className: 'fa fa-header',
            title: 'Aumentar Cabeçalho',
          },
          {
            name: 'quote',
            action: EasyMDE.toggleBlockquote,
            className: 'fa fa-quote-left',
            title: 'Inserir Citação',
          },
          {
            name: 'horizontal-rule',
            action: EasyMDE.drawHorizontalRule,
            className: 'fa fa-minus',
            title: 'Inserir Linha Horizontal',
          },
          {
            name: 'upload-image',
            action: EasyMDE.drawImage,
            className: 'fa fa-picture-o',
            title: 'Inserir Link de Imagem',
          },
          {
            name: 'link',
            action: EasyMDE.drawLink,
            className: 'fa fa-link',
            title: 'Inserir Link',
          },
          {
            name: 'table',
            action: EasyMDE.drawTable,
            className: 'fa fa-table',
            title: 'Inserir Tabela',
          },
          {
            name: 'code',
            action: EasyMDE.toggleCodeBlock,
            className: 'fa fa-code',
            title: 'Inserir Código',
          },
          {
            name: 'preview',
            action: EasyMDE.togglePreview,
            className: 'fa fa-eye no-disable',
            title: 'Visualizar',
          },
          {
            name: 'side-by-side',
            action: EasyMDE.toggleSideBySide,
            className: 'fa fa-columns no-disable no-mobile',
            title: 'Lado a Lado',
          },
          {
            name: 'fullscreen',
            action: EasyMDE.toggleFullScreen,
            className: 'fa fa-arrows-alt no-disable no-mobile',
            title: 'Tela Cheia',
          },
          {
            name: 'guide',
            action: () => {
              window.open('https://www.markdownguide.org/basic-syntax/');
            },
            className: 'fa fa-question-circle',
            title: 'Guia Markdown',
          },
        ],
        previewRender: (plainText) => ReactDOMServer.renderToString(<ReactMarkdownShow>{plainText}</ReactMarkdownShow>),
      } as EasyMDE.Options),
    []
  );

  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setValue('media', selectedFile);
      setSelectedFileName(selectedFile.name);

      if (selectedFile.type.startsWith('image')) {
        setValue('tipo', MediaEnum.Imagem);
      } else if (selectedFile.type.startsWith('video')) {
        setValue('tipo', MediaEnum.Vídeo);
      } else if (selectedFile.type.startsWith('audio')) {
        setValue('tipo', MediaEnum.Áudio);
      } else {
        setValue('tipo', MediaEnum.Documento);
      }
    }
  }

  async function onClickDownloadButton(media: MediaData) {
    setIsDownloadingMedia(true);
    try {
      await downloadMedia(media, openPdfModal);
    } finally {
      setIsDownloadingMedia(false);
    }
  }

  function returnFileButton() {
    if (mediaToEdit.tipo === MediaEnum.Documento || mediaToEdit.tipo === MediaEnum.Imagem) {
      return (
        <Button
          variant="contained"
          startIcon={<HiOutlineDocumentDownload />}
          onClick={() => onClickDownloadButton(mediaToEdit)}
        >
          {isDownloadingMedia ? (
            <CircularProgress size="24.5px" style={{ color: 'white' }} />
          ) : (
            <>{t('media_page.components.edit_media_form.download_button')}</>
          )}
        </Button>
      );
    }
    if (mediaToEdit.tipo === MediaEnum.Áudio) {
      return (
        <Button variant="contained" startIcon={<MdAudiotrack />} onClick={() => setIsListeningAudio(!isListeningAudio)}>
          {isListeningAudio
            ? t('media_page.components.edit_media_form.stop_listening_audio_button')
            : t('media_page.components.edit_media_form.listen_audio_button')}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        startIcon={<HiOutlineVideoCamera />}
        onClick={() => setIsWatchingPreviewVideo(!isWatchingPreviewVideo)}
      >
        {isWatchingPreviewVideo
          ? t('media_page.components.edit_media_form.stop_watching_video_button')
          : t('media_page.components.edit_media_form.watch_video_button')}
      </Button>
    );
  }

  function returnPlayer() {
    if (mediaToEdit.stream) {
      if (mediaToEdit.tipo === MediaEnum.YouTube) {
        return (
          <iframe
            width={640}
            height={430}
            className={styles.iframe}
            src={mediaToEdit.stream.url}
            title={`Player de vídeo da media ${mediaToEdit.nome}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        );
      }
      return (
        <iframe
          height={430}
          width={640}
          className={styles.iframe}
          src={`${config.publicUrl}/azurevideoplayer.html?videoSrc=${mediaToEdit.stream.url}&baseUrl=${config.publicUrl}`}
          title={`Player de vídeo da media ${mediaToEdit.nome}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      );
    }
    if (mediaToEdit.tipo === MediaEnum.Áudio) {
      return <AudioPlayer media={mediaToEdit} />;
    }
    return (
      <span className={styles.noVideoContainer}>
        <FiVideoOff size={128} />
        <p>{t('media_page.components.edit_media_form.video_not_available')}</p>
      </span>
    );
  }

  function handleMediaTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedMediaType((e.target as HTMLInputElement).value);

    if (e.target.value === 'youtube') {
      setValue('tipo', MediaEnum.YouTube);
      unregister('media');
      setSelectedFileName('');
    } else {
      unregister('tipo');
      unregister('youtubeLink');
    }
  }

  function returnMainBody() {
    if (isWatchingPreviewVideo || isListeningAudio) {
      return (
        <>
          <div className={styles.iframeWrapper}>{returnPlayer()}</div>
          <div className={styles.previewMediaButton}>{returnFileButton()}</div>
        </>
      );
    }
    return (
      <>
        <Input
          label={t('media_page.components.add_new_media_form.name_input_label')}
          {...register('nome')}
          error={errors.nome}
        />

        <Controller
          control={control}
          name="descricao"
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error} component="fieldset">
              <FormLabel htmlFor="descricao">
                {t('media_page.components.add_new_media_form.description_input_label')}
              </FormLabel>
              <SimpleMdeReact
                value={field.value}
                onChange={field.onChange}
                options={descriptionMarkdownEditorOptions}
              />
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        {isOptionsShowing && (
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="selecao-tipo-media"
              value={selectedMediaType}
              onChange={(e) => handleMediaTypeChange(e)}
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label={t('media_page.components.add_new_media_form.local_file_type')}
              />
              <FormControlLabel
                value="youtube"
                control={<Radio />}
                label={t('media_page.components.add_new_media_form.youtube_video_type')}
              />
            </RadioGroup>
          </FormControl>
        )}

        {selectedMediaType === 'local' && isOptionsShowing && (
          <FormControl fullWidth error={!!errors.media} component="fieldset" className={styles.fileUploadContainer}>
            <FormLabel htmlFor="mediaFile">Media</FormLabel>
            <div className={styles.inputContainer}>
              <Input
                {...register('media')}
                value={selectedFileName}
                name="mediaName"
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button variant="contained" component="label" className={styles.mediaButton}>
                + Media
                <input type="file" hidden onChange={(e) => handleFileUpload(e)} id="mediaFile" />
              </Button>
            </div>
            {!!errors.media && <FormHelperText error={!!errors.media}>{errors.media?.message}</FormHelperText>}
          </FormControl>
        )}

        {selectedMediaType === 'youtube' && isOptionsShowing && (
          <Input type="url" {...register('youtubeLink')} name="youtubeLink" error={errors.youtubeLink} label="Link" />
        )}

        <div className={styles.previewEditMediaButtons}>
          {returnFileButton()}
          {!isOptionsShowing && (
            <Button variant="contained" onClick={() => setIsOptionsShowing(!isOptionsShowing)}>
              {t('media_page.components.edit_media_form.edit_media_content')}
            </Button>
          )}
        </div>

        <FormControlLabel
          control={<Checkbox {...register('ativo')} checked={watchAtivo} />}
          label={t('media_page.components.add_new_media_form.active_media_checkbox_label')}
        />
        {isPdfModalOpen && (
          <PDFViewerModal isOpen={isPdfModalOpen} onClose={handleClosePdfModal} pdfFileContent={pdfFileContent} />
        )}
      </>
    );
  }

  return <form className={styles.container}>{isEditingMedia ? <LoadIndicator /> : returnMainBody()}</form>;
}
