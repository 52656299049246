import { Button } from '@mui/material';
import { MdAudiotrack } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../../hooks/useModal';
import { MediaData } from '../../../../interfaces/Media';
import { AudioModal } from '../../../../components/AudioModal';
import { UserActivity } from '../../../../interfaces/User';
import { UserActivityEnum } from '../../../../enums/User';
import { api } from '../../../../services/api';

interface ListenAudioButtonProps {
  media: MediaData;
  title: string;
  onUpdateAudioMessage?: () => void;
}

export function ListenAudioButton({ media, title, onUpdateAudioMessage }: ListenAudioButtonProps) {
  const [isMediaAudioModalOpen, handleOpenMediaAudioModal, handleCloseMediaAudioModal] = useModal();

  const { t } = useTranslation();

  async function onAudioStarted() {
    const userActivity: UserActivity = {
      idMedia: media.idMedia || 0,
      tipo: UserActivityEnum.MediaIniciada,
    };
    await api.post('/usuario/media', userActivity);
    if (onUpdateAudioMessage) onUpdateAudioMessage();
  }

  async function onAudioEnded() {
    const userActivity: UserActivity = {
      idMedia: media.idMedia || 0,
      tipo: UserActivityEnum.MediaCompletada,
    };
    await api.post('/usuario/media', userActivity);
    if (onUpdateAudioMessage) onUpdateAudioMessage();
  }

  return (
    <>
      <Button variant="contained" startIcon={<MdAudiotrack />} onClick={handleOpenMediaAudioModal}>
        {t('content_page.components.listen_audio_button.listen_button')}
      </Button>
      {isMediaAudioModalOpen && (
        <AudioModal
          isOpen={isMediaAudioModalOpen}
          onClose={handleCloseMediaAudioModal}
          media={media}
          modalTitle={title}
          onAudioEnded={() => onAudioEnded()}
          onAudioStarted={() => onAudioStarted()}
        />
      )}
    </>
  );
}
