import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserPostBody } from '../../../../interfaces/User';
import { Input } from '../../../../components/Input';
import styles from './styles.module.scss';
import { useUsersContext } from '../../../../context/UsersContext';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { ShowUser } from '../../../../enums/ShowUser';
import { ShowLanguage } from '../../../../enums/ShowLanguage';

export function EditUserForm() {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<UserPostBody>();

  const { t } = useTranslation();

  const { isEditingUser } = useUsersContext();

  const isAtivo = watch('ativo');
  const idioma = watch('idioma');
  const tipo = watch('tipo');

  return isEditingUser ? (
    <div className={styles.loadContainer}>
      <LoadIndicator />
    </div>
  ) : (
    <div className={styles.formContainer}>
      <Input
        type="text"
        label={t('users_page.components.add_new_user_form.first_name_input_label')}
        {...register('primeiroNome')}
        error={errors.primeiroNome}
      />
      <Input
        type="text"
        label={t('users_page.components.add_new_user_form.last_name_input_label')}
        {...register('ultimoNome')}
        error={errors.ultimoNome}
      />
      <Input
        type="text"
        label={t('users_page.components.add_new_user_form.email_input_label')}
        {...register('email')}
        error={errors.email}
      />
      <FormControl fullWidth>
        <FormLabel htmlFor="idioma">{t('users_page.components.add_new_user_form.language_input_label')}</FormLabel>
        <Select id="idioma" size="small" value={idioma} {...register('idioma')}>
          {Object.keys(ShowLanguage).map((lang) => (
            <MenuItem value={lang} key={lang}>
              {t(ShowLanguage[lang])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel htmlFor="tipo">{t('users_page.components.add_new_user_form.user_type_input_label')}</FormLabel>
        <Select id="tipo" {...register('tipo')} size="small" value={tipo}>
          {Object.keys(ShowUser).map((type) => (
            <MenuItem value={type} key={type}>
              {t(ShowUser[type])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Checkbox {...register('ativo')} checked={isAtivo} />}
        label={t('users_page.components.add_new_user_form.active_user_checkbox_label')}
      />
    </div>
  );
}
