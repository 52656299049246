import {
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import { DeleteAlertModal } from '../../../../components/DeleteAlertModal';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useMediasContext } from '../../../../context/MediasContext';
import { useModal } from '../../../../hooks/useModal';
import { MediaData, MediaParamsToOrderBy } from '../../../../interfaces/Media';
import { EditMediaModal } from '../EditMediaModal';
import { WatchVideoButton } from '../WatchVideoButton';
import styles from './styles.module.scss';
import { MediaEnum } from '../../../../enums/Media';
import { DownloadButton } from '../DownloadButton';
import { useAuthContext } from '../../../../context/AuthContext';
import { ListenAudioButton } from '../ListenAudioButton';
import { TableSortProps, useTableSorting } from '../../../../hooks/useTableSorting';
import { ShowMediaEnum } from '../../../../enums/ShowMedia';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function getStatusCellStyles(isActive: boolean) {
  return isActive ? styles.activeBox : styles.inactiveBox;
}

interface MediasTableProps extends TableSortProps<MediaParamsToOrderBy> {}

export function MediasTable({ onTableSort }: MediasTableProps) {
  const [mediaToEdit, setMediaToEdit] = useState<MediaData | null>(null);
  const [mediaToDelete, setMediaToDelete] = useState<MediaData | null>(null);
  const { setPage, pageSize, getMedias, medias, isFetchingMedias, deleteMedia, isDeletingMedia } = useMediasContext();

  const { t } = useTranslation();

  const [isEditMediaModalOpen, handleOpenEditMediaModal, handleCloseEditMediaModal] = useModal();
  const [isDeleteMediaModalOpen, handleOpenDeleteMediaModal, handleCloseDeleteMediaModal] = useModal();

  const { currentUser } = useAuthContext();

  function onOpenEditMediaModal(media: MediaData) {
    setMediaToEdit(media);
    handleOpenEditMediaModal();
  }

  const onCloseEditMediaModal = () => {
    setMediaToEdit(null);
    handleCloseEditMediaModal();
  };

  function onOpenDeleteMediaModal(media: MediaData) {
    setMediaToDelete(media);
    handleOpenDeleteMediaModal();
  }

  const onCloseDeleteMediaModal = () => {
    setMediaToDelete(null);
    handleCloseDeleteMediaModal();
  };

  async function handleDeleteMedia(media: MediaData) {
    await deleteMedia(media);
    handleCloseDeleteMediaModal();
    await getMedias({ page: 1, pageSize });
    setPage(1);
  }

  function returnMediaButton(media: MediaData) {
    if (media.tipo === MediaEnum.Vídeo || media.tipo === MediaEnum.YouTube) {
      return <WatchVideoButton media={media} />;
    }
    if (media.tipo === MediaEnum.Áudio) {
      return <ListenAudioButton media={media} />;
    }
    return <DownloadButton media={media} />;
  }

  const orderButton = useTableSorting<MediaParamsToOrderBy>(onTableSort);

  if (isFetchingMedias) return <LoadIndicator />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width={160} className={styles.tableHeaderCell}>
                {t('media_page.components.medias_table.table.action_header')}
              </StyledTableCell>
              <StyledTableCell align="left" className={styles.tableHeaderCell}>
                {orderButton('nome')}
                {t('media_page.components.medias_table.table.media_name_header')}
              </StyledTableCell>
              <StyledTableCell align="left" className={styles.tableHeaderCell}>
                {orderButton('tipo')}
                {t('media_page.components.medias_table.table.media_type_header')}
              </StyledTableCell>
              <StyledTableCell align="left" className={styles.tableHeaderCell}>
                {orderButton('dataRegistro')}
                {t('media_page.components.medias_table.table.register_date_header')}
              </StyledTableCell>
              <StyledTableCell align="left" width={20} className={styles.tableHeaderCell}>
                {orderButton('ativo')}
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medias.map((media) => (
              <StyledTableRow key={media.idMedia} className={styles.tableRow}>
                <TableCell className={styles.tableBodyCell} width={160}>
                  <IconButton
                    color="primary"
                    onClick={() => onOpenEditMediaModal(media)}
                    aria-label={t('media_page.components.medias_table.table_body.edit_media_button_aria_label')}
                    title={t('media_page.components.medias_table.table_body.edit_media_button_title')}
                  >
                    <HiOutlinePencilAlt />
                  </IconButton>
                  {returnMediaButton(media)}
                  <IconButton
                    color="inherit"
                    aria-label={t('media_page.components.medias_table.table_body.delete_media_button_aria_label')}
                    title={t('media_page.components.medias_table.table_body.delete_media_button_title')}
                    disabled={isDeletingMedia}
                    onClick={() => onOpenDeleteMediaModal(media)}
                  >
                    <HiOutlineTrash />
                  </IconButton>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>{media.nome}</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {media.tipo ? t(ShowMediaEnum[media.tipo || 0]) : ''}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {new Date(media.dataRegistro || '').toLocaleDateString(currentUser?.idioma)}
                </TableCell>
                <TableCell width={20} className={styles.tableBodyCell}>
                  <div className={getStatusCellStyles(media.ativo || false)}>{media.ativo ? 'Active' : 'Inactive'}</div>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {mediaToEdit && (
        <EditMediaModal isOpen={isEditMediaModalOpen} onClose={onCloseEditMediaModal} mediaToEdit={mediaToEdit} />
      )}
      {mediaToDelete && (
        <DeleteAlertModal
          onDeleteCallback={() => handleDeleteMedia(mediaToDelete)}
          isDeleting={isDeletingMedia}
          isOpen={isDeleteMediaModalOpen}
          onClose={onCloseDeleteMediaModal}
          title={t('media_page.components.medias_table.delete_modal.title')}
          message={t('media_page.components.medias_table.delete_modal.message', { mediaName: mediaToDelete.nome })}
        />
      )}
    </>
  );
}
