import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkPlay, BsBookmark, BsBox, BsPeople, BsClipboardData } from 'react-icons/bs';
import styles from './styles.module.scss';
import { NavLink } from './components/NavLink';
import { ModalProps } from '../../../BaseModal';

interface SidebarNavProps extends ModalProps {}

export function SidebarNav({ isOpen, onClose }: SidebarNavProps) {
  const { t } = useTranslation();

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <div className={styles.navContainer}>
        <nav className={styles.menuItems}>
          <NavLink to="/usermanagement">
            <BsPeople />
            {t('components.sidebar_nav.users')}
          </NavLink>
          <NavLink to="/medias">
            <BsFileEarmarkPlay />
            {t('components.sidebar_nav.medias')}
          </NavLink>
          <NavLink to="/contents">
            <BsBookmark />
            {t('components.sidebar_nav.contents')}
          </NavLink>
          <NavLink to="/categories">
            <BsBox />
            {t('components.sidebar_nav.categories')}
          </NavLink>
          <NavLink to="/reports">
            <BsClipboardData />
            {t('components.sidebar_nav.reports')}
          </NavLink>
        </nav>
      </div>
    </Drawer>
  );
}
