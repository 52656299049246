import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import config from './config/config';
import { AuthContextProvider } from './context/AuthContext';
import { AxiosInterceptor } from './services/AxiosInterceptor';

export function App() {
  return (
    <BrowserRouter basename={config.publicUrl}>
      <AuthContextProvider>
        <AxiosInterceptor>
          <Router />
        </AxiosInterceptor>
      </AuthContextProvider>
    </BrowserRouter>
  );
}
