import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import styles from './styles.module.scss';

export function ContentSkeleton() {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.introductionSkeleton}>
        <Skeleton variant="rounded" width="100%" height={500} animation="wave" />
        <div className={styles.contentIntroductionBodySkeleton}>
          <div className={styles.introductionHeadingSkeleton}>
            <Skeleton variant="text" width="50%" animation="wave" />
            <Skeleton variant="text" width="100%" animation="wave" />
          </div>
          <div className={styles.introductionInfosSkeleton}>
            <Skeleton variant="rounded" width="100%" height="40%" animation="wave" />
            <Skeleton variant="text" width="50%" animation="wave" />
            <Skeleton variant="text" width="50%" animation="wave" />
            <Skeleton variant="text" width="50%" animation="wave" />
          </div>
          <div className={styles.introductionActionsSkeleton}>
            <Skeleton variant="rounded" width="40%" height="100%" animation="wave" />
            <Skeleton variant="rounded" width="40%" height="100%" animation="wave" />
          </div>
        </div>
      </div>
      <div className={styles.sectionsContainerSkeleton}>
        <h1>{t('content_page.index.sections_title')}</h1>
        <Skeleton variant="rounded" width="100%" height={100} animation="wave" />
        <Skeleton variant="rounded" width="100%" height={100} animation="wave" />
        <Skeleton variant="rounded" width="100%" height={100} animation="wave" />
      </div>
    </section>
  );
}
