import { useEffect, useState } from 'react';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MediaList } from './components/MediasList';
import styles from './styles.module.scss';
import { MediaData } from '../../../../../../interfaces/Media';
import { useContentsContext } from '../../../../../../context/ContentsContext';
import { LoadIndicator } from '../../../../../../components/LoadIndicator';
import { ContentPutBody } from '../../../../../../interfaces/Content';

function not(a: MediaData[], b: MediaData[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: MediaData[], b: MediaData[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface MediasModalProps {
  isFetchingContentMedias: boolean;
}

export function MediasModal({ isFetchingContentMedias }: MediasModalProps) {
  const { medias, isFetchingMedias } = useContentsContext();

  const { setValue, getValues } = useFormContext<ContentPutBody>();

  const { t } = useTranslation();

  const [checked, setChecked] = useState<MediaData[]>([]);
  const [left, setLeft] = useState<MediaData[]>([]);
  const [right, setRight] = useState<MediaData[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  function handleInit() {
    const leftData = medias.filter((media) => !getValues('medias').includes(media.idMedia || 0));
    setRight(medias.filter((media) => !leftData.includes(media)));
    setLeft(leftData);
  }

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    const data: number[] = [];

    for (let i = 0; i < left.length; i += 1) {
      data.push(left[i].idMedia || 0);
    }
    setValue('medias', getValues('medias').concat(data));
    setValue('ordemMedias', getValues('ordemMedias').concat(data));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    const data: number[] = [];

    for (let i = 0; i < leftChecked.length; i += 1) {
      data.push(leftChecked[i].idMedia || 0);
    }
    setValue('medias', getValues('medias').concat(data));
    setValue('ordemMedias', getValues('ordemMedias').concat(data));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    const data: number[] = [];

    for (let i = 0; i < rightChecked.length; i += 1) {
      data.push(rightChecked[i].idMedia || 0);
    }
    setValue(
      'medias',
      getValues('medias').filter((n) => !data.includes(n))
    );
    setValue(
      'ordemMedias',
      getValues('ordemMedias').filter((n) => !data.includes(n))
    );
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    const data: number[] = [];

    for (let i = 0; i < right.length; i += 1) {
      data.push(right[i].idMedia || 0);
    }
    setValue(
      'medias',
      getValues('medias').filter((n) => !data.includes(n))
    );
    setValue(
      'ordemMedias',
      getValues('ordemMedias').filter((n) => !data.includes(n))
    );
  };

  const handleToggle = (value: MediaData) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (!isFetchingContentMedias && !isFetchingMedias) {
      handleInit();
    }
  }, [isFetchingContentMedias, isFetchingMedias]);

  if (isFetchingContentMedias || isFetchingMedias) {
    return <LoadIndicator />;
  }

  return (
    <div className={styles.container}>
      <MediaList
        title={t('contents_page.components.edit_content_modal.medias_tab_title_available')}
        data={left}
        setData={setLeft}
        handleToggle={handleToggle}
        checked={checked}
        selectedMediaIds={getValues('medias')}
        allMedias={medias}
        side="left"
      />
      <div className={styles.buttonsContainer}>
        <Button className={styles.button} onClick={handleCheckedRight} color="primary" variant="contained">
          <SlArrowRight />
        </Button>
        <Button className={styles.button} onClick={handleAllRight} color="primary" variant="contained">
          <RxDoubleArrowRight />
        </Button>
        <Button className={styles.button} onClick={handleCheckedLeft} color="primary" variant="contained">
          <SlArrowLeft />
        </Button>
        <Button className={styles.button} onClick={handleAllLeft} color="primary" variant="contained">
          <RxDoubleArrowLeft />
        </Button>
      </div>
      <MediaList
        title={t('contents_page.components.edit_content_modal.medias_tab_title_selected')}
        data={right}
        setData={setRight}
        handleToggle={handleToggle}
        checked={checked}
        selectedMediaIds={getValues('medias')}
        allMedias={medias}
        side="right"
      />
    </div>
  );
}
