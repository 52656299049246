import { Skeleton } from '@mui/material';
import styles from './styles.module.scss';

export function ContentCardSkeleton() {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContent}>
        <Skeleton variant="rounded" width="100%" height={160} animation="wave" />
        <div className={styles.courseName}>
          <Skeleton variant="text" width="80%" animation="wave" />
        </div>
        <div className={styles.courseDescription}>
          <Skeleton variant="text" width="80%" animation="wave" />
          <Skeleton variant="text" width="80%" animation="wave" />
          <Skeleton variant="text" width="80%" animation="wave" />
          <Skeleton variant="text" width="80%" animation="wave" />
        </div>
      </div>
    </div>
  );
}
