import { createContext, ReactNode, useContext, useState } from 'react';
import { Metadata } from '../interfaces/ApiResponse';
import {
  ContentData,
  ContentPutBody,
  ContentPostBody,
  FetchContentParamsType,
  ContentParamsToOrderBy,
} from '../interfaces/Content';
import {
  useCreateContent,
  useDeleteContent,
  useEditContent,
  useFetchContents,
  useFetchUserContents,
} from '../hooks/useContent';
import { useFetchMedias } from '../hooks/useMedia';
import { FetchMediaParamsType, MediaData } from '../interfaces/Media';

type ContentsContextType = {
  page: number;
  pageSize: number;
  setPage: (value: number) => void;
  orderBy: keyof ContentParamsToOrderBy | '';
  setOrderBy: (value: keyof ContentParamsToOrderBy | '') => void;
  order: 'Asc' | 'Desc' | '';
  setOrder: (value: 'Asc' | 'Desc' | '') => void;
  contents: ContentData[];
  getContents: ({
    page,
    pageSize,
    categoryId,
    nome,
    itemInList,
    ativo,
    order,
    orderBy,
    catAtiva,
  }: FetchContentParamsType) => Promise<void>;
  isFetchingContents: boolean;
  metadata: Metadata;
  createContent: (content: ContentPostBody) => Promise<ContentData>;
  isCreatingContent: boolean;
  editContent: (content: ContentPutBody) => Promise<ContentData>;
  isEditingContent: boolean;
  userContents: ContentData[];
  getUserContents: () => Promise<void>;
  isFetchingUserContents: boolean;
  deleteContent: (content: ContentData) => Promise<void>;
  isDeletingContent: boolean;
  getMedias: ({ page, pageSize, ativo, nome }: FetchMediaParamsType) => Promise<void>;
  medias: MediaData[];
  isFetchingMedias: boolean;
};

const ContentsContext = createContext({} as ContentsContextType);

interface ContentsContextProviderProps {
  children: ReactNode;
}

export function ContentsContextProvider({ children }: ContentsContextProviderProps) {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof ContentParamsToOrderBy | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');
  const { contents, getContents, isFetchingContents, metadata } = useFetchContents();
  const { createContent, isCreatingContent } = useCreateContent();
  const { editContent, isEditingContent } = useEditContent();
  const { userContents, getUserContents, isFetchingUserContents } = useFetchUserContents();
  const { medias, getMedias, isFetchingMedias } = useFetchMedias();
  const { deleteContent, isDeletingContent } = useDeleteContent();

  return (
    <ContentsContext.Provider
      value={{
        page,
        pageSize,
        setPage,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        contents,
        getContents,
        metadata,
        isFetchingContents,
        createContent,
        isCreatingContent,
        editContent,
        isEditingContent,
        deleteContent,
        isDeletingContent,
        userContents,
        getUserContents,
        isFetchingUserContents,
        medias,
        getMedias,
        isFetchingMedias,
      }}
    >
      {children}
    </ContentsContext.Provider>
  );
}

export function useContentsContext() {
  const contentsContext = useContext(ContentsContext);
  return contentsContext;
}
