import { createContext, ReactNode, useContext, useState } from 'react';
import { useCreateUser, useDeleteUser, useEditUser, useFetchUsers } from '../hooks/useUsers';
import { UserPostBody, FetchUserParams, UserData, UserPutBody, UserParamsToOrderBy } from '../interfaces/User';
import { Metadata } from '../interfaces/ApiResponse';

type UsersContextType = {
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  orderBy: keyof UserParamsToOrderBy | '';
  setOrderBy: (value: keyof UserParamsToOrderBy | '') => void;
  order: 'Asc' | 'Desc' | '';
  setOrder: (value: 'Asc' | 'Desc' | '') => void;
  metadata: Metadata;
  users: UserData[];
  getUsers: ({
    page,
    pageSize,
    primeiroNome,
    ultimoNome,
    email,
    ativo,
    order,
    orderBy,
  }: FetchUserParams) => Promise<void>;
  isFetchingUsers: boolean;
  createUser: (user: UserPostBody) => Promise<UserData>;
  isCreatingUser: boolean;
  editUser: (user: UserPutBody) => Promise<UserData>;
  isEditingUser: boolean;
  deleteUser: (user: UserData) => Promise<void>;
  isDeletingUser: boolean;
};

const UsersContext = createContext({} as UsersContextType);

interface UsersContextProviderProps {
  children: ReactNode;
}

export function UsersContextProvider({ children }: UsersContextProviderProps) {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof UserParamsToOrderBy | ''>('');
  const [order, setOrder] = useState<'Asc' | 'Desc' | ''>('');
  const { users, getUsers, isFetchingUsers, metadata } = useFetchUsers();
  const { createUser, isCreatingUser } = useCreateUser();
  const { editUser, isEditingUser } = useEditUser();
  const { deleteUser, isDeletingUser } = useDeleteUser();

  return (
    <UsersContext.Provider
      value={{
        page,
        pageSize,
        setPage,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        users,
        getUsers,
        isFetchingUsers,
        metadata,
        createUser,
        isCreatingUser,
        editUser,
        isEditingUser,
        deleteUser,
        isDeletingUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export function useUsersContext() {
  const usersContext = useContext(UsersContext);
  return usersContext;
}
