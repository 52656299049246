import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { AiOutlineFullscreen, AiOutlineFullscreenExit, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseModal, ModalProps } from '../BaseModal';
import styles from './styles.module.scss';
import { UserActivity } from '../../interfaces/User';
import { UserActivityEnum } from '../../enums/User';
import { api } from '../../services/api';
import { LoadIndicator } from '../LoadIndicator';

export interface ReturnFileCallBackProps {
  file: File;
  mediaName: string;
  mediaId: number;
}

export interface PDFViewerModalProps extends ModalProps {
  pdfFileContent: ReturnFileCallBackProps;
  onUpdateDocumentRead?: () => void;
}

export function PDFViewerModal({ isOpen, onClose, pdfFileContent, onUpdateDocumentRead }: PDFViewerModalProps) {
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const { t } = useTranslation();

  async function checkIfCompleted(actualPage: number, totPages: number) {
    if (Math.floor((actualPage / totPages) * 100) >= 90) {
      const userActivity: UserActivity = {
        idMedia: pdfFileContent.mediaId,
        tipo: UserActivityEnum.MediaCompletada,
      };

      await api.post('/usuario/media', userActivity);
      if (onUpdateDocumentRead) onUpdateDocumentRead();
    }
  }

  function onDocumentLoadSuccess({ numPages }: any) {
    setTotalPages(numPages);
    setPageNumber(1);
    checkIfCompleted(1, numPages);
    setLoadingPage(false);
  }

  async function changePage(offset: number) {
    const currentPage = pageNumber + offset;
    setPageNumber(currentPage);
    checkIfCompleted(currentPage, totalPages);
  }

  function changeZoom(offset: number) {
    setZoom((prevZoom) => prevZoom + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function zoomIn() {
    changeZoom(0.2);
  }

  function zoomOut() {
    changeZoom(-0.2);
  }

  return (
    <BaseModal title={pdfFileContent.mediaName} onClose={onClose} isOpen={isOpen} fullscreen={isFullscreen}>
      <div className={loadingPage ? styles.pdfViewerLoading : styles.pdfViewer}>
        <span className={styles.documentControls}>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => previousPage()}
            title={t('components.pdf_viewer_modal.prev_page')}
          >
            <BiLeftArrow />
          </IconButton>
          <p style={{ margin: '0 1rem' }}>
            {t('components.pdf_viewer_modal.page_text')} {pageNumber || (totalPages ? 1 : '--')}{' '}
            {t('components.pdf_viewer_modal.of_text')} {totalPages || '--'}
          </p>
          <IconButton
            disabled={pageNumber >= totalPages}
            onClick={() => nextPage()}
            title={t('components.pdf_viewer_modal.next_page')}
          >
            <BiRightArrow />
          </IconButton>

          <IconButton onClick={() => setIsFullscreen((o) => !o)}>
            {isFullscreen ? (
              <AiOutlineFullscreenExit title={t('components.pdf_viewer_modal.exit_fs')} />
            ) : (
              <AiOutlineFullscreen title={t('components.pdf_viewer_modal.enter_fs')} />
            )}
          </IconButton>
          <IconButton disabled={zoom >= 2} onClick={() => zoomIn()} title={t('components.pdf_viewer_modal.amp_zoom')}>
            <AiOutlinePlus />
          </IconButton>
          <IconButton disabled={zoom <= 1} onClick={() => zoomOut()} title={t('components.pdf_viewer_modal.red_zoom')}>
            <AiOutlineMinus />
          </IconButton>
        </span>
        <div className={loadingPage ? styles.pdfDocumentLoading : styles.pdfDocument}>
          <Document
            file={pdfFileContent.file}
            onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
            loading={
              <div className={styles.pdfPageLoadingContainer}>
                <LoadIndicator />
              </div>
            }
            error={t('components.pdf_viewer_modal.error_loading_pdf')}
          >
            <Page
              error={t('components.pdf_viewer_modal.error_loading_page')}
              loading={
                <div className={styles.pdfPageLoadingContainer}>
                  <LoadIndicator />
                </div>
              }
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              scale={zoom}
              onLoadSuccess={() => setLoadingPage(false)}
              onChange={() => setLoadingPage(true)}
              width={1120}
              height={480}
            />
          </Document>
        </div>
      </div>
    </BaseModal>
  );
}
