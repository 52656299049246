import { useFormContext, Controller } from 'react-hook-form';
import { Button, FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import SimpleMdeReact from 'react-simplemde-editor';
import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import EasyMDE from 'easymde';
import { Input } from '../../../../components/Input';
import styles from './styles.module.scss';
import { MediaPostBody } from '../../../../interfaces/Media';
import ReactMarkdownShow from '../../../../components/ReactMarkdownShow/ReactMarkdownShow';
import { MediaEnum } from '../../../../enums/Media';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useMediasContext } from '../../../../context/MediasContext';

export function AddNewMediaForm() {
  const [selectedMediaType, setSelectedMediaType] = useState<string>('local');
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const { t } = useTranslation();

  const { isCreatingMedia } = useMediasContext();

  const {
    register,
    control,
    setValue,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext<MediaPostBody>();

  const watchAtivo = watch('ativo');

  const descriptionMarkdownEditorOptions = useMemo(
    () =>
      ({
        maxHeight: '200px',
        spellChecker: false,
        nativeSpellcheck: true,
        toolbar: [
          {
            name: 'bold',
            action: EasyMDE.toggleBold,
            className: 'fa fa-bold',
            title: 'Negrito',
          },
          {
            name: 'italic',
            action: EasyMDE.toggleItalic,
            className: 'fa fa-italic',
            title: 'Itálico',
          },
          {
            name: 'ordered-list',
            action: EasyMDE.toggleOrderedList,
            className: 'fa fa-list-ol',
            title: 'Inserir Lista Ordenada',
          },
          {
            name: 'unordered-list',
            action: EasyMDE.toggleUnorderedList,
            className: 'fa fa-list-ul',
            title: 'Inserir Lista Não Ordenada',
          },
          {
            name: 'heading',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Cabeçalho',
          },
          {
            name: 'heading-smaller',
            action: EasyMDE.toggleHeadingSmaller,
            className: 'fa fa-header',
            title: 'Diminuir Cabeçalho',
          },
          {
            name: 'heading-bigger',
            action: EasyMDE.toggleHeadingBigger,
            className: 'fa fa-header',
            title: 'Aumentar Cabeçalho',
          },
          {
            name: 'quote',
            action: EasyMDE.toggleBlockquote,
            className: 'fa fa-quote-left',
            title: 'Inserir Citação',
          },
          {
            name: 'horizontal-rule',
            action: EasyMDE.drawHorizontalRule,
            className: 'fa fa-minus',
            title: 'Inserir Linha Horizontal',
          },
          {
            name: 'upload-image',
            action: EasyMDE.drawImage,
            className: 'fa fa-picture-o',
            title: 'Inserir Link de Imagem',
          },
          {
            name: 'link',
            action: EasyMDE.drawLink,
            className: 'fa fa-link',
            title: 'Inserir Link',
          },
          {
            name: 'table',
            action: EasyMDE.drawTable,
            className: 'fa fa-table',
            title: 'Inserir Tabela',
          },
          {
            name: 'code',
            action: EasyMDE.toggleCodeBlock,
            className: 'fa fa-code',
            title: 'Inserir Código',
          },
          {
            name: 'preview',
            action: EasyMDE.togglePreview,
            className: 'fa fa-eye no-disable',
            title: 'Visualizar',
          },
          {
            name: 'side-by-side',
            action: EasyMDE.toggleSideBySide,
            className: 'fa fa-columns no-disable no-mobile',
            title: 'Lado a Lado',
          },
          {
            name: 'fullscreen',
            action: EasyMDE.toggleFullScreen,
            className: 'fa fa-arrows-alt no-disable no-mobile',
            title: 'Tela Cheia',
          },
          {
            name: 'guide',
            action: () => {
              window.open('https://www.markdownguide.org/basic-syntax/');
            },
            className: 'fa fa-question-circle',
            title: 'Guia Markdown',
          },
        ],
        previewRender: (plainText) => ReactDOMServer.renderToString(<ReactMarkdownShow>{plainText}</ReactMarkdownShow>),
      } as EasyMDE.Options),
    []
  );

  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setValue('media', selectedFile);
      setSelectedFileName(selectedFile.name);

      if (selectedFile.type.startsWith('image')) {
        setValue('tipo', MediaEnum.Imagem);
      } else if (selectedFile.type.startsWith('video')) {
        setValue('tipo', MediaEnum.Vídeo);
      } else if (selectedFile.type.startsWith('audio')) {
        setValue('tipo', MediaEnum.Áudio);
      } else {
        setValue('tipo', MediaEnum.Documento);
      }
    }
  }

  function handleMediaTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedMediaType((e.target as HTMLInputElement).value);

    if (e.target.value === 'youtube') {
      setValue('tipo', MediaEnum.YouTube);
      unregister('media');
      setSelectedFileName('');
    } else {
      unregister('tipo');
      unregister('youtubeLink');
    }
  }

  return (
    <form className={styles.container}>
      {isCreatingMedia ? (
        <LoadIndicator />
      ) : (
        <>
          <Input
            label={t('media_page.components.add_new_media_form.name_input_label')}
            {...register('nome')}
            error={errors.nome}
          />
          <Controller
            control={control}
            name="descricao"
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error} component="fieldset">
                <FormLabel htmlFor="descricao">
                  {t('media_page.components.add_new_media_form.description_input_label')}
                </FormLabel>
                <SimpleMdeReact
                  value={field.value}
                  onChange={field.onChange}
                  options={descriptionMarkdownEditorOptions}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="selecao-tipo-media"
              value={selectedMediaType}
              onChange={(e) => handleMediaTypeChange(e)}
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label={t('media_page.components.add_new_media_form.local_file_type')}
              />
              <FormControlLabel
                value="youtube"
                control={<Radio />}
                label={t('media_page.components.add_new_media_form.youtube_video_type')}
              />
            </RadioGroup>
          </FormControl>
          {selectedMediaType === 'local' ? (
            <FormControl fullWidth error={!!errors.media} component="fieldset" className={styles.fileUploadContainer}>
              <FormLabel htmlFor="mediaFile">Media</FormLabel>
              <div className={styles.inputContainer}>
                <Input
                  {...register('media')}
                  value={selectedFileName}
                  name="mediaName"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Button variant="contained" component="label" className={styles.mediaButton}>
                  + Media
                  <input type="file" hidden onChange={(e) => handleFileUpload(e)} id="mediaFile" />
                </Button>
              </div>
              {!!errors.media && <FormHelperText error={!!errors.media}>{errors.media?.message}</FormHelperText>}
            </FormControl>
          ) : (
            <Input type="url" {...register('youtubeLink')} name="youtubeLink" error={errors.youtubeLink} label="Link" />
          )}
          <FormControlLabel
            control={<Checkbox {...register('ativo')} checked={watchAtivo} />}
            label={t('media_page.components.add_new_media_form.active_media_checkbox_label')}
          />
        </>
      )}
    </form>
  );
}
