import { Button, FormControl, TextField } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../../../components/BaseModal';
import { UserData } from '../../../../interfaces/User';
import { SelectUserTable } from '../SelectUserTable';
import styles from './styles.module.scss';
import { useFetchUsers } from '../../../../hooks/useUsers';

export type SelectUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSaveSelection: (users: UserData[]) => void;
};

type UserFilter = {
  firstName: string;
  lastName: string;
  email: string;
};

export function SelectUserModal({ isOpen, onClose, handleSaveSelection }: SelectUserModalProps) {
  const [usersSelected, setUsersSelected] = useState<number[]>([]);
  const [filter, setFilter] = useState<UserFilter>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const { t } = useTranslation();

  const { getUsers, users } = useFetchUsers();

  useEffect(() => {
    getUsers({ page: 1, pageSize: 9999999 });
  }, []);

  const handleUserSelection = (userId: number, select: boolean) => {
    if (select) {
      setUsersSelected([...usersSelected, userId]);
    } else {
      const removed = usersSelected.filter((item) => item !== userId);
      setUsersSelected(removed);
    }
  };

  const handleUserSelectionAll = (select: boolean) => {
    if (select) {
      setUsersSelected(users.map((user) => user.idUsuario || 0));
    } else {
      setUsersSelected([]);
    }
  };

  const onSaveSelection = () => {
    handleSaveSelection(users.filter((user) => usersSelected.includes(user.idUsuario || 0)));
  };

  const onChangeFilter = (type: string, text: string) => {
    setFilter({ ...filter, [type]: text });
  };

  const filterUserList = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    await getUsers({
      page: 1,
      pageSize: 9999999,
      primeiroNome: filter.firstName,
      ultimoNome: filter.lastName,
      email: filter.email,
    });
  };

  return (
    <BaseModal
      onSaveCallback={onSaveSelection}
      showSaveButton
      title={t('report_page.components.select_user_modal.modal_title')}
      isOpen={isOpen}
      onClose={onClose}
      saveButtonText={t('report_page.components.select_user_modal.save_button')}
    >
      <div className={styles.modalContainer}>
        <form className={styles.formContainer} onSubmit={filterUserList}>
          <fieldset className={styles.inputContainer}>
            <FormControl size="small" fullWidth>
              <TextField
                label={t('users_page.components.users_table.table.first_name_header')}
                size="small"
                value={filter.firstName}
                onChange={({ target }) => onChangeFilter('firstName', target.value)}
              />
            </FormControl>
          </fieldset>

          <fieldset className={styles.inputContainer}>
            <FormControl size="small" fullWidth>
              <TextField
                label={t('users_page.components.users_table.table.last_name_header')}
                size="small"
                value={filter.lastName}
                onChange={({ target }) => onChangeFilter('lastName', target.value)}
              />
            </FormControl>
          </fieldset>

          <fieldset className={styles.inputContainer}>
            <FormControl size="small" fullWidth>
              <TextField
                label="E-mail"
                size="small"
                value={filter.email}
                onChange={({ target }) => onChangeFilter('email', target.value)}
              />
            </FormControl>
          </fieldset>

          <div className={styles.submitButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="small" className={styles.submitButton}>
              {t('report_page.components.select_user_modal.filter_button')}
            </Button>
          </div>
        </form>
        <div className={styles.tableContainer}>
          <SelectUserTable
            userList={users}
            handleSelection={handleUserSelection}
            handleSelectAll={handleUserSelectionAll}
            selected={usersSelected}
          />
        </div>
      </div>
    </BaseModal>
  );
}
