import { useState } from 'react';

type UseModalReturnResult = [boolean, () => void, () => void];

export function useModal(): UseModalReturnResult {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return [isModalOpen, handleOpenModal, handleCloseModal];
}
