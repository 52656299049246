import { Modal, Button, IconButton } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { LoadIndicator } from '../LoadIndicator';
import styles from './styles.module.scss';
import { ModalProps } from '../BaseModal';

interface DeleteAlertModalProps extends ModalProps {
  title: string;
  message: string;
  onDeleteCallback: () => void;
  isDeleting: boolean;
}

export function DeleteAlertModal({
  isOpen,
  onClose,
  title,
  message,
  onDeleteCallback,
  isDeleting,
}: DeleteAlertModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.modalBackground}>
        <Draggable handle="#modalHeaderDraggable">
          <div className={styles.modalContainer}>
            <div className={styles.modalHeading} id="modalHeaderDraggable">
              <h1>{title}</h1>
              <IconButton onClick={onClose} onTouchEnd={onClose}>
                <AiOutlineClose />
              </IconButton>
            </div>
            <div className={styles.content}>
              {isDeleting ? <LoadIndicator /> : <p className={styles.message}>{message}</p>}
            </div>
            <div className={styles.modalActions}>
              <Button color="error" variant="outlined" disabled={isDeleting} onClick={onClose}>
                {t('components.delete_alert_modal.no_button')}
              </Button>
              <Button disabled={isDeleting} color="primary" variant="contained" onClick={() => onDeleteCallback()}>
                {t('components.delete_alert_modal.yes_button')}
              </Button>
            </div>
          </div>
        </Draggable>
      </div>
    </Modal>
  );
}
