import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import TabContext from '@mui/lab/TabContext';
import { TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseModal, ModalProps } from '../../../../components/BaseModal';
import styles from './styles.module.scss';
import { DetailsModal } from './components/DetailsModal';
import { MediasModal } from './components/MediasModal';
import { useContentsContext } from '../../../../context/ContentsContext';
import { ContentData, ContentPutBody } from '../../../../interfaces/Content';
import { contentFormPut } from '../../utils/ContentsFormValidation';
import { useFetchContentMedias, useFetchContentUsers } from '../../../../hooks/useContent';
import { UsersModal } from './components/UsersModal';
import { useFetchUsers } from '../../../../hooks/useUsers';
import { OrderModal } from './components/OrderModal';

interface EditContentModalProps extends ModalProps {
  contentToEdit: ContentData;
}

export function EditContentModal({ isOpen, onClose, contentToEdit }: EditContentModalProps) {
  const { t } = useTranslation();

  const TABS_OPTIONS = {
    DETAILS: t('contents_page.components.edit_content_modal.details_tab'),
    USERS: t('contents_page.components.edit_content_modal.users_tab'),
    MEDIAS: t('contents_page.components.edit_content_modal.medias_tab'),
    ORDER: t('contents_page.components.edit_content_modal.order_tab'),
  };

  const [valueTab, setValueTab] = useState('1');

  const { editContent, getContents, setPage, pageSize, getMedias } = useContentsContext();
  const { contentMedias, getContentMedias, isFetchingContentMedias } = useFetchContentMedias();
  const { contentUsers, getContentUsers, isFetchingContentUsers } = useFetchContentUsers();
  const { getUsers, isFetchingUsers, users } = useFetchUsers();

  const editContentForm = useForm<ContentPutBody>({
    defaultValues: {
      idConteudo: contentToEdit.idConteudo,
      idCategoria: contentToEdit.categoria?.idCategoria,
      nome: contentToEdit.nome,
      descricao: contentToEdit.descricao,
      ativo: contentToEdit.ativo,
    },
    resolver: yupResolver(contentFormPut),
  });

  const { handleSubmit, reset, setValue } = editContentForm;

  function handleTabChange(e: React.SyntheticEvent, newValue: string) {
    setValueTab(newValue);
  }

  function onCloseModal() {
    reset();
    onClose();
  }

  async function handleEditContent(content: ContentPutBody) {
    const editedContent = await editContent(content);
    setPage(1);
    await getContents({ page: 1, pageSize, itemInList: editedContent });
    onClose();
  }

  useEffect(() => {
    getMedias({ page: 1, pageSize: 99999 });
    if (contentToEdit.idConteudo) {
      getContentMedias(contentToEdit.idConteudo);
      getContentUsers(contentToEdit.idConteudo);
    }
    getUsers({ page: 1, pageSize: 99999 });
  }, []);

  useEffect(() => {
    setValue(
      'medias',
      contentMedias.map((cm) => cm.idMedia || 0)
    );
    setValue(
      'ordemMedias',
      contentMedias.map((cm) => cm.idMedia || 0)
    );
    setValue(
      'usuarios',
      contentUsers.map((contUs) => contUs.idUsuario || 0)
    );
  }, [contentMedias, contentUsers]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => onCloseModal()}
      title={contentToEdit.nome || ''}
      showSaveButton
      onSaveCallback={handleSubmit(handleEditContent)}
    >
      <div className={styles.editContentContainer}>
        <TabContext value={valueTab}>
          <TabList onChange={(e, newValue) => handleTabChange(e, newValue)}>
            <Tab label={TABS_OPTIONS.DETAILS} value="1" className={styles.tabLabel} />
            <Tab label={TABS_OPTIONS.USERS} value="2" className={styles.tabLabel} />
            <Tab label={TABS_OPTIONS.MEDIAS} value="3" className={styles.tabLabel} />
            <Tab label={TABS_OPTIONS.ORDER} value="4" className={styles.tabLabel} />
          </TabList>
          <TabPanel value="1" className={styles.tabPanel}>
            <FormProvider {...editContentForm}>
              <DetailsModal contentToEdit={contentToEdit} />
            </FormProvider>
          </TabPanel>
          <TabPanel value="2" className={styles.tabPanel}>
            <FormProvider {...editContentForm}>
              <UsersModal
                users={users}
                isFetchingUsers={isFetchingUsers}
                isFetchingContentUsers={isFetchingContentUsers}
              />
            </FormProvider>
          </TabPanel>
          <TabPanel value="3" className={styles.tabPanel}>
            <FormProvider {...editContentForm}>
              <MediasModal isFetchingContentMedias={isFetchingContentMedias} />
            </FormProvider>
          </TabPanel>
          <TabPanel value="4" className={styles.tabPanel}>
            <FormProvider {...editContentForm}>
              <OrderModal isFetchingContentMedias={isFetchingContentMedias} />
            </FormProvider>
          </TabPanel>
        </TabContext>
      </div>
    </BaseModal>
  );
}
