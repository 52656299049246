import { IconButton } from '@mui/material';
import { HiPlay } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../../hooks/useModal';
import { MediaData } from '../../../../interfaces/Media';
import { VideoModal } from '../../../../components/VideoModal';
import { useThemeContext } from '../../../../context/ThemeContext';

interface WatchVideoButtonProps {
  media: MediaData;
}

export function WatchVideoButton({ media }: WatchVideoButtonProps) {
  const [isMediaVideoModalOpen, handleOpenMediaVideoModal, handleCloseMediaVideoModal] = useModal();
  const { selectedTheme } = useThemeContext();

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        style={selectedTheme === 'dark' ? { color: '#0066ff' } : {}}
        color="secondary"
        aria-label={t('media_page.components.watch_video_button.aria_label')}
        title={t('media_page.components.watch_video_button.watch_button')}
        onClick={() => handleOpenMediaVideoModal()}
      >
        <HiPlay />
      </IconButton>
      {isMediaVideoModalOpen && (
        <VideoModal
          media={media}
          isOpen={isMediaVideoModalOpen}
          onClose={handleCloseMediaVideoModal}
          modalTitle={media.nome || ''}
        />
      )}
    </>
  );
}
