import { useEffect, useState } from 'react';
import { Button, TextField, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentsTable } from './components/ContentsTable';
import styles from './styles.module.scss';
import { AddNewContentModal } from './components/AddNewContentModal';
import { useModal } from '../../hooks/useModal';
import { useContentsContext } from '../../context/ContentsContext';
import { CategoryData } from '../../interfaces/Category';
import { CategoryFilterButton } from './components/CategoryFilterButton';
import { OnSortProps } from '../../hooks/useTableSorting';
import { ContentParamsToOrderBy } from '../../interfaces/Content';

export function Contents() {
  const [isAddNewContentModalOpen, handleOpenAddNewContentModal, handleCloseAddNewContentModal] = useModal();
  const [searchContentName, setSearchContentName] = useState<string>('');
  const { getContents, page, setPage, pageSize, metadata, isFetchingContents, order, orderBy, setOrder, setOrderBy } =
    useContentsContext();
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<CategoryData | null>(null);

  const { t } = useTranslation();

  function handlePageChange(value: number) {
    setPage(value);
    getContents({ page: value, pageSize, nome: searchContentName, order, orderBy });
  }

  function handleContentSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPage(1);
    getContents({ page: 1, pageSize, nome: searchContentName, order, orderBy });
  }

  async function onTableSort({ orderOnSort, orderByOnSort }: OnSortProps<ContentParamsToOrderBy>) {
    setOrderBy(orderByOnSort);
    setOrder(orderOnSort);
    await getContents({ page, pageSize, nome: searchContentName, order: orderOnSort, orderBy: orderByOnSort });
  }

  useEffect(() => {
    setPage(1);
    getContents({ page: 1, pageSize, order, orderBy });
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('contents_page.index.title')}</h1>
      <div className={styles.heading}>
        <form className={styles.searchBar} onSubmit={handleContentSearch}>
          <TextField
            type="text"
            color="primary"
            variant="outlined"
            value={searchContentName}
            placeholder={t('contents_page.index.content_name_input_pch')}
            onChange={(e) => setSearchContentName(e.target.value)}
            size="small"
            fullWidth
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isFetchingContents}
            className={styles.searchButton}
          >
            {t('contents_page.index.search_content_button')}
          </Button>
        </form>
        <div className={styles.categoryName}>
          <h1>{selectedMenuCategory ? selectedMenuCategory.nome : t('home_page.index.all_categories')}</h1>
          <CategoryFilterButton
            setSelectedMenuCategory={setSelectedMenuCategory}
            selectedMenuCategory={selectedMenuCategory}
            name={searchContentName}
          />
        </div>
        <div className={styles.addSectionContainer}>
          <Button variant="contained" onClick={handleOpenAddNewContentModal} className={styles.addNewContentButton}>
            {t("contents_page.index.add_new_content_button")}
          </Button>
        </div>
      </div>
      <ContentsTable onTableSort={(params) => onTableSort(params)} />
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          onChange={(_, value) => handlePageChange(value)}
          count={metadata.pages}
          color="primary"
          variant="outlined"
        />
      </div>
      <AddNewContentModal isOpen={isAddNewContentModalOpen} onClose={handleCloseAddNewContentModal} />
    </div>
  );
}
