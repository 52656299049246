import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import styles from './styles.module.scss';
import { UserData } from '../../../../../../../../interfaces/User';

interface RolesListProps {
  data?: UserData[];
  handleToggle: (arg0: UserData) => React.MouseEventHandler<HTMLTableCellElement>;
  checked: UserData[];
  title: string;
}

export function UsersList({ data, handleToggle, checked, title }: RolesListProps) {

    const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.tableContainer}>
        <Table size="small" className={styles.table}>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell className={styles.tableHeadCell} width={80} />
              <TableCell className={styles.tableHeadCell} width="50%">
                {t("contents_page.components.edit_content_modal.users_tab_table_header_name")}
              </TableCell>
              <TableCell className={styles.tableHeadCell} width="50%">
              {t("contents_page.components.edit_content_modal.users_tab_table_header_email")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((user) => {
                const labelId = `${user.primeiroNome}-${user.ultimoNome}-label`;
                return (
                  <TableRow key={user.idUsuario}>
                    <TableCell onClick={handleToggle(user)} align="center">
                      <Checkbox
                        checked={checked.indexOf(user) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{`${user.primeiroNome} ${user.ultimoNome}`}</TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
