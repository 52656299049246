import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Table, TableHead, TableRow, TableCell, Checkbox, TableBody } from '@mui/material';
import styles from './styles.module.scss';
import { MediaData } from '../../../../../../../../interfaces/Media';
import { ShowMediaEnum } from '../../../../../../../../enums/ShowMedia';

interface MediaListProps {
  title: string;
  data: MediaData[];
  setData: React.Dispatch<React.SetStateAction<MediaData[]>>;
  allMedias: MediaData[];
  selectedMediaIds: number[];
  handleToggle: (media: MediaData) => React.MouseEventHandler<HTMLTableCellElement>;
  checked: MediaData[];
  side: 'left' | 'right';
}

export function MediaList({
  title,
  data,
  handleToggle,
  checked,
  setData,
  allMedias,
  selectedMediaIds,
  side,
}: MediaListProps) {
  const [mediaNameSearch, setMediaNameSearch] = useState<string>('');

  const { t } = useTranslation();

  function searchMediaName(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const newData = allMedias
      .filter((media) =>
        side === 'left' ? !selectedMediaIds.includes(media.idMedia || 0) : selectedMediaIds.includes(media.idMedia || 0)
      )
      .filter((media) => media.nome?.toLowerCase().includes(mediaNameSearch.toLowerCase()));
    setData(newData);
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <form className={styles.filterContainer} onSubmit={searchMediaName}>
        <TextField
          type="text"
          variant="outlined"
          className={styles.input}
          onChange={(e) => setMediaNameSearch(e.target.value)}
        />
        <Button className={styles.buttonFilter} variant="contained" type="submit">
          Filtrar
        </Button>
      </form>
      <div className={styles.tableContainer}>
        <Table size="small" className={styles.table}>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell className={styles.tableHeadCell} width={80} />
              <TableCell className={styles.tableHeadCell} width="50%">
                {t('contents_page.components.edit_content_modal.medias_tab_table_header_name')}
              </TableCell>
              <TableCell className={styles.tableHeadCell} width="50%">
                {t('contents_page.components.edit_content_modal.medias_tab_table_header_description')}
              </TableCell>
              <TableCell className={styles.tableHeadCell} width={100} />
              <TableCell className={styles.tableHeadCell} width={120} />
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {data &&
              data.map((media) => {
                const labelId = `${media.nome}-label`;
                return (
                  <TableRow key={media.idMedia}>
                    <TableCell onClick={handleToggle(media)} width={80} align="center">
                      <Checkbox
                        checked={checked.indexOf(media) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell width="50%">{media.nome}</TableCell>
                    <TableCell width="50%">{media.descricao}</TableCell>
                    <TableCell width={100} align="right">
                      {t(ShowMediaEnum[media.tipo || 0])}
                    </TableCell>
                    <TableCell width={120}>
                      <span className={media.ativo ? styles.activeBox : styles.inactiveBox}>
                        {media.ativo ? 'Active' : 'Inactive'}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
