import { ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

interface NavLinkProps extends LinkProps {
  children: ReactNode;
}

export function NavLink({ children, ...rest }: NavLinkProps) {
  const { pathname } = useLocation();

  const isActiveLink = pathname === rest.to;

  return (
    <Link {...rest} className={isActiveLink ? styles.activeLink : styles.link}>
      {children}
    </Link>
  );
}
