export enum UserTypeEnum {
  BackOffice = 1,
  Plataforma = 2,
}

export enum UserActivityEnum {
  MediaIniciada = 1,
  MediaCompletada = 2,
  Download = 3,
}
