import { useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { IMaskInput } from 'react-imask';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/Input';
import styles from './styles.module.scss';
import { AuthRequestAccessType } from '../../../../interfaces/Auth';
import { useAuthContext } from '../../../../context/AuthContext';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { ShowLanguage } from '../../../../enums/ShowLanguage';

export const CNPJMask = React.forwardRef<HTMLInputElement>((props, ref) => (
  <IMaskInput {...props} mask="00.000.000/0000-00" inputRef={ref} />
));

export function RequestAccessForm() {
  const { isRequestingAccess } = useAuthContext();
  const { t } = useTranslation();

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AuthRequestAccessType>();

  const watchCnpj = watch('cnpj');

  return isRequestingAccess ? (
    <div className={styles.loadContainer}>
      <LoadIndicator />
    </div>
  ) : (
    <div className={styles.formContainer}>
      <Input
        type="text"
        label={t('login_page.components.request_access_form.first_name_input_label')}
        {...register('primeiroNome')}
        error={errors.primeiroNome}
      />
      <Input
        type="text"
        label={t('login_page.components.request_access_form.last_name_input_label')}
        {...register('ultimoNome')}
        error={errors.ultimoNome}
      />
      <Input
        type="text"
        label={t('login_page.components.request_access_form.email_input_label')}
        {...register('email')}
        error={errors.email}
      />
      <Input
        type="text"
        name="cnpj"
        label={t('login_page.components.request_access_form.cnpj_input_label')}
        onChange={(e) => setValue('cnpj', e.target.value)}
        InputProps={{ inputComponent: CNPJMask as any }}
        error={errors.cnpj}
        value={watchCnpj}
      />
      <FormControl fullWidth>
        <FormLabel htmlFor="idioma">{t('login_page.components.request_access_form.language_input_label')}</FormLabel>
        <Select id="idioma" {...register('idioma')} size="small" defaultValue="pt-br">
          {Object.keys(ShowLanguage).map((lang) => (
            <MenuItem value={lang} key={lang}>
              {t(ShowLanguage[lang])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
