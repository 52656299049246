import { StyledEngineProvider } from '@mui/material';
import 'easymde/dist/easymde.min.css';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { App } from './App';
import './styles/global.scss';
import { ThemeContextProvider } from './context/ThemeContext';
import { ToastContextProvider } from './context/ToastContext';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import en_translation from './locales/en/translation.json';
import pt_translation from './locales/pt/translation.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

i18next.use(initReactI18next).init({
  resources: {
    en_us: en_translation,
    pt_br: pt_translation,
  },
  fallbackLng: 'pt_br',
  interpolation: {
    escapeValue: true,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeContextProvider>
      <ToastContextProvider>
        <App />
      </ToastContextProvider>
    </ThemeContextProvider>
  </StyledEngineProvider>
);
