import * as yup from 'yup';

export const RequestAccessFormSchema = yup.object({
  primeiroNome: yup.string().required('form_schemas.request_access.first_name_required'),
  ultimoNome: yup.string().required('form_schemas.request_access.last_name_required'),
  email: yup
    .string()
    .email('form_schemas.request_access.email_not_valid')
    .required('form_schemas.request_access.email_required'),
  idioma: yup.string().required('form_schemas.request_access.language_required'),
  cnpj: yup
    .string()
    .required('form_schemas.request_access.cnpj_required')
    .matches(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/, { message: 'form_schemas.request_access.cnpj_not_valid' }),
});
