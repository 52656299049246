import * as yup from 'yup';

export const UserPasswordFormSchema = yup.object({
  senhaAtual: yup.string().required('form_schemas.user_password.current_password_required'),
  novaSenha: yup.string().required('form_schemas.user_password.new_password_required'),
  confirmacaoNovaSenha: yup
    .string()
    .equals([yup.ref('novaSenha')], 'form_schemas.user_password.confirm_new_password_doesnt_match')
    .required('form_schemas.user_password.confirm_new_password_required'),
});
