import { Button, Pagination, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next"
import { useUsersContext } from '../../context/UsersContext';
import { AddNewUserModal } from './components/AddNewUserModal';
import styles from './styles.module.scss';
import { useModal } from '../../hooks/useModal';
import { UsersTable } from './components/UsersTable';
import { OnSortProps } from '../../hooks/useTableSorting';
import { UserParamsToOrderBy } from '../../interfaces/User';

export function UserManagement() {
  const [searchUserName, setSearchUserName] = useState<string>('');
  const [isAddNewUserModalOpen, handleOpenAddNewUserModal, handleCloseAddNewUserModal] = useModal();

  const { t} = useTranslation();

  const { page, setPage, pageSize, metadata, getUsers, isFetchingUsers, order, orderBy, setOrder, setOrderBy } =
    useUsersContext();

  function handleSearchUsers(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPage(1);
    getUsers({ page: 1, pageSize, primeiroNome: searchUserName, order, orderBy });
  }

  function handlePageChange(value: number) {
    setPage(value);
    getUsers({ page: value, pageSize, primeiroNome: searchUserName, order, orderBy });
  }

  async function onTableSort({ orderOnSort, orderByOnSort }: OnSortProps<UserParamsToOrderBy>) {
    setOrderBy(orderBy);
    setOrder(order);
    await getUsers({ page, pageSize, primeiroNome: searchUserName, order: orderOnSort, orderBy: orderByOnSort });
  }

  useEffect(() => {
    getUsers({ page, pageSize, order, orderBy });
  }, []);

  return (
    <section className={styles.container}>
      <h1 className={styles.title}>{t("users_page.index.title")}</h1>
      <div className={styles.heading}>
        <form className={styles.searchBar} onSubmit={handleSearchUsers}>
          <TextField
            type="text"
            color="primary"
            variant="outlined"
            size="small"
            placeholder={t("users_page.index.user_name_input_pch")}
            value={searchUserName}
            disabled={isFetchingUsers}
            onChange={(e) => setSearchUserName(e.target.value)}
            fullWidth
          />
          <Button
            type="submit"
            disabled={isFetchingUsers}
            color="primary"
            variant="contained"
            className={styles.searchButton}
          >
            {t("users_page.index.search_user_button")}
          </Button>
        </form>
        <div className={styles.addSectionContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenAddNewUserModal}
            className={styles.addNewUserButton}
          >
            {t("users_page.index.add_new_user_button")}
          </Button>
        </div>
      </div>
      <UsersTable onTableSort={(params) => onTableSort(params)} />
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          count={metadata.pages}
          color="primary"
          variant="outlined"
          disabled={isFetchingUsers}
          onChange={(e, value) => handlePageChange(value)}
        />
      </div>
      <AddNewUserModal isOpen={isAddNewUserModalOpen} onClose={handleCloseAddNewUserModal} />
    </section>
  );
}
