import { useState } from 'react';
import { Menu } from '@mui/material';
import { FiFilter } from 'react-icons/fi';
import { CategoryFilterMenu } from '../CategoryFilterMenu';
import { useContentsContext } from '../../../../context/ContentsContext';
import { CategoryData } from '../../../../interfaces/Category';

interface CategoryFilterButtonProps {
  setSelectedMenuCategory: (category: CategoryData | null) => void;
  selectedMenuCategory: CategoryData | null;
  name: string;
}

export function CategoryFilterButton({
  setSelectedMenuCategory,
  selectedMenuCategory,
  name,
}: CategoryFilterButtonProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorElement);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const { getContents, setPage, pageSize } = useContentsContext();

  const handleSelectCategory = (category: CategoryData | null) => {
    if (category?.idCategoria === selectedMenuCategory?.idCategoria || category === null) {
      setSelectedMenuCategory(null);
      getContents({ page: 1, pageSize, nome: name, catAtiva: true });
    } else {
      setSelectedMenuCategory(category);
      getContents({ page: 1, pageSize, categoryId: category.idCategoria, nome: name, catAtiva: true });
    }
    setPage(1);
    handleCloseMenu();
  };

  return (
    <>
      <button type="button" onClick={handleOpenMenu}>
        <FiFilter />
      </button>
      <Menu
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { backgroundColor: 'var(--navs)' },
        }}
      >
        <CategoryFilterMenu handleSelectCategory={handleSelectCategory} selectedCategory={selectedMenuCategory} />
      </Menu>
    </>
  );
}
