import { useState } from 'react';
import { ActivityUserData, ActivityUserResponseType, FetchActivityUserParamsType } from '../interfaces/ActivityUser';
import { api } from '../services/api';
import { Metadata } from '../interfaces/ApiResponse';

async function fetchActivityUser({
  page,
  pageSize,
  order,
  orderBy,
  idCategoria,
  idConteudo,
  idUsuarios,
  dataAte,
  dataDe,
}: FetchActivityUserParamsType): Promise<ActivityUserResponseType> {
  const { data } = await api.get<ActivityUserResponseType>(`/atividadeUsuario`, {
    params: {
      page,
      pageSize,
      order,
      orderBy,
      idCategoria,
      idConteudo,
      idUsuarios,
      dataDe,
      dataAte,
    },
  });

  return data;
}

export function useFetchActivityUser() {
  const [activityUser, setActivityUser] = useState<ActivityUserData[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const [isFetchingActivityUser, setIsFetchingActivityUser] = useState<boolean>(false);

  async function getActivityUser({
    page,
    pageSize,
    order,
    orderBy,
    idCategoria,
    idConteudo,
    idUsuarios,
    dataAte,
    dataDe,
  }: FetchActivityUserParamsType) {
    setIsFetchingActivityUser(true);
    try {
      const data = await fetchActivityUser({
        page,
        pageSize,
        order,
        orderBy,
        idCategoria,
        idConteudo,
        idUsuarios,
        dataAte,
        dataDe,
      });
      setActivityUser(data.results);
      const { results, ...rest } = data;
      setMetadata({ ...rest });
    } finally {
      setIsFetchingActivityUser(false);
    }
  }

  return { getActivityUser, metadata, activityUser, isFetchingActivityUser };
}
