import { useEffect, useState } from 'react';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { UsersList } from './components/UsersList';
import styles from './styles.module.scss';
import { LoadIndicator } from '../../../../../../components/LoadIndicator';
import { UserData } from '../../../../../../interfaces/User';
import { ContentPutBody } from '../../../../../../interfaces/Content';

function not(a: UserData[], b: UserData[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: UserData[], b: UserData[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface UsersModalProps {
  users: UserData[];
  isFetchingUsers: boolean;
  isFetchingContentUsers: boolean;
}

export function UsersModal({ isFetchingUsers, users, isFetchingContentUsers }: UsersModalProps) {
  const { setValue, getValues, watch } = useFormContext<ContentPutBody>();
  const usersWatch = watch('usuarios');

  const {t} = useTranslation();

  const selectedUserIds: number[] = [];
  const [checked, setChecked] = useState<UserData[]>([]);
  const [left, setLeft] = useState<UserData[]>([]);
  const [right, setRight] = useState<UserData[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  function handleInit() {
    const leftData = users.filter((user) => !getValues('usuarios').some((contUser) => contUser === user.idUsuario));
    setRight(users.filter((user) => getValues('usuarios').includes(user.idUsuario || 0)));
    setLeft(leftData);
  }

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    for (let i = 0; i < right.concat(left).length; i += 1) {
      selectedUserIds.push(right.concat(left)[i].idUsuario || 0);
    }
    setValue('usuarios', selectedUserIds);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    for (let i = 0; i < right.concat(leftChecked).length; i += 1) {
      selectedUserIds.push(right.concat(leftChecked)[i].idUsuario || 0);
    }
    setValue('usuarios', selectedUserIds);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    for (let i = 0; i < not(right, rightChecked).length; i += 1) {
      selectedUserIds.push(not(right, rightChecked)[i].idUsuario || 0);
    }
    setValue('usuarios', selectedUserIds);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setValue('usuarios', []);
  };

  const handleToggle = (value: UserData) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (!isFetchingUsers && !isFetchingContentUsers) {
      handleInit();
    }
  }, [isFetchingUsers, usersWatch, isFetchingContentUsers]);

  if (isFetchingUsers || isFetchingContentUsers) {
    return <LoadIndicator />;
  }

  return (
    <div className={styles.container}>
      <UsersList data={left} handleToggle={handleToggle} checked={checked} title={t("contents_page.components.edit_content_modal.users_tab_title_available")} />
      <div className={styles.buttonsContainer}>
        <Button className={styles.button} onClick={handleCheckedRight} color="primary" variant="contained">
          <SlArrowRight />
        </Button>
        <Button className={styles.button} onClick={handleAllRight} color="primary" variant="contained">
          <RxDoubleArrowRight />
        </Button>
        <Button className={styles.button} onClick={handleCheckedLeft} color="primary" variant="contained">
          <SlArrowLeft />
        </Button>
        <Button className={styles.button} onClick={handleAllLeft} color="primary" variant="contained">
          <RxDoubleArrowLeft />
        </Button>
      </div>
      <UsersList data={right} handleToggle={handleToggle} checked={checked} title={t("contents_page.components.edit_content_modal.users_tab_title_selected")}/>
    </div>
  );
}
