import { MenuItem, Pagination, TextField } from '@mui/material';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadIndicator } from '../../../../components/LoadIndicator';
import { useGetCategories } from '../../../../hooks/useCategories';
import styles from './styles.module.scss';
import { CategoryData } from '../../../../interfaces/Category';

interface CategoryFilterMenuProps {
  handleSelectCategory: (category: CategoryData | null) => void;
  selectedCategory: CategoryData | null;
}

export function CategoryFilterMenu({ handleSelectCategory, selectedCategory }: CategoryFilterMenuProps) {
  const [page, setPage] = useState<number>(1);
  const pageSize = 5;
  const [searchCategoryName, setSearchCategoryName] = useState<string>('');
  const { categories, getCategories, isFetchingCategories, metadata } = useGetCategories();

  const { t } = useTranslation();

  function handleSearchCategoriesByName(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      setPage(1);
      getCategories({ page: 1, pageSize, nome: searchCategoryName });
    }
  }

  function handlePageChange(value: number) {
    setPage(value);
    getCategories({ page: value, pageSize, nome: searchCategoryName });
  }

  useEffect(() => {
    getCategories({ page, pageSize });
  }, []);

  return (
    <div className={styles.menuContainer}>
      <h5>{t('home_page.components.category_filter_menu.title')}</h5>
      <TextField
        type="text"
        placeholder={t('home_page.components.category_filter_menu.category_name_input_pch')}
        color="primary"
        variant="outlined"
        size="small"
        fullWidth
        value={searchCategoryName}
        onChange={(e) => setSearchCategoryName(e.target.value)}
        onKeyUp={(e) => handleSearchCategoriesByName(e)}
      />
      {isFetchingCategories ? (
        <div className={styles.loadBox}>
          <LoadIndicator />
        </div>
      ) : (
        <div className={styles.categoriesList}>
          <MenuItem
            onClick={() => handleSelectCategory(null)}
            className={selectedCategory === null ? styles.selectedItem : ''}
          >
            {t('home_page.index.all_categories')}
          </MenuItem>
          {categories.map((category) => {
            const isSelectedCategory = category.idCategoria === selectedCategory?.idCategoria;
            return (
              <MenuItem
                key={category.idCategoria}
                onClick={() => handleSelectCategory(category)}
                className={isSelectedCategory ? styles.selectedItem : ''}
              >
                {category.nome}
              </MenuItem>
            );
          })}
        </div>
      )}
      <div className={styles.paginationContainer}>
        <Pagination
          page={page}
          onChange={(e, value) => handlePageChange(value)}
          count={metadata.pages}
          color="primary"
          variant="outlined"
          disabled={isFetchingCategories}
        />
      </div>
    </div>
  );
}
